import ApiGet from "api/ApiGet";
import { responseResult } from "utils/ResponseStatus";
import {
  SETACTIVEMENUSIDEBAR,
  SETDATALOADING,
  SETFOOTER,
  SETFOOTERURLIMG,
  SETHEADERMENU,
  SETHEADERSUBMENU,
  SETMENUSIDEBAR,
  SETROUTE,
} from "./globalTypes";

export const setRoute = (payload) => {
  return {
    type: SETROUTE,
    payload,
  };
};

export const setHeaderMenu = (payload) => {
  console.log(payload, "payloaddd")
  return {
    type: SETHEADERMENU,
    payload,
  };
};
export const setHeaderSubMenu = (payload) => {
  return {
    type: SETHEADERSUBMENU,
    payload,
  };
};

export const setDataLoading = (bool) => {
  return {
    type: SETDATALOADING,
    payload: bool,
  };
};

export const setFooter = (payload) => {
  return {
    type: SETFOOTER,
    payload,
  };
};

export const setFooterUrlImg = (payload) => {
  return {
    type: SETFOOTERURLIMG,
    payload,
  };
};

export const setActiveMenuSidebar = (payload) => {
  return {
    type: SETACTIVEMENUSIDEBAR,
    payload,
  };
};

export const getRoute = (params) => async (dispatch) => {
  dispatch(setDataLoading(true));
  return ApiGet.actions
    .GetRoute(params)
    .then((res) => {
      if (responseResult(res)) {
        dispatch(
          setRoute({
            route: responseResult(res)?.route,
            menu: responseResult(res)?.menu,
          })
        );
      }
      dispatch(setDataLoading(false));
    })
    .catch((err) => {
      dispatch(setDataLoading(false));
    });
};

export const getFooter = (params) => async (dispatch) => {
  dispatch(setDataLoading(true));
  return ApiGet.actions
    .Footer(params)
    .then((res) => {
      if (responseResult(res)) {
        dispatch(setFooter(responseResult(res)));
      }
      dispatch(setDataLoading(false));
    })
    .catch((err) => {
      dispatch(setDataLoading(false));
    });
};

export const getFooterUrlImg = (params) => async (dispatch) => {
  dispatch(setDataLoading(true));
  return ApiGet.actions
    .FooterUrlImg(params)
    .then((res) => {
      if (responseResult(res)) {
        dispatch(setFooterUrlImg(responseResult(res)));
      }
      dispatch(setDataLoading(false));
    })
    .catch((err) => {
      dispatch(setDataLoading(false));
    });
};

export const setMenuSidebar = (payload) => {
  return {
    type: SETMENUSIDEBAR,
    payload,
  };
};
