import React from "react";

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// // document ready (*microsoft office)
// import 'froala-editor/js/froala_editor.pkgd.min.js';

// // Import all Froala Editor plugins;
// import 'froala-editor/js/plugins.pkgd.min.js';

// // Import a single Froala Editor plugin.
// import 'froala-editor/js/plugins/align.min.js';

// // Import a language file.
// import 'froala-editor/js/languages/de.js';

// // Import a third-party plugin.
// import 'froala-editor/js/third_party/image_tui.min.js';
// import 'froala-editor/js/third_party/embedly.min.js';
// import 'froala-editor/js/third_party/spell_checker.min.js';

// Include font-awesome css if required.
// install using "npm install font-awesome --save"
// import 'font-awesome/css/font-awesome.css';
// import 'froala-editor/js/third_party/font_awesome.min.js';

// Include special components if required.
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

const FroalaEditorTextArea = (props) => {
    const {
        model,
    } = props;

    return (
        <>

            <FroalaEditorView
                model={model}
                config={{
                    key: '9KH3cC6A3A3F2F3F3B2H-8xlfbbiaA-8C-7H-7gd1C-13yypqjnrwB3D-11pF3hfA-61B6A4D4C3C5F2H2A3G1A6==',
                    attribution: false
                }}
            />
        </>
    )
}

export default FroalaEditorTextArea