import Modal from "./Modal";
// import { VscClose } from "react-icons/vsc";

function StandardModal({ open, closeModal, children, title, closeButton = false, className, flipping }) {
	return (
		<Modal isOpen={open} closeModal={closeModal} className={className} flipping={flipping}>
			<div className="bg-transparent py-1">
				{/* {closeButton && (
					<div className="flex justify-between items-center mb-4">
						<div className=" font-medium">{title}</div>
						<VscClose size={30} onClick={closeModal} className="cursor-pointer hover:text-primary" />
					</div>
				)} */}
				<div>{children}</div>
			</div>
		</Modal>
	);
}

export default StandardModal;