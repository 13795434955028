import React, { useEffect, useState, useRef } from "react";
import provinsi from "assets/images/icons/provinsi.svg";
import anakperusahaan from "assets/images/icons/anak-perusahaan.svg";
import layanan from "assets/images/icons/layanan.svg";
import terminal from "assets/images/icons/terminal.svg";
import GoogleMaps from "components/reuseable/Google/Maps/GoogleMaps";
import ApiGet from "api/ApiGet";
import { responseResult } from "utils/ResponseStatus";
import CountUp from "react-countup";
import Ticker from "utils/CountUp";
import {
  getAnakPerusahaan,
  resetAnakPerusahaan,
} from "redux/profil/profilActions";
import useRedux from "redux/useRedux";
import "../../../assets/css/wilayah.css";
// import ReactVisibilitySensor from "react-visibility-sensor";
// import VisibilitySensor from 'react-visibility-sensor'

const WilayahKerjaHome = (countRef) => {
  const {
    dispatch,
    profil: { anakPerusahaan },
    layanan: { jenisLayanan },
  } = useRedux();

  const [location, setLocation] = useState([]);
  const [countEffect, setCountEffeck] = useState(false);
  const [scroll, setScroll] = useState(false);
  // const counting = useRef(null)

  const [jmlWilayah, setJmlWilayah] = useState(0);
  const [jmlFasilitas, setJmlFasilitas] = useState(0);

  useEffect(() => {
    dispatch(getAnakPerusahaan());
    ApiGet.actions.CountFasilitasDetails().then((response) => {
      if (responseResult(response)) {
        setJmlFasilitas(responseResult(response));
      }
    });
    return () => {
      dispatch(resetAnakPerusahaan());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (anakPerusahaan?.data?.length > 0) {
      let tampung = [];
      let tempJmlWil = 0;
      anakPerusahaan?.data?.map((val) => {
        let layanan = val?.Layanan?.title;
        if (val?.Wilayah?.length > 0) {
          val?.Wilayah?.map((_val) => {
            tempJmlWil = tempJmlWil + 1;
            tampung.push({
              lat: Number(_val?.latitude),
              lng: Number(_val?.longitude),
              label: _val?.name,
              layanan,
            });
          });
        } else {
          tampung.push({
            label: val?.company_name,
            lat: val?.latitude && Number(val?.latitude),
            lng: val?.longitude && Number(val?.longitude),
            layanan,
          });
        }
      });
      setJmlWilayah(tempJmlWil);
      setLocation(tampung);
    }
  }, [anakPerusahaan]);

  // useEffect(() => {
  //     ApiGet.actions.AnakPerusahaan()
  //         .then((response) => {
  //             if (responseResult(response)) {
  //                 let tampung = []
  //                 responseResult(response)?.length > 0 && responseResult(response)?.map((val) => {
  //                     let layanan = val?.Layanan?.title
  //                     if (val?.Wilayah?.length > 0) {
  //                         val?.Wilayah?.map((_val) => {
  //                             tampung.push({ lat: Number(_val?.latitude), lng: Number(_val?.longitude), label: _val?.name, layanan })
  //                         })
  //                     } else {
  //                         tampung.push(
  //                             {
  //                                 label: val?.company_name,
  //                                 lat: val?.latitude && Number(val?.latitude),
  //                                 lng: val?.longitude && Number(val?.longitude),
  //                                 layanan
  //                             }
  //                         )
  //                     }
  //                     // return {
  //                     //     label: val?.company_name,
  //                     //     lat: val?.latitude && Number(val?.latitude),
  //                     //     lng: val?.longitude && Number(val?.longitude),
  //                     //     layanan: val?.Layanan?.title
  //                     // }
  //                     return val
  //                 })
  //                 // setLocation(loc)
  //                 setLocation(tampung)
  //             }
  //         })
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // console.log('location', location)

  const toggleVisible = () => {
    const divSelect = document.getElementById("wilayah");
    // console.log(divSelect)
    const titleTop = divSelect.offsetTop - 110;
    // console.log(titleTop, "title top")
    // console.log(window.scrollY, "scrolY")

    // console.log(titleTop, "===", window.scrollY)
    if (window.pageYOffset >= titleTop) {
      setScroll(true);
      // console.log("masuk")
    } else {
      // console.log("keluar")
      setScroll(false);
    }

    // setScroll(titleTop = window.scrollY)
  };
  useEffect(() => {
    //    console.log(divSelect, "div")
    window.addEventListener("scroll", toggleVisible);
    return () => {
      // console.log("maasuk")
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);
  return (
    <>
      <div className="flex flex-wrap flex-row justify-between wilayah">
        <div className=" md:basis-2/3 max-w-[850px] h-full ">
          <GoogleMaps location={location} />
        </div>
        <div className=" md:basis-1/3 flex flex-col justify-center p-5 ">
          <div className="flex flex-wrap grid grid-cols-2 gap-[39px] justify-between">
            <div className="flex flex-col m-2 w-full md:w-[180px]">
              <div className="flex flex-row items-center h-[60px] mb-4">
                <div className="mr-4">
                  <img src={provinsi} alt="icon" />
                </div>
                <div className="text-style">
                  {scroll ? (
                    <Ticker
                      className="count"
                      end={anakPerusahaan?.data?.length + jmlWilayah}
                    />
                  ) : (
                    anakPerusahaan?.data?.length + jmlWilayah
                  )}
                </div>
              </div>
              <div className="text-[16px]">Area Layanan</div>
            </div>
            <div className="flex flex-col m-2 w-full md:w-[180px]">
              <div className="flex flex-row items-center h-[60px] mb-4">
                <div className="mr-4">
                  <img src={anakperusahaan} alt="icon" />
                </div>
                <div className="text-style">
                  {scroll ? (
                    <Ticker
                      className="count"
                      end={anakPerusahaan?.data?.length}
                    />
                  ) : (
                    anakPerusahaan?.data?.length
                  )}
                </div>
              </div>
              <div className="text-[16px]">Anak perusahaan</div>
            </div>
            <div className="flex flex-col m-2 w-full md:w-[180px]">
              <div className="flex flex-row items-center h-[60px] mb-4">
                <div className="mr-4">
                  <img src={layanan} alt="icon" />
                </div>
                <div className="text-style">
                  {scroll ? (
                    <Ticker className="count" end={jenisLayanan?.length} />
                  ) : (
                    jenisLayanan?.length
                  )}
                </div>
              </div>
              <div className="text-[16px]">Layanan</div>
            </div>
            <div className="flex flex-col m-2 w-full md:w-[180px]">
              <div className="flex flex-row items-center h-[60px] mb-4">
                <div className="mr-4">
                  <img src={terminal} alt="icon" />
                </div>
                <div className="text-style">
                  {scroll ? (
                    <Ticker className="count" end={jmlFasilitas} />
                  ) : (
                    jmlFasilitas
                  )}
                </div>
              </div>
              <div className="text-[16px]">Fasilitas/Peralatan</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WilayahKerjaHome;
