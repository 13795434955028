import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import RenderError from "../RenderError";

const TextArea = (props) => {
    const {
        classNameDiv,
        label,
        classNameLabel,
        className,
        name,
        rows,
        text,
        value,
        onChange,
        disabled,
        readOnly,
        required,
        placeholder,
    } = props;

    const [requiredProps, setRequiredProps] = useState([])
    const [errorProps, seterrorProps] = useState("")
    let temp = []
    useEffect(() => {
        for (const key in TextArea.propTypes) { //eslint-disable-line react/forbid-foreign-prop-types
            if (!TextArea.propTypes[key].isRequired) { //eslint-disable-line react/forbid-foreign-prop-types
                temp.push(key)
            }
        }
        if (temp.length > 0) {
            setRequiredProps(temp)
        }
    }, []) //eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (requiredProps.length > 0) {
            for (const key in requiredProps) {
                if (!props[requiredProps[key]]) {
                    seterrorProps(requiredProps[key]);
                    break;
                }
            }
        }
    }, [requiredProps]); //eslint-disable-line react-hooks/exhaustive-deps

    if (errorProps) {
        return <RenderError component="TextArea" err={errorProps} />
    }

    return (
        <>
            <div className={`${classNameDiv}`}>
                {
                    label && (
                        <label htmlFor={name} className={`${classNameLabel}`}>
                            {label} {required && <span style={{ color: 'red' }}>*</span>}
                        </label>
                    )
                }

                <textarea
                    id={name}
                    className={`${className}`}
                    name={name}
                    rows={rows}
                    text={text}
                    value={value}
                    // defaultValue={value}
                    onChange={onChange}
                    disabled={disabled}
                    readOnly={readOnly}
                    required={required}
                    placeholder={placeholder}
                />
            </div>
        </>
    )
}

TextArea.defaultProps = {
    rows: '3',
    classNameDiv: '',
    classNameLabel: '',
    className: ''
};

TextArea.propTypes = {
    label: PropTypes.string,
    classNameLabel: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    rows: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.string,
    readOnly: PropTypes.string,
    required: PropTypes.bool,
}

export default TextArea