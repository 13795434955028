import React, { useRef } from "react";
import chooseUs from 'assets/images/dummy/choose-us.png';
import chooseUsLoc from 'assets/images/icons/chooseus-loc.svg';
import chooseUsMap from 'assets/images/icons/chooseus-map.svg';
import chooseUsCheck from 'assets/images/icons/chooseus-check.svg';
import chooseUsHand from 'assets/images/icons/chooseus-hand.svg';
import chooseUsPelindo from 'assets/images/icons/chooseus-pelindo.svg';
import { APIweb } from "api/config";
import { Link, useLocation } from "react-router-dom";
import { scrollDown } from "utils/ScrollDown";
const ChooseUs = (props) => {
    const location = useLocation()
    // const aboutSection = useRef(null)
    return (
        <>
            <div className="choose-us">
                <img src={chooseUs} alt="choose-us" style={{ width: '100%' }} />
                <div className="top-left">
                    <Link to={'/fasilitas'} className="flex flex-row cursor-pointer" >
                        <div className="mr-2">
                            <img src={chooseUsLoc} alt='choose-us-loc' />
                        </div>
                        <div className="flex flex-col w-[220px]">
                            <div className="text-[#0275BC] text-[18px] font-semibold">Lokasi Fasilitas yang Strategis</div>
                            <div className="text-[#333333] text-[14px] text-justify">Fasilitas yang terintegrasi langsung dengan terminal & pelabuhan</div>
                        </div>
                    </Link>
                </div>
                <div className="bottom-left">
                    <Link to={'/wilayah-kerja-anak-perusahaan'} className="flex flex-row cursor-pointer" >
                        <div className="mr-2">
                            <img src={chooseUsMap} alt='choose-us-map' />
                        </div>
                        <div className="flex flex-col w-[220px]">
                            <div className="text-[#0275BC] text-[18px] font-semibold">Wilayah Operasi yang Luas</div>
                            <div className="text-[#333333] text-[14px] text-justify">Wilayah operasi yang tersebar di seluruh Indonesia</div>
                        </div>
                    </Link>
                </div>
                <div className="top-right">
                    <Link to={'/penghargaan-sertifikasi'} className="flex flex-row cursor-pointer" >
                        <div className="mr-2">
                            <img src={chooseUsCheck} alt='choose-us-check' />
                        </div>
                        <div className="flex flex-col w-[220px]">
                            <div className="text-[#0275BC] text-[18px] font-semibold">Perusahaan yang tersertifikasi</div>
                            <div className="text-[#333333] text-[14px] text-justify">Mengutamakan pelayanan dengan mutu terbaik di sektor logistik</div>
                        </div>
                    </Link>
                </div>
                <div className="bottom-right">
                    <Link to={'/logistic-cargo-service'} className="flex flex-row cursor-pointer" >
                        <div className="mr-2">
                            <img src={chooseUsHand} alt='choose-us-hand' />
                        </div>
                        <div className="flex flex-col w-[220px]">
                            <div className="text-[#0275BC] text-[18px] font-semibold">End to End Services</div>
                            <div className="text-[#333333] text-[14px] text-justify">Menyediakan layanan yg mencakup first mile hingga last mile</div>
                        </div>
                    </Link>
                </div>
                <div className="centered-bottom">
                    <div className="flex flex-rowc cursor-pointer" onClick={() => scrollDown(props.scroll)}>
                        <div className="mr-2">
                            <img src={chooseUsPelindo} alt='choose-us-pelindo' />
                        </div>
                        <div className="flex flex-col w-[220px]" >
                            <div className="text-[#0275BC] text-[18px] font-semibold">Bagian dari Pelindo Group</div>
                            <div className="text-[#333333] text-[14px] text-justify">BUMN kepelabuhanan yang terbesar di Indonesia</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center md:hidden">
                    <Link to={'/fasilitas'} target="_blank" className="flex flex-row w-[350px] m-2 items-center">
                        <div className="mr-2">
                            <img src={chooseUsLoc} alt='choose-us-loc' />
                        </div>
                        <div className="flex flex-col">
                            <div className="text-[#0275BC] text-[18px] font-semibold">Lokasi Fasilitas yang Strategis</div>
                        </div>
                    </Link>
                    <Link to={'/wilayah-kerja-anak-perusahaan'} target="_blank" onUpdate={() => window.scrollTo(0, 0)} className="flex flex-row w-[350px] m-2 items-center">
                        <div className="mr-2">
                            <img src={chooseUsMap} alt='choose-us-map' />
                        </div>
                        <div className="flex flex-col">
                            <div className="text-[#0275BC] text-[18px] font-semibold">Wilayah Operasi yang Luas</div>
                        </div>
                    </Link>
                    <Link to={'/penghargaan-sertifikasi'} target="_blank" className="flex flex-row w-[350px] m-2 items-center">
                        <div className="mr-2">
                            <img src={chooseUsCheck} alt='choose-us-check' />
                        </div>
                        <div className="flex flex-col">
                            <div className="text-[#0275BC] text-[18px] font-semibold">Perusahaan yang tersertifikasi</div>
                        </div>
                    </Link>
                    <Link to={'/logistic-cargo-service'} target="_blank" className="flex flex-row w-[350px] m-2 items-center">
                        <div className="mr-2">
                            <img src={chooseUsHand} alt='choose-us-hand' />
                        </div>
                        <div className="flex flex-col">
                            <div className="text-[#0275BC] text-[18px] font-semibold">End to End Services</div>
                        </div>
                    </Link>
                    <div className="flex flex-row w-[350px] m-2 items-center cursor-pointer" onClick={() => scrollDown(props.scroll)}>
                        <div className="mr-2">
                            <img src={chooseUsPelindo} alt='choose-us-pelindo' />
                        </div>
                        <div className="flex flex-col">
                            <div className="text-[#0275BC] text-[18px] font-semibold">Bagian dari Pelindo Group</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChooseUs