import { COUNTLIST, RESETCOUNTLIST } from "./searchTypes";

const initialState = {
    menuList: [],
    details: null,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case COUNTLIST:
            return {
                ...state,
                menuList: payload
            };
        case RESETCOUNTLIST:
            return {
                ...state,
                menuList: []
            };
        default:
            return state;
    }
}

export default reducer;