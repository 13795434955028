
// import LayoutDetail from "LayoutDetail";
import React, { Suspense, useEffect, useLayoutEffect, useState } from "react";
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import Home from "components/pages/Home";
import Layout from "./Layout";
import useRedux from "redux/useRedux";
import { getRoute } from "redux/global/globalActions";
import ConfigRoute from "ConfigRoute";
import { slugUrl } from "utils/myFunc";
import LayoutDetail from "LayoutDetail copy";
import { Circles } from 'react-loader-spinner'
import KontakKami from "components/pages/KontakKami";
import PPID from "components/pages/PPID";
import GlobalSearch from "components/pages/GlobalSearch";
import Profile from "components/pages/PPID/Profile";
import Regulasi from "components/pages/PPID/Regulasi";
import Maklumat from "components/pages/PPID/Maklumat";
import KebijakanPrivasi from "components/pages/PPID/KebijakanPrivasi";
import EPPID from "components/pages/PPID/EPpid";
import InformasiBerkala from "components/pages/PPID/Informasi-Berkala";
import LayananPublik from "components/pages/PPID/LayananPublic";
import RegistrasiPermohonan from "components/pages/PPID/RegistrasiPermohonan";
import InformasiSertaMerta from "components/pages/PPID/InformasiSertaMerta";
import InformasiSetiapSaat from "components/pages/PPID/InformasiSetiapSaat";

const LoadingScreen = () => {
    // console.log('render')
    return (
        <div className="flex flex-col items-center justify-center">
            <Circles
                height="80"
                width="80"
                color="#2FB6E9"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
            <div>Loading..</div>
        </div>
        // <div className="section relative ">
        //     <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] '>
        //         <Circles
        //             height="80"
        //             width="80"
        //             color="#2FB6E9"
        //             ariaLabel="circles-loading"
        //             wrapperStyle={{}}
        //             wrapperClass=""
        //             visible={true}
        //         />
        //     </div>
        // </div>
    )

}

const Router = () => {
    const {
        dispatch,
        global: {
            route
        },
        gcg
    } = useRedux();

    const [gcgMenu, setGcgMenu] = useState([])

    useEffect(() => {
        dispatch(getRoute())
        // dispatch(getMenuListGcg())
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (gcg?.menuList.length > 0) {
            let res = gcg?.menuList?.map((val) => {
                return {
                    title: val?.title,
                    path: `/${slugUrl(val?.title)}`,
                }
            })
            setGcgMenu(res)
        }
    }, [gcg?.menuList]); // eslint-disable-line react-hooks/exhaustive-deps

    const dataRoutes = ConfigRoute(route);


    const dummyProfil = [
        {
            title: 'Tentang Kami',
            path: '/tentang-kami',
        },
        {
            title: 'Manajemen',
            path: '/manajemen'
        },
        {
            title: 'Mitra/Pelanggan',
            path: '/mitra'
        },
        {
            title: 'Wilayah Kerja/Anak Perusahaan',
            path: '/wilayah-kerja'
        },
        {
            title: 'Struktur Organisasi',
            path: '/struktur-organisasi'
        },
        {
            title: 'Penghargaan/Sertifikasi',
            path: '/penghargaan'
        },
    ]
    const dummySubMenu = [
        {
            title: 'Beranda',
            path: '/',
        },
        {
            title: 'Profil',
            child: dummyProfil
        },
        {
            title: 'GCG',
            child: gcgMenu
        }
    ]

    const dummyHeadMenu = [
        {
            title: 'Karir',
            path: 'https://www.pelindo.co.id/page/karir',
            isEksternal: true,
            element: null,
        },
        {
            title: 'PPID',
            path: '/ppid',
            isEksternal: false,
            element: React.lazy(() => import(`components/pages/PPID`)),
        },
        {
            title: 'Pengadaan',
            path: 'https://secure.pelindo.co.id/',
            isEksternal: true,
            element: null,
        },

        {
            title: 'Kontak Kami',
            path: '/kontak-kami',
            isEksternal: false,
            element: React.lazy(() => import(`components/pages/KontakKami`)),
        },
    ]

    // useEffect(() => {
    //     if (route.length === 0) {
    //         let temp = []
    //         dummySubMenu?.map((route, index) => {
    //             if (route?.child?.length > 0) {
    //                 route?.child?.map((_route, index) => {
    //                     temp.push(_route)
    //                 })
    //             } else {
    //                 temp.push(route)
    //             }
    //             return;
    //         })
    //         setRoute(temp)
    //     }
    // }, [dummySubMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    const Wrapper = ({ children }) => {
        const location = useLocation();

        useLayoutEffect(() => {
            window.scrollTo(0, 0)
        }, [location.pathname])

        return children
    }

    return (
        <>

            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout headerMenu={dummyHeadMenu} headerSubMenu={dummySubMenu} />}>
                        <Route index element={<Home />} />
                        <Route path="/kontak-kami" element={<KontakKami />} />
                        <Route path="/ppid" element={<PPID />} />
                        <Route path="/profile" element={<Profile/>} />
                        <Route path="/regulasi" element={<Regulasi/>} />
                        <Route path="/maklumat" element={<Maklumat/>} />
                        <Route path="/kebijakan-privasi" element={<KebijakanPrivasi/>} />
                        <Route path="/eppid" element={<EPPID />} />
                        <Route path="/informasi-berkala" element={<InformasiBerkala />} />
                        <Route path="/layanan-publik" element={<LayananPublik />} />
                        <Route path="/registrasi-permohonan" element={<RegistrasiPermohonan />} />
                        <Route path="/informasi-serta-merta" element={<InformasiSertaMerta />} />
                        <Route path="/informasi-setiap-saat" element={<InformasiSetiapSaat />} />
                        <Route path="/search" element={<GlobalSearch />} />
                        <Route element={<LayoutDetail />} >
                            {dataRoutes?.map((route, index) => {
                                return <Route {...route} key={index} index={route?.index} element={
                                    <Suspense fallback={<LoadingScreen />}>
                                        <route.element />
                                    </Suspense>
                                } />;
                            })}
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}
export default Router