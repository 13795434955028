import BasicDropdown from "components/reuseable/Dropdown/BasicDropdown";
import SelectDropdown from "components/reuseable/Dropdown/SelectDropdown"
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  setActiveMenuSidebar,
  setMenuSidebar,
} from "redux/global/globalActions";
import { setAnakPerusahaan } from "redux/profil/profilActions";
import useRedux from "redux/useRedux";
// import icSelected from "./assets/images/icons/icon-selected.svg";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import profileDoc from "assets/images/kebijakanprivasi.svg";
import ppidInfo from "assets/images/icons/ppid-info.svg";
import ppidCheck from "assets/images/icons/ppid-check.svg";
import bgFooter from "assets/images/bg-footer-ppid.svg"
import ChatBox from "../../pages/PopUp/ChatBox";

const KebijakanPrivasi = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;
  // console.log('pathname', pathname)
  const {
    dispatch,
    global: { menu, menuSidebar, activeMenuSidebar },
    profil: { anakPerusahaan },
  } = useRedux();

  let navigate = useNavigate();

  const [open, setOpen] = useState(false);
  console.log('menu', menu);
  console.log('menuSidebar', menuSidebar);
  console.log('activeMenuSidebar', activeMenuSidebar);

  let pathFind = "";
  const findSubMenu = menu.find((val, ind) => {
    let child = val?.child;
    if (child?.length == 0) {
      pathFind = child?.find(
        (val) => val?.path.split("/")[1] === pathname.split("/")[1]
      );
      return child?.find(
        (val) => val?.path.split("/")[1] === pathname.split("/")[1]
      );
    }
    return null;
  });
  useEffect(() => {
    if (findSubMenu) {
      dispatch(setMenuSidebar(findSubMenu));
    }
    if (pathFind) {
      dispatch(setActiveMenuSidebar(pathFind));
    }
    return () => {
      dispatch(setMenuSidebar(null));
      dispatch(setActiveMenuSidebar(null));
    };
  }, [findSubMenu, pathFind]); // eslint-disable-line react-hooks/exhaustive-deps
  console.log('pathnames', pathFind?.path)
  console.log('findSubMenu', findSubMenu)
  console.log('pathFind', pathFind)

  const anakPerusahaanClick = (data) => {
    // console.log('anakPerusahaanClick', data)
    dispatch(
      setAnakPerusahaan({
        selected: data,
      })
    );
  };
  let dropdown = [
    { Title: "Profil", child: [{ title: "nama", path: "https:" }] },
    { Title: "GCG", child: [{ title: "nama", path: "https:" }] },
  ];

 
  const onClickMenu = (val) => {
    console.log('onClickMenu', val)
    val?.path && navigate(val?.path);
  };

  const bgTitle = (title) => {
    switch (title) {
      case "Profil":
        return "bg-profil";
      case "GCG":
        return "bg-gcg";
      case "Layanan":
        return "bg-layanan";
      case "Fasilitas":
        return "bg-fasilitas";
      case "Hubungan Investor":
        return "bg-hubungan";
      case "Media & Publikasi":
        return "bg-media";
      case "TJSL":
        return "bg-tjsl";
      default:
        return "bg-ppid";
    }
  };
  let tataKelola = [];
  let other = [];

  const menuSidebarss = ["E-PPID","Profile","Regulasi","Maklumat","Informasi Publik","Layanan Publik","Agenda","Registrasi Permohonan"];
  const menuSidebars = ["E-PPID"];
  let test2 = menuSidebar?.child.sort((a, b) => {
    return a.id - b.id;
  });

  console.log("length", menuSidebars?.child);

  return (
    <>
      <div className="mt-[110px]">
        <div
          className={`${bgTitle(
            "Profile"
          )} flex flex-col justify-center items-center`}
        >
          {/* <div className={`${bgTitle(findSubMenu?.title)} flex flex-col justify-center items-center`}> */}
          <div className="text-white text-[40px] font-semibold text-center">
            {"Kebijakan Privasi"}
            {/* {findSubMenu?.title} */}
          </div>
        </div>
        <div className="section pt-[50px]">
          {/* <div className="flex flex-row flex-wrap md:flex-nowrap md:divide-x-2"> */}
          <div className="md:grid md:grid-cols-6 md:divide-x-2 ">
            <div className="hidden md:flex md:flex-col md:mr-2">
              {/* {findSubMenu?.title && <div className="text-active text-[15px] uppercase font-bold mb-4">{findSubMenu?.title}</div>} */}
              { {menuSidebars} && (
            <div className="text-inactive text-[17px] mb-4">
              <Disclosure>
                   {({ open }) => (
                    <Disclosure.Button
                    className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  
                    <Link href="" to={"/ppid"}>
                    <div className="my-1 text-[17px] ">E-PPID</div>
                    </Link>
                  </Disclosure.Button>
                  )}
        
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button 
                    className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                
                    <Link href="" to={"/profile"}>
                      <div className="my-1 text-[17px] ">Profil</div>
                    </Link>
                    {/* <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-primary-500`}
                    /> */}
                  </Disclosure.Button>
                  {/* {menuSidebars?.map((val, ind) => {
                    return (
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                        <Link
                          key={ind}
                          className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${
                            activeMenuSidebar?.path === val?.path
                              ? "text-active font-semibold bg-[#F0F2F2] rounded-lg"
                              : ""
                          }`}
                          to={`${val?.isEksternal ? "#" : val?.path}`}
                          onClick={(e) => {
                            // if (val?.isEksternal) {
                              window.open("https://ppid.pelindo.co.id", "_blank");
                           // }
                          }}
                        >
                          {val?.title}
                        </Link>
                      </Disclosure.Panel>
                    );
                  })} */}
                </>
              )}
            </Disclosure>
            <Disclosure>
                   {({ open }) => (
                    <Disclosure.Button 
                    className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                   
                    <Link href="" to={"/regulasi"}>
                      <div className="my-1 text-[17px] ">Regulasi</div>
                    </Link>
                  </Disclosure.Button>
                  )}
            </Disclosure>
            <Disclosure>
                   {({ open }) => (
                    <Disclosure.Button 
                    className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                 
                    <Link href="" to={"/maklumat"}>
                       <div className="my-1 text-[17px] ">Maklumat</div>
                    </Link>
                  </Disclosure.Button>
                  )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    <div className="my-1 text-[17px] ">Informasi Publik</div>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-primary-500`}
                    />
                  </Disclosure.Button>
                  {menuSidebars?.map((val, ind) => {
                    return (
                      <>
                      
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                        <Disclosure>
                               {({ open }) => (
                                <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                <Link href="" to={"/informasi-berkala"}>
                                    <div className="my-1 text-[17px] ">Informasi Berkala</div>
                                </Link>
                                {/* <ChevronUpIcon
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-5 w-5 text-primary-500`}
                                /> */}
                              </Disclosure.Button>
                              )}
                        </Disclosure>
                        <Disclosure>
                               {({ open }) => (
                                <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                <Link href="" to={"/informasi-setiap-saat"}>
                                  <div className="my-1 text-[17px] ">Informasi Setiap Saat</div>
                                </Link>
                                {/* <ChevronUpIcon
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-5 w-5 text-primary-500`}
                                /> */}
                              </Disclosure.Button>
                              )}
                        </Disclosure>
                        <Disclosure>
                               {({ open }) => (
                                <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                <Link href="" to={"/informasi-serta-merta"}>
                                  <div className="my-1 text-[17px] ">Informasi Serta Merta</div>
                                </Link>
                                {/* <ChevronUpIcon
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-5 w-5 text-primary-500`}
                                /> */}
                              </Disclosure.Button>
                              )}
                        </Disclosure>
                      </Disclosure.Panel>
                      </>
                    );
                  })}
                </>
              )}
            </Disclosure>
            <Disclosure>
                   {({ open }) => (
                    <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                   
                    <Link href="" to={"/layanan-publik"}>
                        <div className="my-1 text-[17px] ">Layanan Publik</div>
                    </Link>
                  </Disclosure.Button>
                  )}
            </Disclosure>
            <Disclosure>
                   {({ open }) => (
                    <Disclosure.Button
                    className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                
                    <Link href="" to={"/kebijakan-privasi"}>
                        <div className="my-1 text-[17px] ">Kebijakan Privasi</div>
                    </Link>
                  </Disclosure.Button>
                  )}
            </Disclosure>
            <Disclosure>
                   {({ open }) => (
                    <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    <Link href="" to={"/registrasi-permohonan"}>
                        <div className="my-1 text-[17px] ">Registrasi Permohonan</div>
                    </Link>              
                        
                      
                  </Disclosure.Button>
                  )}
            </Disclosure>                 
              
            </div>
          )}
              {/* {findSubMenu?.title?.length > 0 && (
                                findSubMenu?.child?.map((val, ind) => {
                                    return (
                                        <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${pathname === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                                            {val?.title}
                                        </Link>
                                    )
                                })
                            )} */}
              
              {menuSidebars?.child?.map((val, ind) => {               
                  if(val?.title == undefined ||val?.title == undefined){
                  return (
                    <>
                 
                  </>
                  );
                }
                })}
              {other?.length == undefined &&
                other.map((val, ind) => {
                  
                  if(val?.title == undefined ||val?.title == undefined){
               
                }})}
              {
                // <BasicDropdown
                //     className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg 'text-active font-semibold bg-[#F0F2F2] rounded-lg'`}
                //     title={'Tata Kelola'}
                //     items={tataKelola}
                //     onClickItems={onClickMenu}
                // />

                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                        {/* <div className="my-1 text-[15px] ">Detail</div>
                        <ChevronUpIcon
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-primary-500`}
                        /> */}
                      </Disclosure.Button>
                      {menuSidebars?.map((val, ind) => {
                        return (
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                              <Link
                              className="flex flex-col items-center justify-center text-active"
                              to={"#"}
                              onClick={(e) => {
                                window.open("https://ppid.pelindo.co.id/informasi", "_blank");
                              }}
                            >
                             
                              <div className="text-[15px] mt-50 text-center">Informasi berkala</div>
                            </Link>
                          </Disclosure.Panel>
                        );
                      })}
                    </>
                  )}
                </Disclosure>
              
              }

              {other?.length > 0 &&
                other.map((val, ind) => {
                  if(val?.title!=="Manual GCG"&&val?.title!=="Whistleblowing System"){
                  return (
                    // <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${pathname === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                    //     {val?.title}
                    // </Link>
                    // <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${menuSidebar?.active?.path === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                    //     {val?.title}
                    // </Link>
                    <>
                      <Link
                        key={ind}
                        className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${
                          activeMenuSidebar?.path === val?.path
                            ? "text-active font-semibold bg-[#F0F2F2] rounded-lg"
                            : ""
                        }`}
                        to={`${val?.isEksternal ? "#" : val?.path}`}
                        onClick={(e) => {
                          if (val?.isEksternal) {
                            window.open(val?.path, "_blank");
                          }
                        }}
                      >
                        {val?.title}
                      </Link>
                    </>
                  );
                }})}

      
            {menuSidebars?.child?.map((val, ind) => {  
            return (
    <>
  
    </>
  );
})}
            </div>
            {/* <div>
                            {
                                dropdown?dropdown.map((data)=>

                                ):null
                            }
                        </div> */}
            <div className="md:col-span-3 md:flex md:flex-col pl-0 md:pl-[20px]">
              {/* <div className="section flex flex-col ml-10 px-[20px] pt-[30px] md:px-[2px]"> */}
              {pathFind?.path === "/wilayah-kerja-anak-perusahaan" &&
                anakPerusahaan?.data?.length > 0 && (
                  <div className="py-6 border-t-2 md:hidden">
                    <SelectDropdown
                        items={anakPerusahaan?.data}
                        onClickItems={anakPerusahaanClick}
                        selectedTitle={anakPerusahaan?.selected?.company_name}
                        selectedValue={anakPerusahaan?.selected}
                    />
                  </div>
                )}
              <div className="section-detail flex flex-col">
                <Outlet context={pathFind} />
              </div>
            </div>
          </div>
          <div className="flex my-10 mx-2 md:mx-20 text-justify md:pl-[100px]">
              {pathFind?.path == undefined &&
                menuSidebars?.length > 0 && (
                  <div className="">
                    {menuSidebars?.map((val, ind) => {
                       return (
                        <>
                        <div className="mt-[0px]">
                          
                          <div className="section" style={{ marginTop: '-1025px', width:'118%' }}>
                            <div className="flex flex-col my-10 mx-2 md:mx-20 text-justify">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                            <img src={profileDoc} alt="profile-doc" style={{ maxWidth: '500px', height: '400px' }} />
                            </div>
                             <br></br>
                              <div className="text-[15px]">
                              <div className="mt-2">
                              Kebijakan privasi menjelaskan kebijakan kami atas segala informasi/data pribadi yang Pengguna berikan kepada Pejabat Pengelola Informasi dan Dokumentasi (PPID) PT Pelindo Solusi Logistik melalui situs website https://pelindosolusilogistik.co.id/. Harap memperhatikan ketentuan di bawah ini secara saksama untuk memahami bagaimana perlakuan terhadap data pribadi tersebut.
                              </div>
                              <div className="mt-2">
                              Pemberitahuan ini berlaku untuk data pribadi yang kami miliki atau di bawah kendali kami, termasuk data pribadi yang dimiliki institusi yang kami libatkan untuk mengumpulkan, menggunakan, mengungkapkan atau memproses data pribadi untuk tujuan kami.
                              </div>
                              <div className="mt-2">
                                Dengan mengakses dan menggunakan layanan PPD PT Pelindo Solusi Logistik (situs web https://pelindosolusilogistik.co.id/). Pengguna dianggap telah membaca, memahami, dan memberikan persetujuannya terhadap pengumpulan dan penggunaan data pribadi Pengguna sebagaimana dijelaskan di bawah ini.
                                </div>
                                <div className="mt-2">
                                 Pengguna tidak menyetujui terhadap salah satu, sebagian, atau seluruh isi yang tertuang dalam Kebijakan Privasi ini, maka Pengguna diperkenankan untuk tidak menggunakan layanan dalam perangkat elektronik dan/atau tidak mengakses layanan dan/atau tidak menggunakan website https://pelindosolusilogistik.co.id/. PPID PT Pelindo Solusi logistik dilepaskan dari seluruh tanggung jawab dari seluruh kerugian yang Pengguna terima sehubungan keputusan untuk tidak menggunakan Layanan ini.
                                </div>
                                <div className="mt-2 font-bold">
                                1. Data Pribadi 
                                </div>
                                <div className="mt-2">
                                Data Pribadi merupakan data tentang orang perseorangan yang teridentifikasi atau dapat diidentifikasi secara tersendiri atau dikombinasi dengan informasi lainnya baik secara langsung maupun tidak langsung melalui sistem elektronik atau non elektronik.
                                Dalam memberikan layanan informasi publik, PPID PT Pelindo Solusi logistik mengumpulkan informasi/data berupa nama, alamat, nomor telepon, email, scan file identitas dan lainnya dari Pengguna sebagai data dasar dalam memverifikasi identitas pemohon informasi publik.
                                </div>
                                <div className="mt-2 font-bold">
                                2. Pengumpulan, Penggunaan, dan Pengungkapan Data Pribadi
                                </div>
                                <div className="mt-2">
                                Informasi/data pribadi dikumpulkan dan digunakan dari Pengguna sewaktu Pengguna menggunakan layanan PPID PT Pelindo Solusi logistik melalui situs web e-PPID PT Pelindo Solusi logistik pada perangkat pengguna. Pengungkapan data pribadi dilakukan apabila kami memberikan layanan yang diminta oleh pengguna, mematuhi hukum dalam rangka membantu penegakan hukum dan penyelidikan.
                                </div>
                                <div className="mt-2 font-bold">
                                3. Keamanan Kerahasiaan Informasi/Data Pribadi Pengguna
                                </div>
                                <div className="mt-2">
                                Pengguna harus menyadari, bahwa tidak ada metode transmisi melalui Internet atau metode penyimpanan elektronik yang sepenuhnya aman. PPID PT Pelindo Solusi logistik selalu berusaha melindungi informasi/data pribadi yang diberikan oleh Pengguna dengan meningkatkan langkah- langkah keamanan informasi kami berdasarkan peraturan perundang-undangan yang berlaku.
                                </div>
                                <div className="mt-2 font-bold">
                                4. Rentensi Data Pribadi
                                </div>
                                <div className="mt-2">
                                Kami dapat menyimpan data pribadi Pengguna selama diperlukan untuk memenuhi tujuan pengumpulannya, atau sebagaimana diharuskan atau diizinkan oleh undang-undang yang berlaku.
                                Kami akan berhenti menyimpan data pribadi pengguna, atau menghapus segera setelah menganggap bahwa penyimpanan tersebut tidak lagi memenuhi tujuan pengumpulan data pribadi, dan tidak ada lagi diperlukan untuk keperluan hukum atau layanan.
                                </div>
                                <div className="mt-2 font-bold">
                                5. Pembaruan Kebijakan Privasi
                                </div>
                                <div className="mt-2">
                                Kebijakan Privasi ini mungkin diubah dan/atau diperbaharui dari waktu ke waktu tanpa pemberitahuan sebelumnya. PPID PT Pelindo Solusi logistik menyarankan agar Pengguna membaca secara saksama dan memeriksa halaman Kebijakan Privasi ini dari waktu ke waktu untuk mengetahui perubahan apa pun. Dengan tetap mengakses dan menggunakan layanan PPID PT Pelindo Solusi logistik, maka Pengguna dianggap menyetujui perubahan-perubahan dalam Kebijakan Privasi ini.
                                </div>
                                <br></br>
                                <div className="mt-2">
                                Untuk konfirmasi mengenai Kebijakan Privasi, Pengguna dapat menghubungi:
                                </div>
                                <div className="mt-2">
                                Telp.   : +62-21 5091 8800
                                </div>
                                <div className="mt-2">
                                Email  : infosolusilogistik@pelindo.co.id
                                </div>
                                <div className="mt-2">
                                Dengan melampirkan subjek surat elektronik berupa Data Pribadi
                                </div>
                              </div>
                         
                            </div>
                          </div>
                        </div>
                      </>
                      );
                    })}
                  </div>
                )}
            </div>
        </div>
      </div>
      <img src={bgFooter} alt="icon" className="relative object-fill w-full" />
      <ChatBox/>
      {/* <img src={bgFooter} alt="icon" className="relative object-fill w-full" /> */}
    </>
  );
  
};
export default KebijakanPrivasi;
