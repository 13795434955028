import React, { createRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const TransitionDropdown = ({
    title = "",
    items = [],
    withChevron = false,
    colorChevron = "primary",
    toggleMainMenu
}) => {
    const [isOpen, setIsOpen] = useState(false);
    let navigate = useNavigate();
    const ref = createRef();
    const toggleIsOpen = () => {
        setIsOpen(prev => !prev)
    }
    const onClickMenu = (val) => {
        // console.log('onClickMenu', val)
        val?.path && navigate(val?.path)
        toggleMainMenu()
    }
    return (
        <>
            <button
                onClick={toggleIsOpen}
                className='flex flex-row font-semibold p-[10px]'
            >
                <div className='mr-2'>{title}</div>
                {withChevron && <div><FontAwesomeIcon icon={faChevronDown} color={colorChevron} /></div>}
            </button>
            {
                items.length > 0 && (
                    <Transition
                        ref={ref}
                        show={isOpen}
                        enter="transition ease-out duration-100 transform"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75 transform"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        {
                            (ref) => {
                                return (
                                    <>
                                        <div className="flex flex-wrap flex-col p-[10px] font-semibold">
                                            {items?.map((val, ind) => {
                                                return (
                                                    <div key={ind} className="p-[10px]" onClick={() => onClickMenu(val)}>
                                                        {val?.title}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                )
                            }
                        }
                    </Transition>
                )
            }
        </>
    )
}
export default TransitionDropdown;