import BasicDropdown from "components/reuseable/Dropdown/BasicDropdown";
import SelectDropdown from "components/reuseable/Dropdown/SelectDropdown"
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  setActiveMenuSidebar,
  setMenuSidebar,
} from "redux/global/globalActions";
import { setAnakPerusahaan } from "redux/profil/profilActions";
import useRedux from "redux/useRedux";
// import icSelected from "./assets/images/icons/icon-selected.svg";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import ppidDoc from "assets/images/icons/ppid-doc.svg";
import ppidInfo from "assets/images/icons/ppid-info.svg";
import ppidCheck from "assets/images/icons/ppid-check.svg";
import bgFooter from "assets/images/bg-footer-ppid.svg"
import ChatBox from "../../pages/PopUp/ChatBox";
import axios from "axios";

const  RegistrasiPermohonan = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;
  // console.log('pathname', pathname)
  const {
    dispatch,
    global: { menu, menuSidebar, activeMenuSidebar },
    profil: { anakPerusahaan },
  } = useRedux();

  let navigate = useNavigate();

  const [open, setOpen] = useState(false);
  console.log('menu', menu);
  console.log('menuSidebar', menuSidebar);
  console.log('activeMenuSidebar', activeMenuSidebar);

  let pathFind = "";
  const findSubMenu = menu.find((val, ind) => {
    let child = val?.child;
    if (child?.length == 0) {
      pathFind = child?.find(
        (val) => val?.path.split("/")[1] === pathname.split("/")[1]
      );
      return child?.find(
        (val) => val?.path.split("/")[1] === pathname.split("/")[1]
      );
    }
    return null;
  });
  useEffect(() => {
    if (findSubMenu) {
      dispatch(setMenuSidebar(findSubMenu));
    }
    if (pathFind) {
      dispatch(setActiveMenuSidebar(pathFind));
    }
    return () => {
      dispatch(setMenuSidebar(null));
      dispatch(setActiveMenuSidebar(null));
    };
  }, [findSubMenu, pathFind]); // eslint-disable-line react-hooks/exhaustive-deps
  console.log('pathnames', pathFind?.path)
  console.log('findSubMenu', findSubMenu)
  console.log('pathFind', pathFind)

  const anakPerusahaanClick = (data) => {
    dispatch(
      setAnakPerusahaan({
        selected: data,
      })
    );
  };
 
  const bgTitle = (title) => {
    switch (title) {
      case "Profil":
        return "bg-profil";
      case "GCG":
        return "bg-gcg";
      case "Layanan":
        return "bg-layanan";
      case "Fasilitas":
        return "bg-fasilitas";
      case "Hubungan Investor":
        return "bg-hubungan";
      case "Media & Publikasi":
        return "bg-media";
      case "TJSL":
        return "bg-tjsl";
      default:
        return "bg-ppid";
    }
  };
  let other = [];

  const menuSidebarss = ["E-PPID","Profile","Regulasi","Maklumat","Informasi Publik","Layanan Publik","Agenda","Registrasi Permohonan"];
  const menuSidebars = ["E-PPID"];
  let test2 = menuSidebar?.child.sort((a, b) => {
    return a.id - b.id;
  });
  
  //Bagian set data untuk di kirim ke email
  const [formData, setFormData] = useState({
   jenisPemohon: '',
   jenisIdentitas: '',
   namaIdentitas: '',
   namaLengkap: '',
   npwp: '',
   pekerjaan: '',
   telp: '',
   fax: '',
   alamat: ''
  })

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({
      ...formData, [name]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();


    try {
      const formdata = new FormData();
      // formdata.append("to", "agit.zaini@gmail.com");
      formdata.append("to", "infosolusilogistik@pelindo.co.id");
      formdata.append("topic", "PPID");
      formdata.append("payload", `jenisPemohon: ${formData.jenisPemohon},
          jenisIdentitas: ${formData.jenisIdentitas},
          namaIdentitas: ${formData.namaIdentitas},
          namaLengkap: ${formData.namaLengkap},
          npwp: ${formData.npwp},
          pekerjaan: ${formData.pekerjaan},
          telp: ${formData.telp},
          fax: ${formData.fax},
          alamat: ${formData.alamat}`);
      
      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

fetch("https://pelindo-hub.pelindo.co.id/psl/email", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));

    } catch (error) {
      console.log('Error:', error)
    }
  }

  return (  
  <>
  <div className="mt-[110px]">
    <div
      className={`${bgTitle(
        "Registrasi Permohonan"
      )} flex flex-col justify-center items-center`}
    >
      {/* <div className={`${bgTitle(findSubMenu?.title)} flex flex-col justify-center items-center`}> */}
      <div className="text-white text-[40px] font-semibold  text-center">
        {"Registrasi Permohonan"}
        {/* {findSubMenu?.title} */}
      </div>
    </div>
    <div className="section pt-[50px]">
      {/* <div className="flex flex-row flex-wrap md:flex-nowrap md:divide-x-2"> */}
      <div className="md:grid md:grid-cols-6 md:divide-x-2 ">
        <div className="hidden md:flex md:flex-col md:mr-2">
          {/* {findSubMenu?.title && <div className="text-active text-[15px] uppercase font-bold mb-4">{findSubMenu?.title}</div>} */}
          { {menuSidebars} && (
            <div className="text-inactive text-[17px] mb-4">
              <Disclosure>
                    {({ open }) => (
                      <Disclosure.Button
                      className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    
                      <Link href="" to={"/ppid"}>
                      <div className="my-1 text-[17px] ">E-PPID</div>
                      </Link>
                    </Disclosure.Button>
                    )}
          
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button 
                      className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  
                      <Link href="" to={"/profile"}>
                        <div className="my-1 text-[17px] ">Profil</div>
                      </Link>
                      {/* <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-primary-500`}
                      /> */}
                    </Disclosure.Button>
                    {/* {menuSidebars?.map((val, ind) => {
                      return (
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                          <Link
                            key={ind}
                            className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${
                              activeMenuSidebar?.path === val?.path
                                ? "text-active font-semibold bg-[#F0F2F2] rounded-lg"
                                : ""
                            }`}
                            to={`${val?.isEksternal ? "#" : val?.path}`}
                            onClick={(e) => {
                              // if (val?.isEksternal) {
                                window.open("https://ppid.pelindo.co.id", "_blank");
                            // }
                            }}
                          >
                            {val?.title}
                          </Link>
                        </Disclosure.Panel>
                      );
                    })} */}
                  </>
                )}
              </Disclosure>
              <Disclosure>
                    {({ open }) => (
                      <Disclosure.Button 
                      className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    
                      <Link href="" to={"/regulasi"}>
                        <div className="my-1 text-[17px] ">Regulasi</div>
                      </Link>
                    </Disclosure.Button>
                    )}
              </Disclosure>
              <Disclosure>
                    {({ open }) => (
                      <Disclosure.Button 
                      className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  
                      <Link href="" to={"/maklumat"}>
                        <div className="my-1 text-[17px] ">Maklumat</div>
                      </Link>
                    </Disclosure.Button>
                    )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      <div className="my-1 text-[17px] ">Informasi Publik</div>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-primary-500`}
                      />
                    </Disclosure.Button>
                    {menuSidebars?.map((val, ind) => {
                      return (
                        <>
                        
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                          <Disclosure>
                                {({ open }) => (
                                  <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                  <Link href="" to={"/informasi-berkala"}>
                                      <div className="my-1 text-[17px] ">Informasi Berkala</div>
                                  </Link>
                                  {/* <ChevronUpIcon
                                    className={`${
                                      open ? "rotate-180 transform" : ""
                                    } h-5 w-5 text-primary-500`}
                                  /> */}
                                </Disclosure.Button>
                                )}
                          </Disclosure>
                          <Disclosure>
                                {({ open }) => (
                                  <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                  <Link href="" to={"/informasi-setiap-saat"}>
                                    <div className="my-1 text-[17px] ">Informasi Setiap Saat</div>
                                  </Link>
                                  {/* <ChevronUpIcon
                                    className={`${
                                      open ? "rotate-180 transform" : ""
                                    } h-5 w-5 text-primary-500`}
                                  /> */}
                                </Disclosure.Button>
                                )}
                          </Disclosure>
                          <Disclosure>
                                {({ open }) => (
                                  <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                  <Link href="" to={"/informasi-serta-merta"}>
                                    <div className="my-1 text-[17px] ">Informasi Serta Merta</div>
                                  </Link>
                                  {/* <ChevronUpIcon
                                    className={`${
                                      open ? "rotate-180 transform" : ""
                                    } h-5 w-5 text-primary-500`}
                                  /> */}
                                </Disclosure.Button>
                                )}
                          </Disclosure>
                        </Disclosure.Panel>
                        </>
                      );
                    })}
                  </>
                )}
              </Disclosure>
              <Disclosure>
                    {({ open }) => (
                      <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    
                      <Link href="" to={"/layanan-publik"}>
                          <div className="my-1 text-[17px] ">Layanan Publik</div>
                      </Link>
                    </Disclosure.Button>
                    )}
              </Disclosure>
              <Disclosure>
                    {({ open }) => (
                      <Disclosure.Button
                      className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  
                      <Link href="" to={"/kebijakan-privasi"}>
                          <div className="my-1 text-[17px] ">Kebijakan Privasi</div>
                      </Link>
                    </Disclosure.Button>
                    )}
              </Disclosure>
              <Disclosure>
                    {({ open }) => (
                      <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      <Link href="" to={"/registrasi-permohonan"}>
                          <div className="my-1 text-[17px] ">Registrasi Permohonan</div>
                      </Link>              
                          
                        
                    </Disclosure.Button>
                    )}
              </Disclosure>                 
            </div>
          )}
          {other?.length == undefined &&
            other.map((val, ind) => {
              
              if(val?.title == undefined ||val?.title == undefined){
           
            }})}
          {

            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    {/* <div className="my-1 text-[15px] ">Detail</div>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-primary-500`}
                    /> */}
                  </Disclosure.Button>
                  {menuSidebars?.map((val, ind) => {
                    return (
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                          <Link
                          className="flex flex-col items-center justify-center text-active"
                          to={"#"}
                          onClick={(e) => {
                            window.open("https://ppid.pelindo.co.id/informasi", "_blank");
                          }}
                        >
                         
                          <div className="text-[15px] mt-50 text-center">Informasi berkala</div>
                        </Link>
                      </Disclosure.Panel>
                    );
                  })}
                </>
              )}
            </Disclosure>
          
          }

          {other?.length > 0 &&
            other.map((val, ind) => {
              if(val?.title!=="Manual GCG"&&val?.title!=="Whistleblowing System"){
              return (
                <>
                  <Link
                    key={ind}
                    className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${
                      activeMenuSidebar?.path === val?.path
                        ? "text-active font-semibold bg-[#F0F2F2] rounded-lg"
                        : ""
                    }`}
                    to={`${val?.isEksternal ? "#" : val?.path}`}
                    onClick={(e) => {
                      if (val?.isEksternal) {
                        window.open(val?.path, "_blank");
                      }
                    }}
                  >
                    {val?.title}
                  </Link>
                </>
              );
            }})}
        </div>
        <div className="md:col-span-3 md:flex md:flex-col pl-0 md:pl-[20px]">
          {/* <div className="section flex flex-col ml-10 px-[20px] pt-[30px] md:px-[2px]"> */}
          {pathFind?.path === "/wilayah-kerja-anak-perusahaan" &&
            anakPerusahaan?.data?.length > 0 && (
              <div className="py-6 border-t-2 md:hidden">
                <SelectDropdown
                    items={anakPerusahaan?.data}
                    onClickItems={anakPerusahaanClick}
                    selectedTitle={anakPerusahaan?.selected?.company_name}
                    selectedValue={anakPerusahaan?.selected}
                />
              </div>
            )}
          <div className="section-detail flex flex-col">
            <Outlet context={pathFind} />
          </div>
        </div>
      </div>
      <div className="flex my-10 mx-2 md:mx-20 text-justify md:pl-[100px]">
          {pathFind?.path == undefined &&
            menuSidebars?.length > 0 && (
              <div className="">
                {menuSidebars?.map((val, ind) => {
                    return(
                      <>
                        <div className="md:mt-[0px] lg:mt-[0px]">
                          <div className="section md:mt-[-600px] lg:mt-[-1000px] " style={{ width:'118%' }}>
                            <div className="image-container">
                              <form onSubmit={handleSubmit}>
                                <div class="space-y-8">
                                  <div class="border-b border-gray-900/10 ">
                                    <h2 class="text-base font-semibold leading-7 text-gray-900">Registrasi Permohonan Informasi</h2>
                                    <p class="mt-1 text-sm leading-6 text-gray-600">Formulir ini digunakan untuk registrasi pemohon. Dengan mendaftar di Sistem Informasi PPID, pemohon mendapatkan kemudahan dalam mengajukan permohonan informasi maupun pengajuan keberatan secara online</p>
                                  </div>
                              
                                  <div class="border-b border-gray-900/10 pb-12">
                                    <h2 class="text-base font-semibold leading-7 text-gray-900">Data Pemohon</h2>
                                    {/* <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}
                              
                                    <div class="mt-5 grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-6">
                                      <div class="sm:col-span-3">
                                        <label for="jenis-pemohon" class="block text-sm font-medium leading-6 text-gray-900">Jenis Pemohon</label>
                                        <div class="mt-2">
                                          <select id="jenisPemohon" onChange={handleChange} value={formData.jenisPemohon} name="jenisPemohon" autocomplete="jenis-pemohon-name" class="block w-full rounded-md border-0 py-3 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <option value={`Perorangan`}>Perorangan</option>
                                            <option value={`Kelompok Orang`}>Kelompok Orang</option>
                                            <option value={`Badan Hukum`}>Badan Hukum</option>
                                          </select>
                                          {/* <input type="text" name="jenis-pemohon" id="jenis-pemohon" autocomplete="given-name" placeholder="PERORANGAN" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/> */}
                                        </div>
                                      </div>
                              
                                      <div class="sm:col-span-3">
                                        <label for="jenisIdentitas" class="block text-sm font-medium leading-6 text-gray-900">Jenis Identitas</label>
                                        <div class="mt-2">
                                        <select id="jenisIdentitas"  value={formData.jenisIdentitas} onChange={handleChange} name="jenisIdentitas" autocomplete="jenis-identitas-name" class="block w-full rounded-md border-0 py-3 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <option value={`KTP`}>KTP</option>
                                            <option value={`Pengesahan Bahan Usaha`}>Pengesahan Badan Usaha</option>
                                            <option value={`Surat Kuasa`}>Surat Kuasa</option>
                                            <option value={`Badan Publik`}>Badan Publik</option>
                                          </select>
                                          {/* <input type="text" name="jenis-identitas" id="jenis-identitas" autocomplete="family-name" placeholder="KTP" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/> */}
                                        </div>
                                      </div>
                              
                                      <div class="sm:col-span-6">
                                        <label for="namaIdentitas" class="block text-sm font-medium leading-6 text-gray-900">Nama Identias</label>
                                        <div class="mt-2">
                                          <input id="namaIdentitas"  value={formData.namaIdentitas} onChange={handleChange} name="namaIdentitas" type="text" autocomplete="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                      </div>
                              
                                      <div class="sm:col-span-6">
                                        <label for="namaLengkap" class="block text-sm font-medium leading-6 text-gray-900">Nama Lengkap</label>
                                        <div class="mt-2">
                                          <input id="namaLengkap" value={formData.namaLengkap} onChange={handleChange} name="namaLengkap" type="text" autocomplete="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                      </div>
                                      
                                      <div class="sm:col-span-3">
                                        <label for="npwp" class="block text-sm font-medium leading-6 text-gray-900">NPWP</label>
                                        <div class="mt-2">
                                          <input type="text" onChange={handleChange} name="npwp" value={formData.npwp} id="npwp" autocomplete="given-name" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                      </div>
                              
                                      <div class="sm:col-span-3">
                                        <label for="pekerjaan" class="block text-sm font-medium leading-6 text-gray-900">Pekerjaan</label>
                                        <div class="mt-2">
                                          <input type="text" value={formData.pekerjaan} onChange={handleChange} name="pekerjaan" id="pekerjaan" autocomplete="family-name" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                      </div>
                                      
                                      <div class="sm:col-span-3">
                                        <label for="telp" class="block text-sm font-medium leading-6 text-gray-900">No Telp</label>
                                        <div class="mt-2">
                                          <input type="text" onChange={handleChange} name="telp" value={formData.telp} id="telp" autocomplete="given-name" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                      </div>
                              
                                      <div class="sm:col-span-3">
                                        <label for="fax" class="block text-sm font-medium leading-6 text-gray-900">No Fax</label>
                                        <div class="mt-2">
                                          <input type="text" onChange={handleChange} name="fax" value={formData.fax} id="fax" autocomplete="family-name" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                      </div>
                              
                                      <div class="sm:col-span-6">
                                        <label for="nama-lengkap" class="block text-sm font-medium leading-6 text-gray-900">Alamat</label>
                                        <div class="mt-2">
                                          <input id="alamat" onChange={handleChange} name="alamat" value={formData.alamat} type="text" autocomplete="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                      </div>
                              
                                    </div>
                                  </div>
                              
                                </div>
                              
                                <div class="mt-6 flex items-center justify-center gap-x-6">
                                  {/* <button type="button" class="text-sm font-semibold leading-6 text-gray-900">Cancel</button> */}
                                  <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Submit Registrasi Permohonan</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                  
                      </>
                    )
                })}
              </div>
            )
          }
      </div>
    </div>
</div>
<img src={bgFooter} alt="icon" className="relative object-fill w-full" />
<ChatBox />
</>
);

};
export default RegistrasiPermohonan;
