import Swal from "sweetalert2";
import { responseMessage } from "utils/ResponseStatus";

export function SwalSuccess({ title, text }) {
    Swal.fire({
        icon: 'success',
        title: title,
        text: text,
        allowOutsideClick: false
    })
}

export function SwalSuccessThen({ title = "Success", text = "Success", callback }) {
    Swal.fire({
        icon: 'success',
        title: title,
        text: text,
        allowOutsideClick: false
    }).then((result) => {
        if (result) {
            return callback();
        }
    })
}

export function SwalError({ text }) {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: text,
        allowOutsideClick: false
    })
}

export function SwalErrorThen({ text, callback }) {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: text,
        allowOutsideClick: false
    }).then((result) => {
        if (result) {
            return callback();
        }
    })
}

export function SwalConfirmThen({ callback, title = 'Are you sure?', text = '' }) {
    Swal.fire({
        icon: 'warning',
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!',
        reverseButtons: true,
        allowOutsideClick: false,
    }).then((result) => {
        if (result.value) {
            return callback()
        }
    })
}

export function SwalPreConfirm({ preConfirm, callback, title = 'Are you sure?', text = "You won't be able to revert this!" }) {
    Swal.fire({
        title: title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!',
        reverseButtons: true,
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        preConfirm: () => {
            return preConfirm()
                .catch((error) => {
                    SwalValidationMessage(responseMessage(error?.response?.data))
                })
        },
    }).then((result) => {
        if (result.isConfirmed) {
            return callback()
        }
    })
}
export function SwalValidationMessage(message) {
    Swal.showValidationMessage(
        `Request failed: ${message}`
    )
}

export function SwalRequestApi({ request, title = 'Loading..', then = () => null, cb = () => null }) {
    return Swal.fire({
        titleText: title,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();
            return request().then((data) => {
                SwalSuccess({ text: data?.response?.data?.msg })
                then(data)
                return Promise.resolve(data)
            })
                .catch((data) => {
                    SwalError({ text: data?.response?.data?.msg })
                    cb(data)
                })
        },
    });
}