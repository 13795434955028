import React, { useEffect, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import PropTypes from "prop-types";
import requiredIf from 'utils/PropTypesRequiredIf';
import RenderError from '../RenderError';

let value
const ReactSlideshow = (props) => {
    const { children, duration, transitionDuration, defaultIndex, indicators, prevArrow, nextArrow, arrows, autoplay, infinite, pauseOnHover, canSwipe, easing, cssClass, onChange, responsive, slidesToShow, slidesToScroll, customIndicators } = props;

    const ref = React.createRef();

    // const customIndicators = (index) => {
    //     console.log('customIndicators', index)
    //     return (
    //         <div className="indicator">
    //             {index + 1}
    //         </div>
    //     );
    // }
    // const customArrow = {
    //     prevArrow: <button>
    //         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" /></svg>
    //     </button>,
    //     nextArrow: <button>
    //         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z" /></svg>
    //     </button>
    // }

    const [requiredProps, setRequiredProps] = useState([])
    const [errorProps, seterrorProps] = useState("")
    let temp = []
    useEffect(() => {
        for (const key in ReactSlideshow.propTypes) { //eslint-disable-line react/forbid-foreign-prop-types
            if (!ReactSlideshow.propTypes[key].isRequired) { //eslint-disable-line react/forbid-foreign-prop-types
                temp.push(key)
            }
        }
        if (temp.length > 0) {
            setRequiredProps(temp)
        }
    }, []) //eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (requiredProps.length > 0) {
            for (const key in requiredProps) {
                if (!props[requiredProps[key]]) {
                    seterrorProps(requiredProps[key]);
                    break;
                }
            }
        }
    }, [requiredProps]); //eslint-disable-line react-hooks/exhaustive-deps

    if (errorProps) {
        return <RenderError component="ReactSlideshow" err={errorProps} />
    }


    return (
        <>
            <Slide ref={ref} duration={duration} transitionDuration={transitionDuration} defaultIndex={defaultIndex} indicators={(indicators && customIndicators) ? customIndicators : indicators} prevArrow={prevArrow} nextArrow={nextArrow} arrows={arrows} autoplay={autoplay} infinite={infinite} pauseOnHover={pauseOnHover} canSwipe={canSwipe} easing={easing} cssClass={cssClass} onChange={onChange} responsive={responsive} slidesToShow={slidesToShow} slidesToScroll={slidesToScroll}>
                {children}
            </Slide>
        </>
    )
}

ReactSlideshow.defaultProps = {
    duration: 5000,
    transitionDuration: 1000,
    defaultIndex: 0,
    indicators: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    pauseOnHover: true,
    canSwipe: true,
    easing: "linear", //linear, ease, ease-in, ease-out, cubic, cubic-in, cubic-out
    cssClass: "",
    responsive: [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }
    ],
    customIndicators: null
};

ReactSlideshow.propTypes = {
    children: PropTypes.any.isRequired,
    duration: PropTypes.number, //The time it takes (milliseconds) before next transition starts
    transitionDuration: PropTypes.number, //Determines how long the transition takes
    defaultIndex: PropTypes.number, //Specifies the first slide to display
    indicators: PropTypes.bool, //For specifying if there should be dots below the slideshow. If function; it will render the returned element
    prevArrow: PropTypes.any, //A custom element to serve as previous arrow
    nextArrow: PropTypes.any, //A custom element to serve as next arrow
    arrows: PropTypes.bool, //Determines if there should be a navigational arrow for going to the next or previous slide
    autoplay: PropTypes.bool, //Responsible for determining if the slideshow should start automatically
    infinite: PropTypes.bool, //Specifies if the transition should loop infinitely
    pauseOnHover: PropTypes.bool, //Determines whether the transition effect applies when the mouse hovers the slider
    canSwipe: PropTypes.bool, //Determines whether the user can go to next or previous slide by the mouse or by touching
    easing: PropTypes.string, //The timing transition function to use. You can use one of linear, ease, ease-in, ease-out, cubic, cubic-in, cubic-out
    cssClass: PropTypes.string, //Use this prop to add your custom css to the wrapper containing the sliders. Pass your css className as value for the cssClass prop
    onChange: PropTypes.func, //Callback that gets triggered at the end of every transition. The oldIndex and newIndex are passed as arguments
    responsive: PropTypes.array, //Set slidesToShow & slidesToScroll based on screen size
    slidesToShow: PropTypes.number, //The number of slides to show on each page
    slidesToScroll: PropTypes.number, //The number of slides to scroll
    customIndicators: PropTypes.any,
};

export default ReactSlideshow