import React, { useLayoutEffect } from "react";
import Header from "components/reuseable/Header";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "components/reuseable/Footer";

const Layout = ({ children, headerSubMenu }) => {

    return (
        <>
            <Header headerSubMenu={headerSubMenu} />
            <Outlet />
            <Footer />
        </>
    )
}
export default Layout;