import axios from "axios";
import { uAPI } from "./config";

const UrlEncodedKey = (url = '', data) => {
    return new Promise(async (resolve, reject) => {
        await axios({
            method: "post",
            url: uAPI + url,
            data: {
                params: data
            }
        })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                return reject(error)
            });
    })
}

const UrlEncodedKey2 = (url = '', data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(uAPI + url, data)
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                return reject(error)
            });
    })
}

const ApiPost = {
    actions: {
        Publikasi(data) {
            return UrlEncodedKey('/landing/publikasi', data)
        },
        saveContact(data) {
            return UrlEncodedKey2('/landing/contact-us', data)
        }
    }
}

export default ApiPost