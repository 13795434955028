import axios from "axios";
import { uAPI } from "./config";

const UrlEncodedKey = (url = '', data) => {
    return new Promise(async (resolve, reject) => {
        await await axios({
            method:"put",
            url:uAPI + url, 
            data:{
                viewers:data
            }
        })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                return reject(error)
            });
    })
}

const ApiPut = {
    actions:{
        Publikasi(id, data){
            return UrlEncodedKey(`/landing/publikasi/${id}`, data)
        }
    }
}

export default ApiPut