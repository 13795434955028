import React, { useEffect, useRef, useState } from "react";
import ApiGet from "api/ApiGet";
import { responseResult } from "utils/ResponseStatus";
import { uAPI } from "api/config";
// import slide1 from 'assets/images/dummy/1.png';
// import slide2 from 'assets/images/dummy/2.png';
// import slide3 from 'assets/images/dummy/3.png';
// import slide4 from 'assets/images/dummy/4.png';


const SlideshowHeader = () => {
    // const dummySlide = [
    //     {
    //         Image: slide1,
    //         ALT: ""
    //     },
    //     {
    //         Image: slide2,
    //         ALT: ""
    //     },
    //     {
    //         Image: slide3,
    //         ALT: ""
    //     },
    //     {
    //         Image: slide4,
    //         ALT: ""
    //     },
    // ]


    const [itemSlide, setItemSlide] = useState([]);

    const delay = 5000;
    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);
    const [paused, setPaused] = useState(false); // eslint-disable-line
    const [prevnextindex, setprevnextindex] = useState({
        prev: 0,
        next: 1
    });

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        ApiGet.actions.Slideshow()
            .then((response) => {
                setItemSlide(responseResult(response))
            })
    }, [])

    useEffect(() => {
        if (index === 0) {
            setprevnextindex({
                prev: itemSlide.length - 1,
                next: index + 1
            })
        } else if (index === itemSlide.length - 1) {
            setprevnextindex({
                prev: index - 1,
                next: 0
            })
        } else {
            setprevnextindex({
                prev: index - 1,
                next: index + 1
            })
        }
    }, [index]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(() => {
            if (!paused) {
                setIndex((prevIndex) =>
                    prevIndex === itemSlide.length - 1 ? 0 : prevIndex + 1
                )
            }
        }, delay);
        return () => {
            resetTimeout();
        };
    }, [index, paused, itemSlide.length]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/* <div className="slider">
                {
                    itemSlide.map((val, _index) => {
                        return (
                            <div key={_index} className={`slide ${prevnextindex.prev === _index ? "prev" : ""} ${prevnextindex.next === _index ? "next" : ""} ${index === _index ? "active" : ""}`}
                            >
                                <img className="" src={uAPI + val.Image} alt={val.ALT} />
                            </div>
                        )
                    })
                }
                <div className="slideshowDots">
                    <div className="left" onClick={() => setIndex((prevIndex) =>
                        prevIndex === 0 ? itemSlide.length - 1 : prevIndex - 1
                    )} />
                    {
                        itemSlide.length > 0 && itemSlide.map(((val, ind) => {
                            return (
                                <div key={ind}
                                    className={`dots ${index === ind ? "active" : ""}`}
                                    onClick={() => {
                                        setIndex(ind);
                                    }}
                                />
                            )
                        }))
                    }
                    <div className="right" onClick={() => setIndex((prevIndex) =>
                        prevIndex === itemSlide.length - 1 ? 0 : prevIndex + 1
                    )} />
                </div>
            </div> */}

            <div className="slideshow-header">
                <div
                    className="slideshowSlider-header"
                    style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                >
                    {itemSlide.map((val, _index) => {
                        return (
                            <div key={_index} className={`slide-header ${prevnextindex.prev === _index ? "prev" : ""} ${prevnextindex.next === _index ? "next" : ""} ${index === _index ? "active" : ""}`}
                            >
                                <img className="" src={uAPI + val.Image} alt={val.ALT} />
                            </div>
                        )
                    })}

                </div>

                <div className="slideshowDots-header">
                    <div className="slideshowDot-header left" onClick={() => setIndex((prevIndex) =>
                        prevIndex === 0 ? itemSlide.length - 1 : prevIndex - 1
                    )} />
                    {
                        itemSlide.length > 0 && itemSlide.map(((val, ind) => {
                            return (
                                <div key={ind}
                                    className={`slideshowDot-header ${index === ind ? "active" : ""}`}
                                    onClick={() => {
                                        setIndex(ind);
                                    }}
                                />
                            )
                        }))
                    }
                    <div className="slideshowDot-header right" onClick={() => setIndex((prevIndex) =>
                        prevIndex === itemSlide.length - 1 ? 0 : prevIndex + 1
                    )} />
                </div>
            </div>
            {/* {itemSlide.map((val, idx) => {
                return (
                    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-center px-[20px] md:px-[48px]"
                    style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.8)' }}
                    >
                        <div className="text-[30px] leading-[30px] md:text-[48px] md:leading-[50px] uppercase font-bold">
                            Integrated Logistics Ecosystem Player
                            
                        </div>  
                        <div className=" text-[15px] leading-[15px] mt-[20px] md:text-[20px] md:leading-[20px] md:mt-[30px]">
                            Solusi Logistik Terdepan yang Mendorong Laju Perdagangan dan Ekonomi di Indonesia
                        </div>
                    </div>
                )

            })} */}

        </>
    )
}
export default SlideshowHeader;