import { SALT } from "api/config";

let CryptoJS = require("crypto-js");

export const _doEncryptObj = (obj) => {
    let dataEncrypt = CryptoJS.AES.encrypt(JSON.stringify(obj), SALT).toString();
    return dataEncrypt;
};

export const _doDecryptObj = (data) => {
    if (data != null) {
        let decrypted = CryptoJS.AES.decrypt(data, SALT);
        decrypted = CryptoJS.enc.Utf8.stringify(decrypted)
        return decrypted
    } else {
        return false;
    }
};
