import { RESETHUBUNGANINVESTOR, SETHUBUNGANINVESTOR } from "./hubunganinvestorTypes";

const initialState = {
    data: []
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SETHUBUNGANINVESTOR:
            return {
                ...state,
                ...payload
            };
        case RESETHUBUNGANINVESTOR:
            return {
                ...state,
                data: []
            };

        default:
            return state;
    }
}

export default reducer;