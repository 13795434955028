import ApiGet from "api/ApiGet";
import { setDataLoading } from "redux/global/globalActions";
import { responseResult } from "utils/ResponseStatus";
import { RESETDETAILSLAYANAN, RESETLAYANAN, SETLAYANAN } from "./layananTypes";

export const setLayanan = (payload) => {
    return {
        type: SETLAYANAN,
        payload,
    };
};
export const resetLayanan = () => {
    return {
        type: RESETLAYANAN,
    };
};
export const resetDetailsLayanan = () => {
    return {
        type: RESETDETAILSLAYANAN,
    };
};

export const getLayanan = (params) => async (dispatch) => {
    dispatch(setDataLoading(true));
    return ApiGet.actions.Layanan(params)
        .then(res => {
            if (responseResult(res)) {
                dispatch(setLayanan({
                    data: responseResult(res)
                }));
            }
            dispatch(setDataLoading(false));
        })
        .catch(err => {
            dispatch(resetLayanan())
            dispatch(setDataLoading(false));
        })
};
export const getDetailLayanan = (params) => async (dispatch) => {
    dispatch(setDataLoading(true));
    return ApiGet.actions.LayananDetails(params)
        .then(res => {
            if (responseResult(res)) {
                dispatch(setLayanan({
                    details: responseResult(res)
                }));
            }
            dispatch(setDataLoading(false));
        })
        .catch(err => {
            dispatch(resetLayanan())
            dispatch(setDataLoading(false));
        })
};
export const getJenisLayanan = (params) => async (dispatch) => {
    dispatch(setDataLoading(true));
    return ApiGet.actions.JenisLayanan(params)
        .then(res => {
            if (responseResult(res)) {
                dispatch(setLayanan({
                    jenisLayanan: responseResult(res)
                }));
            }
            dispatch(setDataLoading(false));
        })
        .catch(err => {
            dispatch(resetLayanan())
            dispatch(setDataLoading(false));
        })
};
