import {
  SETACTIVEMENUSIDEBAR,
  SETDATALOADING,
  SETFOOTER,
  SETFOOTERURLIMG,
  SETHEADERMENU,
  SETHEADERSUBMENU,
  SETMENUSIDEBAR,
  SETROUTE,
} from "./globalTypes";

const initialState = {
  route: [],
  menu: [],
  headerMenu: [],
  headerSubMenu: [],
  dataLoading: false,
  footer: "",
  footerUrlImg: [],
  menuSidebar: null,
  activeMenuSidebar: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SETROUTE:
      return {
        ...state,
        ...payload,
      };
    case SETHEADERMENU:
      return {
        ...state,
        headerMenu: payload,
      };
    case SETHEADERSUBMENU:
      return {
        ...state,
        headerSubMenu: payload,
      };
    case SETDATALOADING:
      return {
        ...state,
        dataLoading: payload,
      };
    case SETFOOTER:
      return {
        ...state,
        footer: payload,
      };
    case SETFOOTERURLIMG:
      return {
        ...state,
        footerUrlImg: payload,
      };
    case SETMENUSIDEBAR:
      return {
        ...state,
        // ...payload
        menuSidebar: payload,
      };
    case SETACTIVEMENUSIDEBAR:
      return {
        ...state,
        activeMenuSidebar: payload,
      };

    default:
      return state;
  }
};

export default reducer;
