import cookie from "react-cookies";
import { _doDecryptObj, _doEncryptObj } from "./Encryption";

// Expires Example
var weeks = new Date();
weeks.setDate(weeks.getDate() + 7);

export const setCookie = (cookieName, objCookies, expired) => {
    const secret = _doEncryptObj(objCookies);
    cookie.save(cookieName, secret, { path: "/", expires: weeks });
    return cookie;
};

export const getCookie = (cookieName, arr = "") => {
    if (cookie.load(cookieName) !== undefined) {
        const bytes = _doDecryptObj(cookie.load(cookieName));
        const decryptedData = JSON.parse(bytes);
        if (arr !== "") {
            return decryptedData[arr];
        } else {
            return decryptedData;
        }
    }
    return null;
};

export const clearCookie = (cookieName) => {
    cookie.remove(cookieName, { path: "/" });
    return cookie;
}

export const clearAllCookie = (cookieName) => {
    cookie.remove();
    return cookie;
}
