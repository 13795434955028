import ApiGet from "api/ApiGet";
import { uAPI } from "api/config";
import ReactSlideshow from "components/reuseable/Slideshow/ReactSlideshow";
import SlideshowCustom from "components/reuseable/Slideshow/SlideshowCustom";
import React, { Fragment, useEffect, useState } from "react";
import Media from "react-media";
import { Link } from "react-router-dom";
import { responseResult } from "utils/ResponseStatus";

const AnakPerusahaanHome = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        ApiGet.actions.AnakPerusahaan()
            .then((response) => {
                if (responseResult(response)) {
                    setData(responseResult(response))
                }
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const customChildren = (data) => {
        // console.log('customChildren', data)
        return data.map((_val, _index) => {
            if (_val) {
                return (
                    <div key={_index} className="flex flex-col mx-3">
                        <img style={{ width: '180px' }} className="flex flex-row justify-center" src={uAPI + _val.company_logo} alt={_val.company_logo_alt} />
                    </div>
                )
            }
            return _val;
        })
    }

    return (
        <>
            {
                data.length > 0 && (
                    // <Media queries={{
                    //     desktop: "(min-width: 1025px)",
                    //     tablet: "(min-width: 768px) and (max-width: 1024px)",
                    //     landscapeMobile: "(min-width: 481px) and (max-width: 767px)",
                    //     portraitMobile: "(min-width: 320px) and (max-width: 480px)",
                    // }}>
                    //     {matches => (
                    //         <Fragment>
                    //             {
                    //                 matches.desktop &&
                    //                 <>
                    //                     <SlideshowCustom data={data} showItem={4} customChildren={customChildren} autoSlide={true} withDots={true} withArrow={true} />
                    //                 </>
                    //             }
                    //             {
                    //                 matches.tablet &&
                    //                 <>
                    //                     <SlideshowCustom data={data} showItem={3} customChildren={customChildren} autoSlide={true} withDots={true} withArrow={true} />
                    //                 </>
                    //             }
                    //             {
                    //                 matches.landscapeMobile &&
                    //                 <>
                    //                     <SlideshowCustom data={data} showItem={2} customChildren={customChildren} autoSlide={true} withDots={true} withArrow={true} />
                    //                 </>
                    //             }
                    //             {
                    //                 matches.portraitMobile &&
                    //                 <>
                    //                     <SlideshowCustom data={data} showItem={1} customChildren={customChildren} autoSlide={true} withDots={true} withArrow={true} />
                    //                 </>
                    //             }
                    //         </Fragment>
                    //     )}
                    // </Media>
                    <div className="border py-[20px] rounded-lg">
                        <ReactSlideshow indicators={false} arrows={false} slidesToShow={6} slidesToScroll={1} responsive={[]}
                        >
                            {
                                data.map((_val, _index) => {
                                    // console.log(_val, "valuee")
                                    return (

                                        <div key={_index} className="flex flex-col mx-3 cursor-pointer" onClick={() => {
                                            if (_val?.company_website) {
                                                window.open(_val?.company_website ? _val?.company_website : "#", "_blank")
                                            }
                                        }} >
                                            <img style={{ width: '180px' }} className="flex flex-row justify-center" src={uAPI + _val.company_logo} alt={_val.company_logo_alt} />
                                        </div>

                                    )
                                })
                            }
                        </ReactSlideshow>
                    </div>
                )
            }
        </>
    )
}
export default AnakPerusahaanHome;