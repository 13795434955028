import BasicDropdown from "components/reuseable/Dropdown/BasicDropdown";
import SelectDropdown from "components/reuseable/Dropdown/SelectDropdown"
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  setActiveMenuSidebar,
  setMenuSidebar,
} from "redux/global/globalActions";
import { setAnakPerusahaan } from "redux/profil/profilActions";
import useRedux from "redux/useRedux";
// import icSelected from "./assets/images/icons/icon-selected.svg";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import profileDoc from "assets/images/icons/profile.jpg";
import ppidInfo from "assets/images/icons/ppid-info.svg";
import ppidCheck from "assets/images/icons/ppid-check.svg";
import bgFooter from "assets/images/bg-footer-ppid.svg"
import ChatBox from "../../pages/PopUp/ChatBox";


const Profile = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;
  // console.log('pathname', pathname)
  const {
    dispatch,
    global: { menu, menuSidebar, activeMenuSidebar },
    profil: { anakPerusahaan },
  } = useRedux();

  let navigate = useNavigate();

  const [open, setOpen] = useState(false);
  console.log('menu', menu);
  console.log('menuSidebar', menuSidebar);
  console.log('activeMenuSidebar', activeMenuSidebar);

  let pathFind = "";
  const findSubMenu = menu.find((val, ind) => {
    let child = val?.child;
    if (child?.length == 0) {
      pathFind = child?.find(
        (val) => val?.path.split("/")[1] === pathname.split("/")[1]
      );
      return child?.find(
        (val) => val?.path.split("/")[1] === pathname.split("/")[1]
      );
    }
    return null;
  });
  useEffect(() => {
    if (findSubMenu) {
      dispatch(setMenuSidebar(findSubMenu));
    }
    if (pathFind) {
      dispatch(setActiveMenuSidebar(pathFind));
    }
    return () => {
      dispatch(setMenuSidebar(null));
      dispatch(setActiveMenuSidebar(null));
    };
  }, [findSubMenu, pathFind]); // eslint-disable-line react-hooks/exhaustive-deps
  console.log('pathnames', pathFind?.path)
  console.log('findSubMenu', findSubMenu)
  console.log('pathFind', pathFind)

  const anakPerusahaanClick = (data) => {
    // console.log('anakPerusahaanClick', data)
    dispatch(
      setAnakPerusahaan({
        selected: data,
      })
    );
  };
  let dropdown = [
    { Title: "Profil", child: [{ title: "nama", path: "https:" }] },
    { Title: "GCG", child: [{ title: "nama", path: "https:" }] },
  ];

 
  const onClickMenu = (val) => {
    console.log('onClickMenu', val)
    val?.path && navigate(val?.path);
  };

  const bgTitle = (title) => {
    switch (title) {
      case "Profil":
        return "bg-profil";
      case "GCG":
        return "bg-gcg";
      case "Layanan":
        return "bg-layanan";
      case "Fasilitas":
        return "bg-fasilitas";
      case "Hubungan Investor":
        return "bg-hubungan";
      case "Media & Publikasi":
        return "bg-media";
      case "TJSL":
        return "bg-tjsl";
      default:
        return "bg-ppid";
    }
  };
  let tataKelola = [];
  let other = [];

  const menuSidebarss = ["E-PPID","Profile","Regulasi","Maklumat","Informasi Publik","Layanan Publik","Agenda","Registrasi Permohonan"];
  const menuSidebars = ["E-PPID"];
  let test2 = menuSidebar?.child.sort((a, b) => {
    return a.id - b.id;
  });

  console.log("length", menuSidebars?.child);

  return (
    <>
    <div className="mt-[110px]">
      <div
        className={`${bgTitle(
          "Profil E-PPID"
        )} flex flex-col justify-center items-center`}
      >
        {/* <div className={`${bgTitle(findSubMenu?.title)} flex flex-col justify-center items-center`}> */}
        <div className="text-white text-[40px] font-semibold  text-center">
          {"Profil E-PPID"}
          {/* {findSubMenu?.title} */}
        </div>
      </div>
      <div className="section pt-[50px]">
        {/* <div className="flex flex-row flex-wrap md:flex-nowrap md:divide-x-2"> */}
        <div className="md:grid md:grid-cols-6 md:divide-x-2 ">
          <div className="hidden md:flex md:flex-col md:mr-2">
            {/* {findSubMenu?.title && <div className="text-active text-[15px] uppercase font-bold mb-4">{findSubMenu?.title}</div>} */}
            { {menuSidebars} && (
              <div className="text-inactive text-[17px] mb-4">
                <Disclosure>
                     {({ open }) => (
                      <Disclosure.Button
                      className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    
                      <Link href="" to={"/ppid"}>
                      <div className="my-1 text-[17px] ">E-PPID</div>
                      </Link>
                    </Disclosure.Button>
                    )}
          
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button 
                      className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  
                      <Link href="" to={"/profile"}>
                        <div className="my-1 text-[17px] ">Profil</div>
                      </Link>
                      {/* <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-primary-500`}
                      /> */}
                    </Disclosure.Button>
                    {/* {menuSidebars?.map((val, ind) => {
                      return (
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                          <Link
                            key={ind}
                            className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${
                              activeMenuSidebar?.path === val?.path
                                ? "text-active font-semibold bg-[#F0F2F2] rounded-lg"
                                : ""
                            }`}
                            to={`${val?.isEksternal ? "#" : val?.path}`}
                            onClick={(e) => {
                              // if (val?.isEksternal) {
                                window.open("https://ppid.pelindo.co.id", "_blank");
                             // }
                            }}
                          >
                            {val?.title}
                          </Link>
                        </Disclosure.Panel>
                      );
                    })} */}
                  </>
                )}
              </Disclosure>
              <Disclosure>
                     {({ open }) => (
                      <Disclosure.Button 
                      className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                     
                      <Link href="" to={"/regulasi"}>
                        <div className="my-1 text-[17px] ">Regulasi</div>
                      </Link>
                    </Disclosure.Button>
                    )}
              </Disclosure>
              <Disclosure>
                     {({ open }) => (
                      <Disclosure.Button 
                      className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                   
                      <Link href="" to={"/maklumat"}>
                         <div className="my-1 text-[17px] ">Maklumat</div>
                      </Link>
                    </Disclosure.Button>
                    )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      <div className="my-1 text-[17px] ">Informasi Publik</div>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-primary-500`}
                      />
                    </Disclosure.Button>
                    {menuSidebars?.map((val, ind) => {
                      return (
                        <>
                        
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                          <Disclosure>
                                 {({ open }) => (
                                  <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                  <Link href="" to={"/informasi-berkala"}>
                                      <div className="my-1 text-[17px] ">Informasi Berkala</div>
                                  </Link>
                                  {/* <ChevronUpIcon
                                    className={`${
                                      open ? "rotate-180 transform" : ""
                                    } h-5 w-5 text-primary-500`}
                                  /> */}
                                </Disclosure.Button>
                                )}
                          </Disclosure>
                          <Disclosure>
                                 {({ open }) => (
                                  <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                  <Link href="" to={"/informasi-setiap-saat"}>
                                    <div className="my-1 text-[17px] ">Informasi Setiap Saat</div>
                                  </Link>
                                  {/* <ChevronUpIcon
                                    className={`${
                                      open ? "rotate-180 transform" : ""
                                    } h-5 w-5 text-primary-500`}
                                  /> */}
                                </Disclosure.Button>
                                )}
                          </Disclosure>
                          <Disclosure>
                                 {({ open }) => (
                                  <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                  <Link href="" to={"/informasi-serta-merta"}>
                                    <div className="my-1 text-[17px] ">Informasi Serta Merta</div>
                                  </Link>
                                  {/* <ChevronUpIcon
                                    className={`${
                                      open ? "rotate-180 transform" : ""
                                    } h-5 w-5 text-primary-500`}
                                  /> */}
                                </Disclosure.Button>
                                )}
                          </Disclosure>
                        </Disclosure.Panel>
                        </>
                      );
                    })}
                  </>
                )}
              </Disclosure>
              <Disclosure>
                     {({ open }) => (
                      <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                     
                      <Link href="" to={"/layanan-publik"}>
                          <div className="my-1 text-[17px] ">Layanan Publik</div>
                      </Link>
                    </Disclosure.Button>
                    )}
              </Disclosure>
              <Disclosure>
                     {({ open }) => (
                      <Disclosure.Button
                      className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  
                      <Link href="" to={"/kebijakan-privasi"}>
                          <div className="my-1 text-[17px] ">Kebijakan Privasi</div>
                      </Link>
                    </Disclosure.Button>
                    )}
              </Disclosure>
              <Disclosure>
                     {({ open }) => (
                      <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      <Link href="" to={"/registrasi-permohonan"}>
                          <div className="my-1 text-[17px] ">Registrasi Permohonan</div>
                      </Link>              
                          
                        
                    </Disclosure.Button>
                    )}
              </Disclosure>                 
                
              </div>
            )}
            {/* {findSubMenu?.title?.length > 0 && (
                              findSubMenu?.child?.map((val, ind) => {
                                  return (
                                      <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${pathname === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                                          {val?.title}
                                      </Link>
                                  )
                              })
                          )} */}
            
            {menuSidebars?.child?.map((val, ind) => {               
                if(val?.title == undefined ||val?.title == undefined){
                return (
                  <>
               
                </>
                );
              }
              })}
            {other?.length == undefined &&
              other.map((val, ind) => {
                
                if(val?.title == undefined ||val?.title == undefined){
             
              }})}
            {
              // <BasicDropdown
              //     className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg 'text-active font-semibold bg-[#F0F2F2] rounded-lg'`}
              //     title={'Tata Kelola'}
              //     items={tataKelola}
              //     onClickItems={onClickMenu}
              // />

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      {/* <div className="my-1 text-[15px] ">Detail</div>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-primary-500`}
                      /> */}
                    </Disclosure.Button>
                    {menuSidebars?.map((val, ind) => {
                      return (
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <Link
                            className="flex flex-col items-center justify-center text-active"
                            to={"#"}
                            onClick={(e) => {
                              window.open("https://ppid.pelindo.co.id/informasi", "_blank");
                            }}
                          >
                           
                            <div className="text-[15px] mt-50 text-center">Informasi berkala</div>
                          </Link>
                        </Disclosure.Panel>
                      );
                    })}
                  </>
                )}
              </Disclosure>
            
            }

            {other?.length > 0 &&
              other.map((val, ind) => {
                if(val?.title!=="Manual GCG"&&val?.title!=="Whistleblowing System"){
                return (
                  // <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${pathname === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                  //     {val?.title}
                  // </Link>
                  // <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${menuSidebar?.active?.path === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                  //     {val?.title}
                  // </Link>
                  <>
                    <Link
                      key={ind}
                      className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${
                        activeMenuSidebar?.path === val?.path
                          ? "text-active font-semibold bg-[#F0F2F2] rounded-lg"
                          : ""
                      }`}
                      to={`${val?.isEksternal ? "#" : val?.path}`}
                      onClick={(e) => {
                        if (val?.isEksternal) {
                          window.open(val?.path, "_blank");
                        }
                      }}
                    >
                      {val?.title}
                    </Link>
                  </>
                );
              }})}

    
          {menuSidebars?.child?.map((val, ind) => {  
          return (
  <>

  </>
);
})}
          </div>
          {/* <div>
                          {
                              dropdown?dropdown.map((data)=>

                              ):null
                          }
                      </div> */}
          <div className="md:col-span-3 md:flex md:flex-col pl-0 md:pl-[20px]">
            {/* <div className="section flex flex-col ml-10 px-[20px] pt-[30px] md:px-[2px]"> */}
            {pathFind?.path === "/wilayah-kerja-anak-perusahaan" &&
              anakPerusahaan?.data?.length > 0 && (
                <div className="py-6 border-t-2 md:hidden">
                  <SelectDropdown
                      items={anakPerusahaan?.data}
                      onClickItems={anakPerusahaanClick}
                      selectedTitle={anakPerusahaan?.selected?.company_name}
                      selectedValue={anakPerusahaan?.selected}
                  />
                </div>
              )}
            <div className="section-detail flex flex-col">
              <Outlet context={pathFind} />
            </div>
          </div>
        </div>
        <div className="flex my-10 mx-2 md:mx-20 text-justify md:pl-[100px]">
            {pathFind?.path == undefined &&
              menuSidebars?.length > 0 && (
                <div className="">
                  {menuSidebars?.map((val, ind) => {
                       return (
                        <>
                        <div className="mt-[0px]">
                          
                          <div className="section" style={{ marginTop: '-1025px', width:'118%'}}>
                            <div className="flex flex-col my-10 mx-2 md:mx-20 text-justify">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
                            <img src={profileDoc} alt="profile-doc" style={{ maxWidth: '500px', height: '400px' }} />
                            </div>
                             <br></br>
                              <div className="text-[15px]">
                              <div className="mt-2">
                              Dengan komitmen yang kuat terhadap transparansi dan integritas, PT Pelindo Solusi Logistik (SPSL) sebagai Subholding BUMN Kepelabuhanan Pelindo yang bergerak pada klaster bisnis Logistik & Hinterland Development, berkomitmen untuk menjalankan Keterbukaan Informasi Publik sesuai dengan amanat Undang-Undang Nomor 14 Tahun 2008 Tentang Keterbukaan Informasi Publik. 
                              </div>
                              <div className="mt-2">
                              Pedoman Pelaksanaan Keterbukaan Informasi Publik Nomor: HK.01/17/10/1/KKPJ/DIUT/PLSL-23 diterbitkan oleh PT Pelindo Solusi Logistik sebagai bentuk komitmen untuk memastikan bahwa pengelolaan dan pelaksanaan Layanan Informasi Publik di bidang Logistik & Hinterland Development dilakukan dengan lebih terstruktur dan terarah.
                              </div>
                              <div className="mt-2">
                              Melalui Peraturan Direksi PT Pelindo Solusi Logistik Nomor: HK.01/17/10/1/KKPJ/DIUT/PLSL-23 tanggal 17 Oktober 2023 tentang Pedoman Pelaksanaan Keterbukaan Informasi Publik di Lingkungan PT Pelindo Solusi Logistik, telah ditetapkan:
                              </div>
                              <br></br>
                              1. Senior Vice President/Pejabat setingkat yang membidangi Kesekretariatan Perusahaan sebagai Atasan Pejabat Pengelola Informasi, dan Dokumentasi (PPID) di PT Pelindo Solusi Logistik.
                              <br></br>
                              2. Vice President/Pejabat setingkat yang membidangi Kehumasan sebagai Pejabat Pengelola Informasi, dan Dokumentasi (PPID) di PT Pelindo Solusi Logistik.
                              <br></br>
                              <div className="mt-2">
                              Atasan PPID PT Pelindo Solusi Logistik bertugas untuk menetapkan kebijakan Layanan Informasi Publik PT Pelindo Solusi Logistik. Sementara itu, PPID PT Pelindo Solusi Logistik memperoleh tugas untuk mengelola Layanan Informasi Publik PT Pelindo Solusi Logistik.
                              </div>
                              PT Pelindo Solusi Logistik berkomitmen membangun sinergi antara seluruh Fungsi dan Divisi untuk memastikan Keterbukaan Informasi Publik dan menyediakan layanan informasi yang optimal serta mudah diakses oleh publik.

                              <div className="text-[15px] mt-2 text-center font-bold">VISI DAN MISI PPID</div>
                              <div className="text-[14px] mt-2 font-bold">Visi : </div>
                              Menjadi PPID terdepan dalam melaksanakan pelayanan informasi publik di bidang logistik dan Hinterland  Development dengan mengedepankan ketepatan, integritas, dan kualitas informasi sesuai ketentuan peraturan perundang-undangan yang berlaku, guna mendukung transparansi dan efisiensi operasional perusahaan..
                              <div className="text-[14px] mt-2 font-bold">Misi : </div>
                              <div className="mt-2">
                              Menyediakan, memberikan, dan/atau menerbitkan informasi publik dengan tepat dan akurat sesuai ketentuan peraturan yang berlaku.
                              </div>
                              <div className="mt-2">
                              Menyediakan sumber daya manusia yang berintegritas, santun, serta bertanggungjawab sesuai dengan AKHLAK dalam pelayanan informasi.
                              </div>
                              <div className="mt-2">
                              Menyediakan fasilitas pelayanan Informasi publik yang representatif.
                              </div>
                              <div className="mt-2">
                              Dukungan masyarakat sangat kami perlukan dalam pelaksanaan Keterbukaan Informasi Publik di lingkungan PT Pelindo Solusi Logistik. Segala bentuk yang berkaitan dengan peningkatan layanan Keterbukaan Informasi Publik dapat disampaikan kepada PPID PT Pelindo Solusi Logistik melalui saluran berikut:
                              </div><br></br>
                              <div className="text-[14px] mt-2 font-bold">PPID PT Pelindo Solusi Logistik </div> 
                              <div className="mt-2">Pelindo Tower, Jl. Yos Sudarso, 9, Rawa Badak Utara, Koja, Kota Adm. Jakarta Utara, DKI Jakarta, 14230</div>
                              <br></br>
                              <div className="text-[14px] mt-2">Phone : +62 21-5091-8800</div>
                              <div className="text-[14px] mt-2">Email : infosolusilogistik@pelindo.co.id</div>
                              </div>
                         
                            </div>
                          </div>
                        </div>
                      </>
                      );
                    })}
                  </div>
                )}
            </div>
        </div>
      </div>
      <img src={bgFooter} alt="icon" className="relative object-fill w-full" />
      <ChatBox/>
    </>
  );
  
};
export default Profile;
