import { RESETDETAILSGCG, RESETMENULISTGCG, SETDETAILSGCG, SETMENULISTGCG } from "./gcgTypes";

const initialState = {
    menuList: [],
    details: null,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SETMENULISTGCG:
            return {
                ...state,
                menuList: payload
            };
        case RESETMENULISTGCG:
            return {
                ...state,
                menuList: [],
            };
        case SETDETAILSGCG:
            return {
                ...state,
                details: payload
            };
        case RESETDETAILSGCG:
            return {
                ...state,
                details: [],
            };


        default:
            return state;
    }
}

export default reducer;