import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Listbox, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import useRedux from 'redux/useRedux'

const SelectDropdown = ({
    items = [],
    onClickItems,
    selectedTitle,
    selectedValue
}) => {
    const {
        global: {
            activeMenuSidebar
        }
    } = useRedux();

    console.log("items: ", items)
    console.log("selectedValue: ", selectedValue)
    console.log("selectedTitle: ", selectedTitle)

    return (
      <div className="w-90">
        <Listbox value={selectedValue} onChange={onClickItems}>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className="block truncate text-[#2FB6E9]">{selectedTitle}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10 p-inline-0">
                {items.map((item, idx) => (
                  <Listbox.Option
                    key={idx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 px-4 li-select ${
                        active ? 'text-[#2FB6E9]' : 'text-gray-900'
                      }`
                    }
                    value={item}
                  >
                    {({ selectedValue }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selectedValue ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {item.company_name}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    )
}

export default SelectDropdown