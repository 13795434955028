import BasicDropdown from "components/reuseable/Dropdown/BasicDropdown";
import SelectDropdown from "components/reuseable/Dropdown/SelectDropdown"
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  setActiveMenuSidebar,
  setMenuSidebar,
} from "redux/global/globalActions";
import { setAnakPerusahaan } from "redux/profil/profilActions";
import useRedux from "redux/useRedux";
// import icSelected from "./assets/images/icons/icon-selected.svg";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import ppidDoc from "assets/images/icons/icFiles.svg";
import ppidDoc1 from "assets/pdf/Undang_undang_Nomor_14_Tahun_2008_tentang_Keterbukaan_Informasi_Publik.pdf";
import ppidDoc2 from "assets/pdf/Peraturan_Pemerintah_Nomor_61_Tahun_2010_tentang_Pelaksanaan_Keterbukaan_Informasi_Publik.pdf";
import ppidDoc3 from "assets/pdf/Peraturan_Komisi_Informasi_Nomor_1_Tahun_2010_tentang_Standar_Layanan_Informasi_Publik.pdf";
import ppidDoc4 from "assets/pdf/Undang_undang_Nomor_40_Tahun_1999_tentang_Pers.pdf";
import ppidDoc5 from "assets/pdf/Peraturan_Dewan_Pers_Nomor_6_Peraturan_DP_V_2008.pdf";
import ppidDoc6 from "assets/pdf/Peraturan_Komisi_Informasi_Nomor_1_tahun_2017_tentang_Pengklasifikasian_Informasi_Publik.pdf";
import ppidDoc7 from "assets/pdf/Peraturan_Komisi_Informasi_Nomor_1_Tahun_2021_Tentang_Standar_Layanan_Informasi_Publik.pdf";
import ppidDoc8 from "assets/pdf/Peraturan_Komisi_Informasi_Nomor_2_Tahun_2016_tentang_Tata_Cara_Pemeriksaan_Setempat.pdf";
import ppidDoc9 from "assets/pdf/Peraturan_Komisi_Informasi_Nomor_1_Tahun_2013_tentang_Penyelesaian_Sengketa_Informasi_Publik.pdf";
import ppidDoc10 from "assets/pdf/Peraturan_Direksi_PT_Pelabuhan_Indonesia_(Persero)_nomor_HK.013101KMKPUTMAPLND-22.pdf";
import ppidDoc11 from "assets/pdf/Peraturan_Direksi_PT_Pelindo_Solusi_Logistik_nomor_HK.0117101KKPJDIRUTPLSL-23.pdf";
import ppidDoc12 from "assets/pdf/Prosedur_Uji_Konsekuansi.pdf";
import ppidDoc13 from "assets/pdf/Prosedur_Permohonan_Informasi.pdf";
import ppidDoc14 from "assets/pdf/Prosedur_Penetapan_dan_Pemutakhiran_Daftar_Informasi_Publik.pdf";
import ppidDoc15 from "assets/pdf/Prosedur_Pendokumentasian_Informasi_Publik.pdf";

import ppidCheck from "assets/images/icons/ppid-check.svg";
import icons from "assets/images/icons/icons.svg";
import bgFooter from "assets/images/bg-footer-ppid.svg"
import ChatBox from "../../pages/PopUp/ChatBox";

const Regulasi = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;
  const gradientStyle = {
    background: 'linear-gradient(to right, #1C3865, #1C80B9, #36B4E5)'
  };
  // console.log('pathname', pathname)
  const {
    dispatch,
    global: { menu, menuSidebar, activeMenuSidebar },
    profil: { anakPerusahaan },
  } = useRedux();

  let navigate = useNavigate();

  const [open, setOpen] = useState(false);
  console.log('menu', menu);
  console.log('menuSidebar', menuSidebar);
  console.log('activeMenuSidebar', activeMenuSidebar);

  let pathFind = "";
  const findSubMenu = menu.find((val, ind) => {
    let child = val?.child;
    if (child?.length == 0) {
      pathFind = child?.find(
        (val) => val?.path.split("/")[1] === pathname.split("/")[1]
      );
      return child?.find(
        (val) => val?.path.split("/")[1] === pathname.split("/")[1]
      );
    }
    return null;
  });
  useEffect(() => {
    if (findSubMenu) {
      dispatch(setMenuSidebar(findSubMenu));
    }
    if (pathFind) {
      dispatch(setActiveMenuSidebar(pathFind));
    }
    return () => {
      dispatch(setMenuSidebar(null));
      dispatch(setActiveMenuSidebar(null));
    };
  }, [findSubMenu, pathFind]); // eslint-disable-line react-hooks/exhaustive-deps
  console.log('pathnames', pathFind?.path)
  console.log('findSubMenu', findSubMenu)
  console.log('pathFind', pathFind)

  const anakPerusahaanClick = (data) => {
    // console.log('anakPerusahaanClick', data)
    dispatch(
      setAnakPerusahaan({
        selected: data,
      })
    );
  };
  let dropdown = [
    { Title: "Profil", child: [{ title: "nama", path: "https:" }] },
    { Title: "GCG", child: [{ title: "nama", path: "https:" }] },
  ];

 
  const onClickMenu = (val) => {
    console.log('onClickMenu', val)
    val?.path && navigate(val?.path);
  };

  const bgTitle = (title) => {
    switch (title) {
      case "Profil":
        return "bg-profil";
      case "GCG":
        return "bg-gcg";
      case "Layanan":
        return "bg-layanan";
      case "Fasilitas":
        return "bg-fasilitas";
      case "Hubungan Investor":
        return "bg-hubungan";
      case "Media & Publikasi":
        return "bg-media";
      case "TJSL":
        return "bg-tjsl";
      default:
        return "bg-ppid";
    }
  };
  let tataKelola = [];
  let other = [];

  const menuSidebarss = ["E-PPID","Profile","Regulasi","Maklumat","Informasi Publik","Layanan Publik","Agenda","Registrasi Permohonan"];
  const menuSidebars = ["E-PPID"];
  let test2 = menuSidebar?.child.sort((a, b) => {
    return a.id - b.id;
  });

  const [isOpen, setIsOpen] = useState({
    dropdown1: false,
    dropdown2: false,
    dropdown3: false,
    dropdown4: false,
    dropdown5: false,
    dropdown6: false,
    dropdown7: false,
  }) 
  const toggleDropdown = (dropdownName) => { 
    // setIsOpen(!isOpen)
    setIsOpen(prevState =>({
      ...prevState, 
      [dropdownName]: !prevState[dropdownName]
    }))
  }
  console.log("length", menuSidebars?.child);

  return (
    <>
      <div className="mt-[110px]">
        <div
          className={`${bgTitle(
            "Regulasi"
          )} flex flex-col justify-center items-center`}
        >
          {/* <div className={`${bgTitle(findSubMenu?.title)} flex flex-col justify-center items-center`}> */}
          <div className="text-white text-[40px] font-semibold text-center">
            {"Regulasi"}
            {/* {findSubMenu?.title} */}
          </div>
        </div>
        <div className="section pt-[50px]">
          {/* <div className="flex flex-row flex-wrap md:flex-nowrap md:divide-x-2"> */}
          <div className="md:grid md:grid-cols-6 md:divide-x-2 ">
            <div className="hidden md:flex md:flex-col md:mr-2">
              {/* {findSubMenu?.title && <div className="text-active text-[15px] uppercase font-bold mb-4">{findSubMenu?.title}</div>} */}
              { {menuSidebars} && (
                <div className="text-inactive text-[17px] mb-4">
                                    <Disclosure>
                       {({ open }) => (
                        <Disclosure.Button
                        className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      
                        <Link href="" to={"/ppid"}>
                        <div className="my-1 text-[17px] ">E-PPID</div>
                        </Link>
                      </Disclosure.Button>
                      )}
            
                </Disclosure>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button 
                        className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    
                        <Link href="" to={"/profile"}>
                          <div className="my-1 text-[17px] ">Profil</div>
                        </Link>
                        {/* <ChevronUpIcon
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-primary-500`}
                        /> */}
                      </Disclosure.Button>
                      {/* {menuSidebars?.map((val, ind) => {
                        return (
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <Link
                              key={ind}
                              className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${
                                activeMenuSidebar?.path === val?.path
                                  ? "text-active font-semibold bg-[#F0F2F2] rounded-lg"
                                  : ""
                              }`}
                              to={`${val?.isEksternal ? "#" : val?.path}`}
                              onClick={(e) => {
                                // if (val?.isEksternal) {
                                  window.open("https://ppid.pelindo.co.id", "_blank");
                               // }
                              }}
                            >
                              {val?.title}
                            </Link>
                          </Disclosure.Panel>
                        );
                      })} */}
                    </>
                  )}
                </Disclosure>
                <Disclosure>
                       {({ open }) => (
                        <Disclosure.Button 
                        className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                       
                        <Link href="" to={"/regulasi"}>
                          <div className="my-1 text-[17px] ">Regulasi</div>
                        </Link>
                      </Disclosure.Button>
                      )}
                </Disclosure>
                <Disclosure>
                       {({ open }) => (
                        <Disclosure.Button 
                        className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                     
                        <Link href="" to={"/maklumat"}>
                           <div className="my-1 text-[17px] ">Maklumat</div>
                        </Link>
                      </Disclosure.Button>
                      )}
                </Disclosure>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                        <div className="my-1 text-[17px] ">Informasi Publik</div>
                        <ChevronUpIcon
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-primary-500`}
                        />
                      </Disclosure.Button>
                      {menuSidebars?.map((val, ind) => {
                        return (
                          <>
                          
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <Disclosure>
                                  {({ open }) => (
                                    <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                    <Link href="" to={"/informasi-berkala"}>
                                        <div className="my-1 text-[17px] ">Informasi Berkala</div>
                                    </Link>
                                    {/* <ChevronUpIcon
                                      className={`${
                                        open ? "rotate-180 transform" : ""
                                      } h-5 w-5 text-primary-500`}
                                    /> */}
                                  </Disclosure.Button>
                                  )}
                            </Disclosure>
                            <Disclosure>
                                  {({ open }) => (
                                    <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                    <Link href="" to={"/informasi-setiap-saat"}>
                                      <div className="my-1 text-[17px] ">Informasi Setiap Saat</div>
                                    </Link>
                                    {/* <ChevronUpIcon
                                      className={`${
                                        open ? "rotate-180 transform" : ""
                                      } h-5 w-5 text-primary-500`}
                                    /> */}
                                  </Disclosure.Button>
                                  )}
                            </Disclosure>
                            <Disclosure>
                                  {({ open }) => (
                                    <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                    <Link href="" to={"/informasi-serta-merta"}>
                                      <div className="my-1 text-[17px] ">Informasi Serta Merta</div>
                                    </Link>
                                    {/* <ChevronUpIcon
                                      className={`${
                                        open ? "rotate-180 transform" : ""
                                      } h-5 w-5 text-primary-500`}
                                    /> */}
                                  </Disclosure.Button>
                                  )}
                            </Disclosure>
                          </Disclosure.Panel>
                          </>
                        );
                      })}
                    </>
                  )}
                </Disclosure>
                <Disclosure>
                       {({ open }) => (
                        <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                       
                        <Link href="" to={"/layanan-publik"}>
                            <div className="my-1 text-[17px] ">Layanan Publik</div>
                        </Link>
                      </Disclosure.Button>
                      )}
                </Disclosure>
                <Disclosure>
                       {({ open }) => (
                        <Disclosure.Button
                        className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    
                        <Link href="" to={"/kebijakan-privasi"}>
                            <div className="my-1 text-[17px] ">Kebijakan Privasi</div>
                        </Link>
                      </Disclosure.Button>
                      )}
                </Disclosure>
                <Disclosure>
                       {({ open }) => (
                        <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                       <Link href="" to={"/registrasi-permohonan"}>
                            <div className="my-1 text-[17px] ">Registrasi Permohonan</div>
                        </Link>              
                            
                          
                      </Disclosure.Button>
                      )}
                </Disclosure>    
                  
                </div>
              )}
              
              {menuSidebars?.child?.map((val, ind) => {               
                  if(val?.title == undefined ||val?.title == undefined){
                  return (
                    <>
                 
                  </>
                  );
                }
                })}
              {other?.length == undefined &&
                other.map((val, ind) => {
                  
                  if(val?.title == undefined ||val?.title == undefined){
               
                }})}
              {
                // <BasicDropdown
                //     className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg 'text-active font-semibold bg-[#F0F2F2] rounded-lg'`}
                //     title={'Tata Kelola'}
                //     items={tataKelola}
                //     onClickItems={onClickMenu}
                // />

              
              }

              {other?.length > 0 &&
                other.map((val, ind) => {
                  if(val?.title!=="Manual GCG"&&val?.title!=="Whistleblowing System"){
                  return (
                    // <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${pathname === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                    //     {val?.title}
                    // </Link>
                    // <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${menuSidebar?.active?.path === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                    //     {val?.title}
                    // </Link>
                    <>
                      <Link
                        key={ind}
                        className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${
                          activeMenuSidebar?.path === val?.path
                            ? "text-active font-semibold bg-[#F0F2F2] rounded-lg"
                            : ""
                        }`}
                        to={`${val?.isEksternal ? "#" : val?.path}`}
                        onClick={(e) => {
                          if (val?.isEksternal) {
                            window.open(val?.path, "_blank");
                          }
                        }}
                      >
                        {val?.title}
                      </Link>
                    </>
                  );
                }})}
            </div>
            <div className="md:col-span-3 md:flex md:flex-col pl-0 md:pl-[20px]">
              {/* <div className="section flex flex-col ml-10 px-[20px] pt-[30px] md:px-[2px]"> */}
              {pathFind?.path === "/wilayah-kerja-anak-perusahaan" &&
                anakPerusahaan?.data?.length > 0 && (
                  <div className="py-6 border-t-2 md:hidden">
                    <SelectDropdown
                        items={anakPerusahaan?.data}
                        onClickItems={anakPerusahaanClick}
                        selectedTitle={anakPerusahaan?.selected?.company_name}
                        selectedValue={anakPerusahaan?.selected}
                    />
                  </div>
                )}
              <div className="section-detail flex flex-col">
                <Outlet context={pathFind} />
              </div>
            </div>
          </div>
          <div className="flex my-10 mx-2 md:mx-20 text-justify md:pl-[100px]">
              {pathFind?.path == undefined &&
                menuSidebars?.length > 0 && (
                  <div className="">
                    {menuSidebars?.map((val, ind) => {
                       return (
                        <>
                        <div className="mt-[0px]">
                          
                          <div className="section block" style={{ marginTop: '-1025px', width:'123%'}}>
                            
                            <div className="flex flex-col my-10 mx-2 text-justify" style={{ borderTopLeftRadius: '1rem',borderTopRightRadius: '1rem'}}>
                            <div class="mb-2 lg:border lg:rounded-lg rounded-sm"style={{width:'100%'}}>
                                <div  class={`text-white  align-middle font-medium lg:rounded-t-lg lg:rounded-lg text-lg px-2 lg:py-4 py-2 flex flex-row-reverse cursor-pointer hover:opacity-90 border-b  css-ck7407 min-w-[1075px]`} onClick={() => toggleDropdown('dropdown1')} style={gradientStyle}>
                                <ChevronUpIcon
                                      className={`${
                                        isOpen.dropdown1 ? "rotate-180 transform" : ""
                                      } h-5 w-5 text-primary-500`}
                                    />
                                    <div class="flex-auto mt-1 text-lg">Peraturan Mengenai Keterbukaan Informasi Publik</div>
                                    <div class="px-2 ">
                                        <img src={icons} className=" flex justify-left" alt="ppic-info" />
                                    </div>
                                    </div>
                                    
                                    <div class={`lg:px-4 px-2 py-2 text-justify text-left text-gray-800 lg:mb-5 mb-3 bg-white block  ${isOpen.dropdown1? ' ': 'hidden'}`}  style={{width:'100%'}}>
                                      
                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Undang-undang Nomor 14 Tahun 2008 tentang Keterbukaan Informasi Publik
                                          </div>
                                          <div class="flex justify-center ">
                                            <a href={ppidDoc1} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                          </div>
                                        </div>
                                      </span>

                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Peraturan Pemerintah Nomor 61 Tahun 2010 tentang Pelaksanaan Keterbukaan Informasi Publik
                                          </div>
                                          <div class="flex justify-center lg:mt-0 mt-2">
                                          <a href={ppidDoc2} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                          </div>
                                        </div>
                                      </span>

                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Peraturan Komisi Informasi Nomor 1 Tahun 2010 tentang Standar Layanan Informasi Publik
                                          </div>
                                          <div class="flex justify-center lg:mt-0 mt-2">
                                          <a href={ppidDoc3} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                          </div>
                                        </div>
                                      </span>

                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Undang-undang Nomor 40 Tahun 1999 tentang Pers
                                          </div>
                                          <div class="flex justify-center lg:mt-0 mt-2">
                                            <a href={ppidDoc4} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                          </div>
                                        </div>
                                      </span>
                                      
                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Peraturan Dewan Pers Nomor 6 Peraturan-DP V 2008
                                          </div>
                                          <div class="flex justify-center lg:mt-0 mt-2">
                                            <a href={ppidDoc5} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                          </div>
                                        </div>
                                      </span>
                                      
                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Peraturan Komisi Informasi Nomor 1 Tahun 2017 tentang Pengklasifikasian Informasi Publik
                                          </div>
                                          <div class="flex justify-center lg:mt-0 mt-2">
                                            <a href={ppidDoc6} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                          </div>
                                        </div>
                                      </span>
                                      
                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Peraturan Komisi Informasi Nomor 1 Tahun 2021 Tentang Standar Layanan Informasi Publik
                                          </div>
                                          <div class="flex justify-center lg:mt-0 mt-2">
                                            <a href={ppidDoc7} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                          </div>
                                        </div>
                                      </span>
                                    
                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Peraturan Komisi Informasi Nomor 2 Tahun 2016 tentang Tata Cara Pemeriksaan Setempat
                                          </div>
                                          <div class="flex justify-center lg:mt-0 mt-2">
                                             <a href={ppidDoc8} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                          </div>
                                        </div>
                                      </span>

                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Peraturan Komisi Informasi Nomor 1 Tahun 2013 tentang Penyelesaian Sengketa Informasi Publik
                                          </div>
                                          <div class="flex justify-center lg:mt-0 mt-2">
                                          <a href={ppidDoc9} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                          </div>
                                        </div>
                                      </span>                                      
                                    
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col my-10 mx-2 text-justify" style={{ borderTopLeftRadius: '1rem',borderTopRightRadius: '1rem'}}>
                            <div class="mb-2 lg:border lg:rounded-lg rounded-sm"style={{width:'100%'}}>
                                <div  class={`text-white  align-middle font-medium lg:rounded-t-lg lg:rounded-lg text-lg px-2 lg:py-4 py-2 flex flex-row-reverse cursor-pointer hover:opacity-90 border-b  css-ck7407 min-w-[1075px]`} onClick={() => toggleDropdown('dropdown2')} style={gradientStyle}>
                                <ChevronUpIcon
                                      className={`${
                                        isOpen.dropdown2 ? "rotate-180 transform" : ""
                                      } h-5 w-5 text-primary-500`}
                                    />
                                    <div class="flex-auto mt-1 text-lg">Peraturan mengenai Keterbukaan Informasi Publik yang dikeluarkan PT Pelindo Solusi Logistik</div>
                                    <div class="px-2 ">
                                        <img src={icons} className=" flex justify-left" alt="ppic-info" />
                                    </div>
                                    </div>
                                    
                                    <div class={`lg:px-4 px-2 py-2 text-justify text-left text-gray-800 lg:mb-5 mb-3 bg-white block  ${isOpen.dropdown2? ' ': 'hidden'}`}  style={{width:'100%'}}>
                                      
                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Peraturan Direksi PT Pelabuhan Indonesia (Persero) nomor: HK.01/3/101/KMKP/UTMA/PLND-22
                                          </div>
                                          <div class="flex justify-center ">
                                            <a href={ppidDoc10} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                         </div>
                                        </div>
                                      </span>

                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Peraturan Direksi PT Pelindo Solusi Logistik nomor: HK.01/17/10/1/KKPJ/DIRUT/PLSL-23
                                          </div>
                                          <div class="flex justify-center lg:mt-0 mt-2">
                                             <a href={ppidDoc11} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                         </div>
                                        </div>
                                      </span>                                      
                                    
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col my-10 mx-2 text-justify" style={{ borderTopLeftRadius: '1rem',borderTopRightRadius: '1rem'}}>
                            <div class="mb-2 lg:border lg:rounded-lg rounded-sm"style={{width:'100%'}}>
                                <div  class={`text-white  align-middle font-medium lg:rounded-t-lg lg:rounded-lg text-lg px-2 lg:py-4 py-2 flex flex-row-reverse cursor-pointer hover:opacity-90 border-b  css-ck7407 min-w-[1075px]`} onClick={() => toggleDropdown('dropdown3')} style={gradientStyle}>
                                <ChevronUpIcon
                                      className={`${
                                        isOpen.dropdown3 ? "rotate-180 transform" : ""
                                      } h-5 w-5 text-primary-500`}
                                    />
                                    <div class="flex-auto mt-1 text-lg">Standar Pelayanan Informasi Publik PT Pelindo Solusi Logistik</div>
                                    <div class="px-2 ">
                                        <img src={icons} className=" flex justify-left" alt="ppic-info" />
                                    </div>
                                    </div>
                                    
                                    <div class={`lg:px-4 px-2 py-2 text-justify text-left text-gray-800 lg:mb-5 mb-3 bg-white block  ${isOpen.dropdown3? ' ': 'hidden'}`}  style={{width:'100%'}}>
                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Prosedur Uji Konsekuansi
                                          </div>
                                          <div class="flex justify-center ">
                                            <a href={ppidDoc12} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                          </div>
                                        </div>
                                      </span>

                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Prosedur Permohonan Informasi
                                          </div>
                                          <div class="flex justify-center lg:mt-0 mt-2">
                                            <a href={ppidDoc13} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                          </div>
                                        </div>
                                      </span>

                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Prosedur Penetapan dan Pemutakhiran Daftar Informasi Publik
                                          </div>
                                          <div class="flex justify-center lg:mt-0 mt-2">
                                              <a href={ppidDoc14} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                          </div>
                                        </div>
                                      </span>

                                      <span>
                                        <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                          <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                            <div style={{ marginRight: '10px'}}>
                                              <img src={ppidDoc} alt="ppic-info" />
                                            </div> 
                                            Prosedur Pendokumentasian Informasi Publik
                                          </div>
                                          <div class="flex justify-center lg:mt-0 mt-2">
                                           <a href={ppidDoc15} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                          </div>
                                        </div>
                                      </span>
                                      
                                                                          
                                    </div>
                                </div>
                            </div>

                          </div> 
                          

                                    
                        </div>
                        </>
                      );
                    })}
                  </div>
                )}
            </div>
        </div>
      </div>
      <img src={bgFooter} alt="icon" className="relative object-fill w-full" />
      <ChatBox/>
      {/* <img src={bgFooter} alt="icon" className="relative object-fill w-full" /> */}
    </>
  );
  
};
export default Regulasi;
