import React, { useEffect, useState } from "react";
import SlideshowCustom from "components/reuseable/Slideshow/SlideshowCustom";
import ApiGet from "api/ApiGet";
import { uAPI } from "api/config";
import { responseResult } from "utils/ResponseStatus";
import ReactSlideshow from "components/reuseable/Slideshow/ReactSlideshow";

const TestimoniHome = () => {
    const [state, setState] = useState([])

    useEffect(() => {
        ApiGet.actions.Testimoni()
            .then((response) => {
                if (responseResult(response)) {
                    setState(responseResult(response))
                }
            })
        return () => {
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const customChildren = (data) => {
        // console.log('customChildren', data)
        return data.map((_val, _index) => {
            if (_val) {
                return (
                    <div key={_index} className="flex flex-col items-center rounded-lg border mx-4 p-[30px] max-w-[340px] whitespace-normal" >
                        <img className="testi-img" src={uAPI + _val?.picture} alt={_val?.picture_alt} style={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '50%'
                        }} />
                        <div className="text-[20px] font-semibold ">
                            {_val?.name}
                        </div>
                        <div className="text-secondary text-[15px] h-[65px]">
                            {_val?.jabatan}
                        </div>
                        <img className="my-[15px] testi-img-pt" src={uAPI + _val?.company_logo} alt={_val?.company_logo_alt} style={{
                            width: '200px',
                            height: '100px',
                        }} />
                        <div className="mt-4 text-secondary">
                            {_val?.testi}
                        </div>
                    </div >
                )
            }
            return _val;
        })
    }

    const customIndicators = (index) => {
        // console.log('customIndicators', index)
        return (
            <div className="indicator-dot mb-[50px]"></div>
        );
    }

    const responsive = [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        }
    ]
    const prevArrow = () => {
        return (
            <div>
                left
            </div>
        )
    }

    const onChangeSlide = (from, to) => {
        console.log('onChangeSlide', from, to)
    }

    return (
        <>
            {
                state.length > 0 && (
                    // <SlideshowCustom data={state} showItem={1} customChildren={customChildren} withArrow={true} withDots={true} autoSlide />
                    <div className="px-[70px]">
                        <ReactSlideshow indicators={true} customIndicators={customIndicators} responsive={responsive} transitionDuration={500}
                            prevArrow={
                                <div className="bottom-arrow"></div>
                            }
                            nextArrow={
                                <div className="bottom-arrow"></div>
                            }
                        >
                            {
                                state.map((_val, _index) => {
                                    return (
                                        <div key={_index} className="flex flex-col items-center rounded-[15px] border mx-4 p-[30px] max-w-[340px] max-h-[490px] h-[494px] my-[40px] whitespace-normal" >
                                            <img className="testi-img" src={uAPI + _val?.picture} alt={_val?.picture_alt} style={{
                                                width: '100px',
                                                height: '100px',
                                                border: "1px solid #BBBBBB",
                                                borderRadius: '50%',
                                                objectFit: 'cover'
                                            }} />
                                            <div className="text-[20px] font-semibold ">
                                                {_val?.name}
                                            </div>
                                            <div className="text-secondary text-[15px] h-[45px] text-center line-clamp-2">
                                                {_val?.jabatan}
                                            </div>
                                            <div className="w-full h-[70px] flex items-center justify-center">
                                            <img className="my-[15px] testi-img-pt" src={uAPI + _val?.company_logo} alt={_val?.company_logo_alt} style={{
                                                width: '182px',
                                                // height: '63px',
                                                objectFit: 'cover'
                                            }} />
                                            </div>
                                            <div className="mt-4 text-secondary text-justify line-clamp-4 h-[210px] text-[15px]">
                                                {_val?.testi}
                                            </div>
                                        </div >
                                    )
                                })
                            }
                        </ReactSlideshow>
                    </div>
                )
            }
        </>
    )
}
export default TestimoniHome;