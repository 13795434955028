import { useDispatch, useSelector } from "react-redux";

function useRedux() {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    return {
        dispatch,
        ...state,
    };
}

export default useRedux;