import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const SlideshowCustom = (props) => {
    const { data, showItem, delay, autoSlide, customChildren, withArrow, withDots, arrowBottomDots } = props;

    const [itemSlide, setItemSlide] = useState(data); // eslint-disable-line

    const zip = (...arrays) => [
        ...new Array(
            Math.max(...arrays.map((array) => array.length))
        ),
    ].map((_, index) => arrays.map((array) => array[index]));

    const chunk = (array, items) => zip(
        ...[...new Array(items)].map((_, index) =>
            array.slice(index)
        )
    ).filter((_, index) => index % items === 0);

    const listing = chunk(itemSlide, showItem)

    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);
    const [paused, setPaused] = useState(false); // eslint-disable-line
    const [prevnextindex, setprevnextindex] = useState({
        prev: 0,
        next: 1
    });

    const [arrowClick, setArrowClick] = useState('');

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        if (index === 0) {
            setprevnextindex({
                prev: listing.length - 1,
                next: index + 1
            })
        } else if (index === listing.length - 1) {
            setprevnextindex({
                prev: index - 1,
                next: 0
            })
        } else {
            setprevnextindex({
                prev: index - 1,
                next: index + 1
            })
        }
    }, [index]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (autoSlide) {
            resetTimeout();
            timeoutRef.current = setTimeout(() => {
                if (!paused) {
                    setIndex((prevIndex) =>
                        prevIndex === listing.length - 1 ? 0 : prevIndex + 1
                    )
                }
            }, delay);
        }
        return () => {
            resetTimeout();
        };
    }, [index, paused, listing.length]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            {
                itemSlide.length > 0 && (
                    <div className="slideshow">
                        {
                            itemSlide.length > 0 && listing.map(((val, ind) => {
                                return (
                                    <div key={ind} className={`item fade  ${prevnextindex.prev === ind ? "prev" : ""} ${prevnextindex.next === ind ? "next" : ""} ${index === ind ? `${arrowClick} active` : ""}`}>
                                        {/* {val.length > 0 && val.map((_val, _index) => {
                                            if (_val) {
                                                return (
                                                    <div key={_index} className="flex flex-col mx-3" >
                                                        <img className="flex flex-row justify-center" src={_val.Image} alt={_val.ALT} />
                                                        <div className="rounded-lg bg-white drop-shadow-md p-5 mx-10 mt-[-60px]">
                                                            <div className="text-[12px] text-[#828585]">13 Mei 2022</div>
                                                            <div className="text-primary text-[14px] font-semibold">Tingkatkan Sinergi Logistik dan Kawasan Industri, SPSL Tandatangani Nota Kesepahaman dengan Himpunan Kawasan Industri Indonesia (HKI)
                                                            </div>
                                                            <div className="text-[12px] text-[#303030]">Oleh Admin</div>
                                                            <div className="text-[15px] text-[#39A3D6] text-right">Baca selengkapnya</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            return _val;
                                        })} */}
                                        {
                                            val.length > 0 && customChildren(val)
                                        }
                                    </div>
                                )
                            }))
                        }
                        {
                            arrowBottomDots ? (
                                <>
                                    {
                                        withDots && <div className="slideshowDots arrowBottomDots">
                                            {
                                                withArrow && (
                                                    <div className="left" onClick={() => {
                                                        setArrowClick('left-animate')
                                                        setIndex((prevIndex) =>
                                                            prevIndex === 0 ? listing.length - 1 : prevIndex - 1
                                                        )
                                                    }} />
                                                )
                                            }
                                            {
                                                itemSlide.length > 0 && listing.map(((val, ind) => {
                                                    return (
                                                        <div key={ind}
                                                            className={`slideshowDot-new ${index === ind ? "active" : ""}`}
                                                            onClick={() => {
                                                                setIndex(ind);
                                                            }}
                                                        />
                                                    )
                                                }))
                                            }
                                            {
                                                withArrow && (
                                                    <div className="right" onClick={() => {
                                                        setArrowClick('right-animate')
                                                        setIndex((prevIndex) =>
                                                            prevIndex === listing.length - 1 ? 0 : prevIndex + 1
                                                        )
                                                    }} />
                                                )
                                            }
                                        </div>
                                    }
                                </>
                            ) : (
                                <>
                                    {
                                        withArrow && (
                                            <div>
                                                <div className="left" onClick={() => {
                                                    setArrowClick('left-animate')
                                                    setIndex((prevIndex) =>
                                                        prevIndex === 0 ? listing.length - 1 : prevIndex - 1
                                                    )
                                                }} />
                                                <div className="right" onClick={() => {
                                                    setArrowClick('right-animate')
                                                    setIndex((prevIndex) =>
                                                        prevIndex === listing.length - 1 ? 0 : prevIndex + 1
                                                    )
                                                }} />
                                            </div>
                                        )
                                    }
                                    {
                                        withDots && <div className="slideshowDots">
                                            {
                                                itemSlide.length > 0 && listing.map(((val, ind) => {
                                                    return (
                                                        <div key={ind}
                                                            className={`slideshowDot ${index === ind ? "active" : ""}`}
                                                            onClick={() => {
                                                                setIndex(ind);
                                                            }}
                                                        />
                                                    )
                                                }))
                                            }
                                        </div>
                                    }
                                </>
                            )
                        }

                    </div>
                )
            }
        </>
    )
}

SlideshowCustom.defaultProps = {
    data: [],
    showItem: 1,
    delay: 5000, //5sec
    autoSlide: false,
    withArrow: false,
    withDots: false,
    arrowBottomDots: true,
};

SlideshowCustom.propTypes = {
    data: PropTypes.array.isRequired,
    showItem: PropTypes.number,
    delay: PropTypes.number,
    autoSlide: PropTypes.bool,
    customChildren: PropTypes.any.isRequired,
    withArrow: PropTypes.bool,
    withDots: PropTypes.bool,
    arrowBottomDots: PropTypes.bool,
};


export default SlideshowCustom