import { motion, useAnimationControls } from "framer-motion";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";

export default function Modal({
	isOpen,
	closeModal,
	children,
	size,
	msize,
	heightSize = "",
	className,
	flipping = false
}) {
	const flip = useAnimationControls();

	useEffect(() => {
		flip.start({ rotateX: flipping ? 180 : 0, scaleY: flipping ? -1 : 1 });
	}, [flipping]);

	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="fixed inset-0 z-40 overflow-y-auto" onClose={closeModal}>
					<Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
					<div className="min-h-screen flex items-center justify-center px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0" />
						</Transition.Child>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<motion.div
								className={`inline-block relative w-${size} md:w-${msize} ${heightSize} p-4 px-8 mt-3 text-left align-middle transition-all transform  rounded-xl ${className}`}
								initial={{ rotateX: 0 }}
								animate={flip}
								transition={{ ease: "linear", duration: .3 }}
							>
								{children}
							</motion.div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}
