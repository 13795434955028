import React, { useEffect, useState } from "react";
import batasFooter from "assets/images/line-footer-2.png";
import LogoPutih from "assets/images/logo_pt_psl_putih_footer.svg";
import LogoPutih1 from "assets/images/logo_pt_psl_putih1.svg";
import footerIg from "assets/images/icons/footer-ig.svg";
import footerTwitter from "assets/images/icons/icons-twitter-fill.svg";
import footerFb from "assets/images/icons/icons-facebook-fill.svg";
import footerLinked from "assets/images/icons/icons-linkedin.svg";
import { uAPI } from "api/config";
import logbase from "assets/images/logbase.png";
import { Link, useLocation } from "react-router-dom";
import { getFooter, getFooterUrlImg } from "redux/global/globalActions";
import useRedux from "redux/useRedux";
import axios from "axios";

const Footer = () => {
  const {
    dispatch,
    global: { footer, footerUrlImg },
    profil: {
      anakPerusahaan: {
        selected
      }
    }
  } = useRedux();

  const location = useLocation();

  useEffect(() => {
    dispatch(getFooter());
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getFooterUrlImg());
  }, [location]); //eslint-disable-line react-hooks/exhaustive-deps

  let test = footerUrlImg.find((data) => data.menu === location.pathname);
  const objectUrl = uAPI + test?.image;

  // console.log("footer footer", footer);

  return (
    <div className="mt-28">
      <div className="relative">
        {location.pathname === test?.menu ? (
          test?.menu === '/wilayah-kerja-anak-perusahaan' && selected?.footer_image ? (
            <>
              <img className="w-full" src={uAPI + selected?.footer_image} alt={test?.alt} />
            </>
          ) : (
            <>
              <img className="w-full" src={objectUrl} alt={test?.alt} />
            </>
          )
        ) : (
          ""
        )}
        {/* <div className="absolute z-10 top-[87%]">
          <img className="w-full" src={batasFooter} alt="batas-footer" />
        </div> */}
      </div>

      {/* <div className="relative bg-footer px-[30px] md:px-[130px]"> */}
      <div className="relative bg-footer flex flex-col">
        {/* <img className="md:absolute md:top-[-20%]" src={batasFooter} alt="batas-footer" /> */}
        <img
          className="w-full absolute bottom-[99.6%] left-0"
          src={batasFooter}
          alt="batas-footer"
        />
        {/* <div class="section-padding bg-footer-blend h-[480px] flex flex-col md:flex-row justify-between">
          <div className="mr-6">
            <div className="md:basis-1/6 flex flex-col text-white justify-self-start ">
              <img className="w-[180px] h-[80px]" src={LogoPutih} alt="logo" />
              <div className="text-[15px] mt-2">
                <div className="text-justify w-[280px]">
                  Menjadi penyedia solusi terbaik untuk ekosistem logistik yang
                  terintegrasi.
                </div>
              </div>
              <div className="flex flex-row justify-between mt-4">
                <img
                  className="cursor-pointer"
                  src={footerIg}
                  alt="icon"
                  width={22}
                  height={22}
                  onClick={() => {
                    footer?.instagram &&
                      window.open(footer?.instagram, "_blank");
                  }}
                />
                <img
                  className="cursor-pointer"
                  src={footerFb}
                  alt="icon"
                  width={22}
                  height={22}
                  onClick={() => {
                    footer?.youtube && window.open(footer?.youtube, "_blank");
                  }}
                />
                <img
                  className="cursor-pointer"
                  src={footerLinked}
                  alt="icon"
                  width={22}
                  height={22}
                  onClick={() => {
                    footer?.linkedin && window.open(footer?.linkedin, "_blank");
                  }}
                />
                <img
                  className="cursor-pointer"
                  src={footerTwitter}
                  alt="icon"
                  width={22}
                  height={22}
                  onClick={() => {
                    footer?.twitter && window.open(footer?.twitter, "_blank");
                  }}
                />
              </div>
            </div>
          </div>
          <div class="flex flex-col md:flex-row">
            <div className="text-white">
              <div className="flex flex-col m-4 w-full md:w-[250px]">
                <div className="text-[20px] font-semibold">Main Office</div>
                <div className="flex flex-row">
                  <div
                    className="text-[15px] text-justify cursor-pointer"
                    onClick={() => {
                      window.open(
                        `https://www.google.com/maps/search/${footer?.address}`,
                        "_blank"
                      );
                    }}
                  >
                    {footer?.address}
                  </div>
                </div>
              </div>
              <div className="flex flex-col m-4 w-full md:w-[250px]">
                <div className="text-[20px] font-semibold">Phone</div>
                <div className="text-[15px] cursor-pointer">
                  {footer?.phone}
                </div>
              </div>
              <div className="flex flex-col m-4 w-full md:w-[250px]">
                <div className="text-[20px] font-semibold">Email</div>
                <Link
                  className="flex flex-row"
                  to="#"
                  onClick={(e) => {
                    if (footer?.email) {
                      window.location.href = `mailto:${footer?.email}`;
                    }
                    e.preventDefault();
                  }}
                >
                  <div className="text-[15px]">{footer?.email}</div>
                </Link>
              </div>
            </div>
            <div className="text-white">
              <div className="flex flex-col m-4 w-full md:w-[250px]">
                <div className="text-[20px] font-semibold">Jenis Layanan</div>
                <div
                  className="text-[15px] cursor-pointer"
                  onClick={() => window.open("/logistic-cargo-service")}
                >
                  Logistic Service
                </div>
                <div
                  className="text-[15px] cursor-pointer"
                  onClick={() =>
                    window.open("/hinterland-development-services")
                  }
                >
                  Hinterland Development Service
                </div>
              </div>
              <div className="flex flex-col m-4 w-full md:w-[250px]">
                <div className="text-[20px] font-semibold">
                  Media & Publikasi
                </div>
                <div
                  className="text-[15px] cursor-pointer"
                  onClick={() => window.open("/kabar-terbaru")}
                >
                  Kabar Terbaru
                </div>
                <div
                  className="text-[15px] cursor-pointer"
                  onClick={() => window.open("/siaran-pers")}
                >
                  Siaran Pers
                </div>
              </div>
              <div className="flex flex-col m-4 w-full md:w-[250px]">
                <div className="text-[20px] font-semibold">
                  Hubungan Investor
                </div>
                <div
                  className="text-[15px] cursor-pointer"
                  onClick={() => window.open("/tahunan")}
                >
                  Laporan Tahunan
                </div>
                <div
                  className="text-[15px] cursor-pointer"
                  onClick={() => window.open("/keberlanjutan")}
                >
                  Laporan Keberlanjutan
                </div>
                <div
                  className="text-[15px] cursor-pointer"
                  onClick={() => window.open("/keuangan")}
                >
                  Laporan Keuangan
                </div>
              </div>
            </div>
          </div>
          <div className="text-white">
            <div
              onClick={() => {
                window.open("https://logbase.co.id/", "_blank");
              }}
              className="flex flex-col m-4 w-full md:w-[220px] cursor-pointer"
            >
              <div className="text-[20px] font-semibold">
                Logistic Marketplace
              </div>
              <img src={logbase} className="w-[220px]" alt="logbase" />
            </div>
          </div>
        </div> */}
        <div class="section-padding bg-footer-blend grid md:grid-cols-4 gap-10 pb-[70px]">
          <div className="pt-[10px]">
            {/* <img className="" src={LogoPutih} alt="logo" /> */}
            <img className="" src={LogoPutih1} alt="logo" />
            <div className="text-white text-justify text-[15px]">
              Menjadi penyedia solusi terbaik untuk ekosistem logistik yang
              terintegrasi.
            </div>
            <div className="flex flex-row mt-4">
              <img
                className="cursor-pointer mr-[15px]"
                src={footerIg}
                alt="icon"
                width={22}
                height={22}
                onClick={() => {
                  footer?.instagram &&
                    window.open(footer?.instagram, "_blank");
                }}
              />
              <img
                className="cursor-pointer mr-[15px]"
                src={footerFb}
                alt="icon"
                width={22}
                height={22}
                onClick={() => {
                  footer?.youtube && window.open(footer?.youtube, "_blank");
                }}
              />
              <img
                className="cursor-pointer mr-[15px]"
                src={footerLinked}
                alt="icon"
                width={22}
                height={22}
                onClick={() => {
                  footer?.linkedin && window.open(footer?.linkedin, "_blank");
                }}
              />
              <img
                className="cursor-pointer"
                src={footerTwitter}
                alt="icon"
                width={22}
                height={22}
                onClick={() => {
                  footer?.twitter && window.open(footer?.twitter, "_blank");
                }}
              />
            </div>
          </div>
          <div class="col-span-2 grid md:grid-cols-2 gap-10 pt-[30px]">
            <div className="grid grid-flow-row auto-rows-max gap-x-10 gap-y-6 text-white text-[15px]">
              <div className="">
                <div className="font-bold">Main Office</div>
                <div className="flex flex-row">
                  <div
                    className="text-justify cursor-pointer"
                    onClick={() => {
                      window.open(
                        `https://www.google.com/maps/search/${footer?.address}`,
                        "_blank"
                      );
                    }}
                  >
                    {footer?.address}
                  </div>
                </div>
              </div>
              <div className="">
                <div className="font-bold">Phone</div>
                <div className="cursor-pointer">
                  {footer?.phone}
                </div>
              </div>
              <div className="">
                <div className="font-bold">Email</div>
                <Link
                  className="flex flex-row"
                  to="#"
                  onClick={(e) => {
                    if (footer?.email) {
                      window.location.href = `mailto:${footer?.email}`;
                    }
                    e.preventDefault();
                  }}
                >
                  <div className="">{footer?.email}</div>
                </Link>
              </div>
            </div>
            <div className="grid grid-flow-row auto-rows-max gap-x-10 gap-y-6 text-white text-[15px]">
              <div className="">
                <div className="font-bold">Jenis Layanan</div>
                <div
                  className="cursor-pointer"
                  onClick={() => window.open("/end-to-end-logistics-services")}
                >
                  End to End Logistics Services
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    window.open("/hinterland-development-services")
                  }
                >
                  Hinterland Development Service
                </div>
              </div>
              <div className="">
                <div className="font-bold">
                  Media & Publikasi
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => window.open("/kabar-terbaru")}
                >
                  Kabar Terbaru
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => window.open("/siaran-pers")}
                >
                  Siaran Pers
                </div>
              </div>
              <div className="">
                <div className="font-bold">
                  Hubungan Investor
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => window.open("/tahunan")}
                >
                  Laporan Tahunan
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => window.open("/keberlanjutan")}
                >
                  Laporan Keberlanjutan
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => window.open("/keuangan")}
                >
                  Laporan Keuangan
                </div>
              </div>
            </div>
          </div>
          <div className="pt-[30px]">
            <div className="text-[15px] text-white font-bold">
              Logistic Marketplace
            </div>
            <img src={logbase} className="cursor-pointer" alt="logbase"
              style={{
                width: '100%',
                maxWidth: '180px',
                height: 'auto'
              }}
              onClick={() => {
                window.open("https://logbase.co.id/", "_blank");
              }} />
          </div>
        </div>
        {/* <div className="flex flex-wrap flex-row justify-between py-[20px]">
          <div className="mt-10 w-full text-white text-[15px]">
            <p>PT. Pelindo Solusi Logistik. All Rights Reserved.</p>
            <p>for the best experience, use Google Chrome.</p>
          </div>
        </div> */}
        <div className="bg-footer-bottom inline-flex h-[70px] items-center w-full ">
          <div className="section-padding w-full">
            <div className="w-[460px] text-white text-[15px]">
              Copyright © 2022 PT Pelindo Solusi Logistik. All Rights Reserved.
              for the best experience, use Google Chrome.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
