import React, { useEffect, useState } from "react";
// import BgSearch from 'assets/images/bg-search.png';
import TabSearch from "./components/TabSearch";
import { useLocation } from "react-router-dom";
import useRedux from "redux/useRedux";
import { getSearchCount, resetCountList } from "redux/search/searchActions";

export default function GlobalSearch() {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");

  const {
    dispatch,
    search: {
      menuList,
    }
  } = useRedux();

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    dispatch(getSearchCount(searchTerm
    ))
    return () => {
      dispatch(resetCountList())
    }
  }, [searchTerm])

  // console.log(location, "location luar");
  useEffect(() => {
    let resultSe = location.search.split("=")
    if (resultSe) {
      setSearch(resultSe[1])
      setSearchTerm(resultSe[1])
    }
  }, [location])

  return (
    <div className='mt-28'>
      <div className='bg-globalSearch w-full h-[232px] bg-profil flex justify-center items-center' >
        <div className='text-[40px] font-semibold text-white '>SEARCH</div>
      </div>
      <div className='flex justify-center items-center'>
        <div className="py-14">
          <div className="flex ">
            <input className="search w-96" type="search" placeholder="Search"
              value={search}
              onChange={handleChange} />
            <div className="bg-[#2FB6E9] rounded-md ml-2" onClick={() => setSearchTerm(search)}>
              <div className="p-3">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.2897 21.9239L12.248 14.5748C10.7371 15.6956 8.87784 16.1833 7.03866 15.9411C5.19947 15.6989 3.5146 14.7445 2.31798 13.2671C1.12135 11.7897 0.50031 9.89716 0.577931 7.96448C0.655552 6.0318 1.42617 4.20009 2.73706 2.83236C4.0476 1.46404 5.80299 0.659462 7.65528 0.578117C9.50756 0.496773 11.3215 1.1446 12.7376 2.39318C14.1537 3.64176 15.0685 5.39995 15.3008 7.31924C15.533 9.23853 15.0657 11.1788 13.9915 12.7555L21.0332 20.1046L19.2909 21.9226L19.2897 21.9239ZM7.96381 3.1435C6.83032 3.14323 5.73137 3.55065 4.85214 4.29713C3.9729 5.04361 3.36712 6.08351 3.13686 7.24163C2.9066 8.39975 3.06594 9.60529 3.58802 10.6551C4.11011 11.705 4.96303 12.535 6.00308 13.0052C7.04313 13.4755 8.20674 13.5573 9.29785 13.2369C10.389 12.9165 11.3409 12.2134 11.9932 11.2461C12.6456 10.2789 12.9585 9.10653 12.8792 7.92665C12.7999 6.74677 12.3333 5.63146 11.558 4.76864L12.3034 5.54007L11.4631 4.66579L11.4483 4.65036C10.9919 4.17116 10.4489 3.79123 9.85077 3.53257C9.25264 3.27392 8.61127 3.14167 7.96381 3.1435Z" fill="#FBFBFB" />
                </svg>
              </div>
            </div>
          </div>
          <div className="text-xs text-[#BBBBBB] py-3 select-none">Search result of "Logistik". 21 entry found</div>
        </div>
      </div>
      <div>
        <TabSearch search={searchTerm} />
      </div>
    </div>
  )
}
