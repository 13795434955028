import { RESETDETAILSFASILITAS, RESETFASILITAS, SETFASILITAS } from "./fasilitasTypes";

const initialState = {
    data: [],
    dataCategory: [],
    details: {},
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SETFASILITAS:
            return {
                ...state,
                ...payload
            };
        case RESETFASILITAS:
            return {
                ...state,
                data: [],
                dataCategory: [],
            };
        case RESETDETAILSFASILITAS:
            return {
                ...state,
                details: {},
            };

        default:
            return state;
    }
}

export default reducer;