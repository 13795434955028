import ApiGet from "api/ApiGet";
import { setDataLoading } from "redux/global/globalActions";
import { responseResult } from "utils/ResponseStatus";
import { COUNTLIST, RESETCOUNTLIST } from "./searchTypes";

export const setCountList = (payload) => {
    return {
        type: COUNTLIST,
        payload,
    };
};
export const resetCountList = () => {
    return {
        type: RESETCOUNTLIST,
    };
};

export const getSearchCount = (params) => async (dispatch) => {
    dispatch(setDataLoading(true));
    console.log(params,"masuk redux");
    return ApiGet.actions.Search(params)
        .then(res => {
            if (responseResult(res)) {
                dispatch(setCountList(responseResult(res)));
            }
            dispatch(setDataLoading(false));
        })
        .catch(err => {
            // dispatch(resetMenuListGcg())
            dispatch(setDataLoading(false));
        })
};

// export const getDetailsGcg = (params) => async (dispatch) => {
//     dispatch(setDataLoading(true));
//     return ApiGet.actions.GCGDetails(params)
//         .then(res => {
//             if (responseResult(res)) {
//                 dispatch(setDetailsGcg(responseResult(res)));
//             }
//             dispatch(setDataLoading(false));
//         })
//         .catch(err => {
//             dispatch(resetDetailsGcg())
//             dispatch(setDataLoading(false));
//         })
// };