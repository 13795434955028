import React from "react";
import PropTypes from "prop-types";
import { youtube_parser } from "utils/myFunc";

const YoutubeEmbed = ({ embedId, autoPlay = false }) => (
    <div className="video-responsive rounded-[10px]">
        <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${youtube_parser(embedId)}?autoplay=${autoPlay}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
);

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;
