import BasicDropdown from "components/reuseable/Dropdown/BasicDropdown";
import SelectDropdown from "components/reuseable/Dropdown/SelectDropdown"
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  setActiveMenuSidebar,
  setMenuSidebar,
} from "redux/global/globalActions";
import { setAnakPerusahaan } from "redux/profil/profilActions";
import useRedux from "redux/useRedux";
// import icSelected from "./assets/images/icons/icon-selected.svg";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import ppidDoc from "assets/images/icons/ppid-doc.svg";
import ppidInfo from "assets/images/icons/ppid-info.svg";
import ppidCheck from "assets/images/icons/ppid-check.svg";


const EPpid = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;
  // console.log('pathname', pathname)
  const {
    dispatch,
    global: { menu, menuSidebar, activeMenuSidebar },
    profil: { anakPerusahaan },
  } = useRedux();

  let navigate = useNavigate();

  const [open, setOpen] = useState(false);
  console.log('menu', menu);
  console.log('menuSidebar', menuSidebar);
  console.log('activeMenuSidebar', activeMenuSidebar);

  let pathFind = "";
  const findSubMenu = menu.find((val, ind) => {
    let child = val?.child;
    if (child?.length == 0) {
      pathFind = child?.find(
        (val) => val?.path.split("/")[1] === pathname.split("/")[1]
      );
      return child?.find(
        (val) => val?.path.split("/")[1] === pathname.split("/")[1]
      );
    }
    return null;
  });
  useEffect(() => {
    if (findSubMenu) {
      dispatch(setMenuSidebar(findSubMenu));
    }
    if (pathFind) {
      dispatch(setActiveMenuSidebar(pathFind));
    }
    return () => {
      dispatch(setMenuSidebar(null));
      dispatch(setActiveMenuSidebar(null));
    };
  }, [findSubMenu, pathFind]); // eslint-disable-line react-hooks/exhaustive-deps
  console.log('pathnames', pathFind?.path)
  console.log('findSubMenu', findSubMenu)
  console.log('pathFind', pathFind)

  const anakPerusahaanClick = (data) => {
    // console.log('anakPerusahaanClick', data)
    dispatch(
      setAnakPerusahaan({
        selected: data,
      })
    );
  };
  let dropdown = [
    { Title: "Profil", child: [{ title: "nama", path: "https:" }] },
    { Title: "GCG", child: [{ title: "nama", path: "https:" }] },
  ];

 
  const onClickMenu = (val) => {
    console.log('onClickMenu', val)
    val?.path && navigate(val?.path);
  };

  const bgTitle = (title) => {
    switch (title) {
      case "Profil":
        return "bg-profil";
      case "GCG":
        return "bg-gcg";
      case "Layanan":
        return "bg-layanan";
      case "Fasilitas":
        return "bg-fasilitas";
      case "Hubungan Investor":
        return "bg-hubungan";
      case "Media & Publikasi":
        return "bg-media";
      case "TJSL":
        return "bg-tjsl";
      default:
        return "bg-ppid";
    }
  };
  let tataKelola = [];
  let other = [];

  const menuSidebarss = ["E-PPID","Profile","Regulasi","Maklumat","Informasi Publik","Layanan Publik","Agenda","Registrasi Permohonan"];
  const menuSidebars = ["E-PPID"];
  let test2 = menuSidebar?.child.sort((a, b) => {
    return a.id - b.id;
  });

  console.log("length", menuSidebars?.child);

  return (
    <>
      <div className="mt-[110px]">
        <div
          className={`${bgTitle(
            "PPID"
          )} flex flex-col justify-center items-center`}
        >
          {/* <div className={`${bgTitle(findSubMenu?.title)} flex flex-col justify-center items-center`}> */}
          <div className="text-white text-[40px] font-semibold uppercase text-center">
            {"PPID"}
            {/* {findSubMenu?.title} */}
          </div>
        </div>
        <div className="section pt-[50px]">
          {/* <div className="flex flex-row flex-wrap md:flex-nowrap md:divide-x-2"> */}
          <div className="md:grid md:grid-cols-6 md:divide-x-2 ">
            <div className="hidden md:flex md:flex-col md:mr-2">
              {/* {findSubMenu?.title && <div className="text-active text-[15px] uppercase font-bold mb-4">{findSubMenu?.title}</div>} */}
              { {menuSidebars} && (
                <div className="text-inactive text-[17px] mb-4">
                                    <Disclosure>
                       {({ open }) => (
                        <Disclosure.Button
                        className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      
                        <Link href="" to={"/ppid"}>
                        <div className="my-1 text-[17px] ">E-PPID</div>
                        </Link>
                      </Disclosure.Button>
                      )}
            
                </Disclosure>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button 
                        className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    
                        <Link href="" to={"/profile"}>
                          <div className="my-1 text-[17px] ">Profil</div>
                        </Link>
                        {/* <ChevronUpIcon
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-primary-500`}
                        /> */}
                      </Disclosure.Button>
                      {/* {menuSidebars?.map((val, ind) => {
                        return (
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <Link
                              key={ind}
                              className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${
                                activeMenuSidebar?.path === val?.path
                                  ? "text-active font-semibold bg-[#F0F2F2] rounded-lg"
                                  : ""
                              }`}
                              to={`${val?.isEksternal ? "#" : val?.path}`}
                              onClick={(e) => {
                                // if (val?.isEksternal) {
                                  window.open("https://ppid.pelindo.co.id", "_blank");
                               // }
                              }}
                            >
                              {val?.title}
                            </Link>
                          </Disclosure.Panel>
                        );
                      })} */}
                    </>
                  )}
                </Disclosure>
                <Disclosure>
                       {({ open }) => (
                        <Disclosure.Button 
                        className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                       
                        <Link href="" to={"/regulasi"}>
                          <div className="my-1 text-[17px] ">Regulasi</div>
                        </Link>
                      </Disclosure.Button>
                      )}
                </Disclosure>
                <Disclosure>
                       {({ open }) => (
                        <Disclosure.Button 
                        className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                     
                        <Link href="" to={"/maklumat"}>
                           <div className="my-1 text-[17px] ">Maklumat</div>
                        </Link>
                      </Disclosure.Button>
                      )}
                </Disclosure>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                        <div className="my-1 text-[17px] ">Informasi Publik</div>
                        <ChevronUpIcon
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-primary-500`}
                        />
                      </Disclosure.Button>
                      {menuSidebars?.map((val, ind) => {
                        return (
                          <>
                          
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <Disclosure>
                                  {({ open }) => (
                                    <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                    <Link href="" to={"/informasi-berkala"}>
                                        <div className="my-1 text-[17px] ">Informasi Berkala</div>
                                    </Link>
                                    {/* <ChevronUpIcon
                                      className={`${
                                        open ? "rotate-180 transform" : ""
                                      } h-5 w-5 text-primary-500`}
                                    /> */}
                                  </Disclosure.Button>
                                  )}
                            </Disclosure>
                            <Disclosure>
                                  {({ open }) => (
                                    <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                    <Link href="" to={"/informasi-setiap-saat"}>
                                      <div className="my-1 text-[17px] ">Informasi Setiap Saat</div>
                                    </Link>
                                    {/* <ChevronUpIcon
                                      className={`${
                                        open ? "rotate-180 transform" : ""
                                      } h-5 w-5 text-primary-500`}
                                    /> */}
                                  </Disclosure.Button>
                                  )}
                            </Disclosure>
                            <Disclosure>
                                  {({ open }) => (
                                    <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                    <Link href="" to={"/informasi-serta-merta"}>
                                      <div className="my-1 text-[17px] ">Informasi Serta Merta</div>
                                    </Link>
                                    {/* <ChevronUpIcon
                                      className={`${
                                        open ? "rotate-180 transform" : ""
                                      } h-5 w-5 text-primary-500`}
                                    /> */}
                                  </Disclosure.Button>
                                  )}
                            </Disclosure>
                          </Disclosure.Panel>
                          </>
                        );
                      })}
                    </>
                  )}
                </Disclosure>
                <Disclosure>
                       {({ open }) => (
                        <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                       
                        <Link href="" to={"/layanan-publik"}>
                            <div className="my-1 text-[17px] ">Layanan Publik</div>
                        </Link>
                      </Disclosure.Button>
                      )}
                </Disclosure>
                <Disclosure>
                       {({ open }) => (
                        <Disclosure.Button
                        className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    
                        <Link href="" to={"/kebijakan-privasi"}>
                            <div className="my-1 text-[17px] ">Kebijakan Privasi</div>
                        </Link>
                      </Disclosure.Button>
                      )}
                </Disclosure>
                <Disclosure>
                       {({ open }) => (
                        <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                       <Link href="" to={"/registrasi-permohonan"}>
                            <div className="my-1 text-[17px] ">Registrasi Permohonan</div>
                        </Link>              
                            
                          
                      </Disclosure.Button>
                      )}
                </Disclosure>    
                  
                </div>
              )}
              {/* {findSubMenu?.title?.length > 0 && (
                                findSubMenu?.child?.map((val, ind) => {
                                    return (
                                        <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${pathname === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                                            {val?.title}
                                        </Link>
                                    )
                                })
                            )} */}
              
              {menuSidebars?.child?.map((val, ind) => {               
                  if(val?.title == undefined ||val?.title == undefined){
                  return (
                    <>
                 
                  </>
                  );
                }
                })}
              {other?.length == undefined &&
                other.map((val, ind) => {
                  
                  if(val?.title == undefined ||val?.title == undefined){
               
                }})}
              {
                // <BasicDropdown
                //     className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg 'text-active font-semibold bg-[#F0F2F2] rounded-lg'`}
                //     title={'Tata Kelola'}
                //     items={tataKelola}
                //     onClickItems={onClickMenu}
                // />

                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                        {/* <div className="my-1 text-[15px] ">Detail</div>
                        <ChevronUpIcon
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-primary-500`}
                        /> */}
                      </Disclosure.Button>
                      {menuSidebars?.map((val, ind) => {
                        return (
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                              <Link
                              className="flex flex-col items-center justify-center text-active"
                              to={"#"}
                              onClick={(e) => {
                                window.open("https://ppid.pelindo.co.id/informasi", "_blank");
                              }}
                            >
                             
                              <div className="text-[15px] mt-50 text-center">Informasi berkala</div>
                            </Link>
                          </Disclosure.Panel>
                        );
                      })}
                    </>
                  )}
                </Disclosure>
              
              }

              {other?.length > 0 &&
                other.map((val, ind) => {
                  if(val?.title!=="Manual GCG"&&val?.title!=="Whistleblowing System"){
                  return (
                    // <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${pathname === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                    //     {val?.title}
                    // </Link>
                    // <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${menuSidebar?.active?.path === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                    //     {val?.title}
                    // </Link>
                    <>
                      <Link
                        key={ind}
                        className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${
                          activeMenuSidebar?.path === val?.path
                            ? "text-active font-semibold bg-[#F0F2F2] rounded-lg"
                            : ""
                        }`}
                        to={`${val?.isEksternal ? "#" : val?.path}`}
                        onClick={(e) => {
                          if (val?.isEksternal) {
                            window.open(val?.path, "_blank");
                          }
                        }}
                      >
                        {val?.title}
                      </Link>
                    </>
                  );
                }})}

      
            {menuSidebars?.child?.map((val, ind) => {  
            return (
    <>
  
    </>
  );
})}
            </div>
            {/* <div>
                            {
                                dropdown?dropdown.map((data)=>

                                ):null
                            }
                        </div> */}
            <div className="md:col-span-3 md:flex md:flex-col pl-0 md:pl-[20px]">
              {/* <div className="section flex flex-col ml-10 px-[20px] pt-[30px] md:px-[2px]"> */}
              {pathFind?.path === "/wilayah-kerja-anak-perusahaan" &&
                anakPerusahaan?.data?.length > 0 && (
                  <div className="py-6 border-t-2 md:hidden">
                    <SelectDropdown
                        items={anakPerusahaan?.data}
                        onClickItems={anakPerusahaanClick}
                        selectedTitle={anakPerusahaan?.selected?.company_name}
                        selectedValue={anakPerusahaan?.selected}
                    />
                  </div>
                )}
              <div className="section-detail flex flex-col">
                <Outlet context={pathFind} />
              </div>
            </div>
          </div>
          <div className="flex my-10 mx-2 md:mx-20 text-justify md:pl-[100px]">
              {pathFind?.path == undefined &&
                menuSidebars?.length > 0 && (
                  <div className="">
                    {menuSidebars?.map((val, ind) => {
                       return (
                        <>
                        <div className="mt-[0px]">
                          
                          <div className="section" style={{ marginTop: '-1025px', width:'118%' }}>
                            <div className="flex flex-col my-10 mx-2 md:mx-20 text-justify">
                              <div className="font-bold text-[15px]">
                                Selamat Datang di Layanan e-PPID.
                              </div>
                              <div className="text-[15px]">
                                Terima kasih telah mengunjungi layanan e-PPID PT Pelindo Solusi
                                Logistik. Layanan ini merupakan sarana layanan online bagi pemohon
                                informasi publik sebagai salah satu wujud pelaksanaan keterbukaan
                                informasi publik di PT Pelindo Solusi Logistik. Informasi yang
                                kami sediakan meliputi:
                              </div>
                              <div className="flex flex-row justify-between my-10">
                                <Link
                                  className="flex flex-col items-center justify-center text-active"
                                  to={"#"}
                                  onClick={(e) => {
                                    window.open("https://ppid.pelindo.co.id/informasi", "_blank");
                                  }}
                                >
                                  <img src={ppidDoc} alt="ppid-doc" />
                                  <div className="text-[15px] mt-2 text-center">Informasi berkala</div>
                                </Link>
                                <Link
                                  className="flex flex-col items-center justify-center text-active"
                                  to={"#"}
                                  onClick={(e) => {
                                    window.open(
                                      "https://ppid.pelindo.co.id/informasi/umum",
                                      "_blank"
                                    );
                                  }}
                                >
                                  <img src={ppidCheck} alt="ppid-check" />
                                  <div className="text-[15px] mt-2 text-center">Informasi Serta Merta</div>
                                </Link>
                                <Link
                                  className="flex flex-col items-center justify-center text-active"
                                  to={"#"}
                                  onClick={(e) => {
                                    window.open(
                                      "https://ppid.pelindo.co.id/informasi/wajib",
                                      "_blank"
                                    );
                                  }}
                                >
                                  <img src={ppidInfo} alt="ppic-info" />
                                  <div className="text-[15px] mt-2 text-center">Informasi Setiap Saat</div>
                                </Link>
                              </div>
                              <div className="mb-6 text-[15px]">
                                <b>PT Pelindo Solusi Logistik</b> yang merupakan salah satu
                                subholding PT Pelabuhan Indonesia (Persero) pada klaster bisnis
                                logistik dan hinterland development memiliki kewajiban untuk
                                memberikan informasi – informasi ke publik sesuai dengan ketentuan
                                yang berlaku. Berdasarkan UU No.14 tahun 2008 tentang Keterbukaan
                                Informasi Publik (KIP) oleh Pemerintah yaitu Badan Publik wajib
                                menyediakan, memberikan dan/atau menerbitkan Informasi Publik yang
                                berada di bawah kewenangannya kepada Pemohon Informasi Publik,
                                selain informasi yang dikecualikan sesuai dengan ketentuan.
                              </div>
                              <div className="mb-6 text-[15px]">
                                Portal e-ppid ini merupakan bagian dari layanan informasi publik
                                yang dilaksanakan oleh PT Pelabuhan Indonesia (Persero) sesuai
                                dengan Undang-undang Nomor 14 Tahun 2008 tentang Keterbukaan
                                Informasi Publik. PT Pelabuhan Indonesia (Persero) mem- berikan
                                kesempatan kepada masyarakat untuk mengetahui tentang perusahaan
                                melalui informasi yang kami sediakan maupun melalui permintaan
                                informasi.
                              </div>
                              <div className="mb-6 text-[15px]">
                                Dukungan masyarakat sangat kami perlukan dalam pelaksanaan
                                Keterbukaan Informasi Publik di lingkungan PT Pelabuhan Indonesia
                                (Persero) Group. Saat ini PT Pelindo Solusi Logistik menerapkan
                                pelayanan satu atap, sehingga informasi tersentralisasi, sehingga
                                segala bentuk berkaitan dengan peningkatan layanan berkaitan
                                dengan Keterbukaan Informasi Publik dapat disampaikan kepada PPID
                                Korporat PT Pelabuhan Indonesia (Persero). Sehingga segala bentuk
                                berkaitan dengan peningkatan layanan Keterbukaan Informasi Publik
                                dapat disampaikan kepada PPID Korporat PT Pelabuhan Indonesia
                                (Persero).
                              </div>
                  
                              <div className="text-[15px] mb-6">
                                <div className="font-bold mb-2">
                                  Pelayanan Informasi Publik SPSL (Pelindo Group)
                                </div>
                                <div className="font-semibold ">
                                  Persyaratan Permohonan Informasi
                                </div>
                                <ul>
                                  <li>Mengisi formulir permohonan informasi.</li>
                                  <li>Mencantumkan identitas yang jelas dan lengkap.</li>
                                  <li>
                                    Memberikan fotocopy tanda bukti diri (KTP/KK/SIM/Kartu
                                    Pelajar/Kartu Mahasiswa).
                                  </li>
                                  <li>Mencantumkan alamat dan nomor telepon yang jelas.</li>
                                  <li>
                                    Menyampaikan secara jelas jenis informasi yang dibutuhkan.
                                  </li>
                                  <li>Menyampaikan maksud dan tujuan permohonan informasi.</li>
                                  <li>
                                    Melampirkan surat perintah tugas (bila pemohon mengatasnamakan
                                    institusi/badan hukum).
                                  </li>
                                </ul>
                              </div>
                              <div className="text-[15px] mb-6">
                                <div className="font-bold mb-2">
                                  Biaya Pelayanan Informasi Publik
                                </div>
                                <div className="mb-2">
                                  <div className="font-semibold ">Hard Copy</div>
                                  <div>
                                    Penyampaian informasi yang dimohon berupa dokumen 1-10 lebar
                                    akan disampaikan korporasi secara gratis.
                                  </div>
                                </div>
                                <div className="mb-2">
                                  <div className="font-semibold ">Soft Copy</div>
                                  <div>
                                    Penyampaian informasi yang dimohon berupa dokumen 1-10 lembar
                                    akan dilakukan dengan cara memberikan soft copy (scan dokumen
                                    format .pdf) pemohon informasi harus membawa alat merekam
                                    informasi seperti USB, CD/DVD, atau Hardisk.
                                  </div>
                                </div>
                              </div>
                              <div className="text-[15px] mb-6">
                                <div className="font-bold mb-2">
                                  Jam Pelayanan Informasi Publik
                                </div>
                                <div>Senin - Kamis pukul 09.00 - 16.00 WIB</div>
                                <div>Jumat pukul 09.00 - 15.00 WIB</div>
                              </div>
                              <div className="text-[15px] mb-6">
                                <div className="font-bold mb-2">Informasi lebih Lanjut</div>
                                <div className="text-active">
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      window.location.href = `mailto:infosolusilogistik@pelindo.co.id`;
                                    }}
                                  >
                                    infosolusilogistik@pelindo.co.id
                                  </Link>
                                </div>
                                <div className="text-active">
                                  <Link
                                    to={"#"}
                                    onClick={(e) => {
                                      window.open("https://ppid.pelindo.co.id", "_blank");
                                    }}
                                  >
                                    https://ppid.pelindo.co.id
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      );
                    })}
                  </div>
                )}
            </div>
        </div>
      </div>
      {/* <img src={bgFooter} alt="icon" className="relative object-fill w-full" /> */}
    </>
  );
  
};
export default EPpid;
