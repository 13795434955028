import { text } from '@fortawesome/fontawesome-svg-core';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import images from "assets/images/ttd.png";
import ppidDoc from "assets/images/ttd.png";

const Card = ({ titles, title, content, image, content2,content3,content4,content5}) => {
  const gradientStyle = {
    background: 'linear-gradient(to right, #1C3865, #1C80B9, #36B4E5)'
  };
  return (
    <div style={styles.cardContainer}>
     
      <div style={styles.content}>
      <div style={{marginTop:'15px'}}></div>
      <div class="text-white font-medium lg:rounded-t-lg lg:rounded-lg text-lg px-2
        lg:py-4 py-2 flex flex-row-reverse cursor-pointer hover:opacity-60 border-b  css-ck7407" style={gradientStyle}>         
        <div class="flex-auto text-center text-[25px] font-bold uppercase">{titles}</div></div>
        <h2 class="font-bold text-center uppercase" style={styles.title}>{title}</h2>
        <p style={styles.text}>{content}</p><br></br>
        <p class="text-center" style={styles.text2}>{content2}</p>
        <p class="font-bold text-center" style={styles.text2}>{content3}</p>
        <p class="font-bold text-center" style={styles.text2}>{content4}</p>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vh' }}>
            <img class="image-center" src={ppidDoc} alt="ppic-info"/>
            {/* <img src={profileDoc} alt="profile-doc" style={{ maxWidth: '500px', height: '400px' }} /> */}
        </div>
        
        {/* {image && <img src={images} alt={title} style={styles.image} />} */}
      
        <p class="font-bold text-center text-decoration: underline" style={styles.text2}>{content5}</p>
      </div>
    </div>
  );
};

const styles = {
  cardContainer: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    width: '900px',
    margin: '20px',
  },
  image: {
    width: '50%',
    height: 'auto',
  },
  content: {
    padding: '15px',
  },
  title: {
    margin: '0 0 10px',
    fontSize: '1rem',
    text: 'center'    
  },
  text: {
    margin: 0,
    fontSize: '1rem',
    color: '#555',
  },
  text2: {
    margin: 0,
    fontSize: '1rem',
    color: '#555',  
    text2: 'center'  
  },
};

export default Card;