import React from "react";
import PropTypes from 'prop-types';

const RenderError = ({
    component,
    err
}) => {
    return (
        <div className='pt-10 pb-10'>
            <h2>Something went wrong.</h2>
            <details style={{ whiteSpace: 'pre-wrap' }}>
                component <b>{component}</b> has error: The prop <b>{err}</b> is marked as required. For detail please see error in console!!
            </details>
        </div>
    )
}

RenderError.propTypes = {
    component: PropTypes.string.isRequired,
    err: PropTypes.string.isRequired
}

export default RenderError