import ApiGet from "api/ApiGet";
import ApiPost from "api/ApiPost";
import ApiPut from "api/ApiPut";
import { setDataLoading } from "redux/global/globalActions";
import { responseResult } from "utils/ResponseStatus";
import { RESETANAKPERUSAHAAN, RESETMANAJEMEN, RESETMITRA, RESETSTRUKTURORGANISASI, SETANAKPERUSAHAAN, SETMANAJEMEN, SETMITRA, SETSTRUKTURORGANISASI, SETPENGHARGAAN, RESETPENGHARGAAN, SETPUBLIKASI, RESETPUBLIKASI, UPDATEPUBLIKASI, SETDETAILPUBLIKASI } from "./profilTypes";

export const setAnakPerusahaan = (payload) => {
    return {
        type: SETANAKPERUSAHAAN,
        payload,
    };
};
export const resetAnakPerusahaan = () => {
    return {
        type: RESETANAKPERUSAHAAN,
    };
};
export const getAnakPerusahaan = (params) => async (dispatch) => {
    dispatch(setDataLoading(true));
    return ApiGet.actions.AnakPerusahaan(params)
        .then(res => {
            if (responseResult(res)) {
                dispatch(setAnakPerusahaan({
                    data: responseResult(res)
                }));
            }
            dispatch(setDataLoading(false));
        })
        .catch(err => {
            dispatch(resetAnakPerusahaan())
            dispatch(setDataLoading(false));
        })
};

export const setMitra = (payload) => {
    return {
        type: SETMITRA,
        payload,
    };
};
export const resetMitra = () => {
    return {
        type: RESETMITRA,
    };
};
export const getMitra = (params) => async (dispatch) => {
    dispatch(setDataLoading(true));
    return ApiGet.actions.Mitra(params)
        .then(res => {
            if (responseResult(res)) {
                dispatch(setMitra({
                    data: responseResult(res)
                }));
            }
            dispatch(setDataLoading(false));
        })
        .catch(err => {
            dispatch(resetMitra())
            dispatch(setDataLoading(false));
        })
};

export const setManajemen = (payload) => {
    return {
        type: SETMANAJEMEN,
        payload,
    };
};
export const resetManajemen = () => {
    return {
        type: RESETMANAJEMEN,
    };
};
export const getKomisaris = (params) => async (dispatch) => {
    dispatch(setDataLoading(true));
    return ApiGet.actions.Komisaris(params)
        .then(res => {
            if (responseResult(res)) {
                dispatch(setManajemen({
                    dataKomisaris: responseResult(res)
                }));
            }
            dispatch(setDataLoading(false));
        })
        .catch(err => {
            dispatch(resetManajemen())
            dispatch(setDataLoading(false));
        })
};
export const getDireksi = (params) => async (dispatch) => {
    dispatch(setDataLoading(true));
    return ApiGet.actions.Direksi(params)
        .then(res => {
            if (responseResult(res)) {
                dispatch(setManajemen({
                    dataDireksi: responseResult(res)
                }));
            }
            dispatch(setDataLoading(false));
        })
        .catch(err => {
            dispatch(resetManajemen())
            dispatch(setDataLoading(false));
        })
};

export const setStrukturOrganisasi = (payload) => {
    return {
        type: SETSTRUKTURORGANISASI,
        payload,
    };
};
export const resetStrukturOrganisasi = () => {
    return {
        type: RESETSTRUKTURORGANISASI,
    };
};
export const getStrukturOrganisasi = (params) => async (dispatch) => {
    dispatch(setDataLoading(true));
    return ApiGet.actions.StrukturOrganisasi(params)
        .then(res => {
            if (responseResult(res)) {
                dispatch(setStrukturOrganisasi({
                    data: responseResult(res)
                }));
            }
            dispatch(setDataLoading(false));
        })
        .catch(err => {
            dispatch(resetStrukturOrganisasi())
            dispatch(setDataLoading(false));
        })
};
export const setPenghargaan = (payload) => {
    return {
        type: SETPENGHARGAAN,
        payload,
    };
};
export const resetPenghargaan = () => {
    return {
        type: RESETPENGHARGAAN,
    };
};
export const getSertifikat = (params) => async (dispatch) => {
    dispatch(setDataLoading(true))
    return ApiGet.actions.Sertifikasi(params)
        .then(res => {
            if (responseResult(res)) {
                dispatch(setPenghargaan({
                    data: responseResult(res)
                }))
            }
            dispatch(setDataLoading(false));
        })
        .catch(err => {
            dispatch(resetPenghargaan())
            dispatch(setDataLoading(false));
        })
}

export const setPublikasi = (payload) => {
    return {
        type: SETPUBLIKASI,
        payload
    }
}
export const setDetailPublikasi = (payload) => {
    return {
        type: SETDETAILPUBLIKASI,
        payload
    }
}

export const resetPublikasi = () => {
    return {
        type: RESETPUBLIKASI
    }
}

export const updatePublikasi = (payload) => {
    return {
        type: UPDATEPUBLIKASI,
        payload
    }
}

export const getDetailPublikasi = (id, params) => async (dispatch) => {
    dispatch(setDataLoading(true))
    await dispatch(updateView(id))
    return await ApiGet.actions.DetailPublikasi(id, params)
        .then(res => {
            let category = responseResult(res).category
            if (responseResult(res)) {
                console.log(params, "===", category, "get detail")
                if (category === params) {
                    dispatch(setDetailPublikasi({
                        data: responseResult(res),
                    }))
                }
                dispatch(setDetailPublikasi({
                    data: responseResult(res),
                }))
                // if (category === "Siaran Pers") {
                //     dispatch(setDetailPublikasi({
                //         data: responseResult(res),
                //     }))
                // } else if (category === "Kabar Terbaru") {
                //     dispatch(setDetailPublikasi({
                //         data: responseResult(res)
                //     }))
                // } else if (category === "Info TJSL") {
                //     dispatch(setDetailPublikasi({
                //         data: responseResult(res)
                //     }))
                // }
            }
            dispatch(setDataLoading(false));
        })
        .catch(err => {
            dispatch(resetPublikasi())
            dispatch(setDataLoading(false));
        })
}

export const getPublikasi = (params, limit = null) => async (dispatch) => {
    dispatch(setDataLoading(true))
    return ApiPost.actions.Publikasi(params)
        .then(res => {
            let category = responseResult(res).map(data => {
                return data.category
            })
            if (responseResult(res)) {
                console.log(category[0], "===", params, "get")
                if (category[0] === params) {
                    if (limit !== null && typeof limit === 'number') {
                        dispatch(setPublikasi({
                            data: responseResult(res).slice(0, limit), // Limit the array
                        }))
                    } else {
                        dispatch(setPublikasi({
                            data: responseResult(res),
                        }))
                    }
                }
                dispatch(setPublikasi({
                    data: responseResult(res),
                }))
            }
            dispatch(setDataLoading(false));
        })
        .catch(err => {
            dispatch(resetPublikasi())
            dispatch(setDataLoading(false));
        })
}

export const updateView = (id, viewers) => async (dispatch) => {
    dispatch(setDataLoading())
    return ApiPut.actions.Publikasi(id, viewers)
}