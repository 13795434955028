import informasiBerkala_1_1 from "assets/pdf/Profil-Singkat.pdf";
import informasiBerkala_1_2 from "assets/pdf/Identitas-Perusahaan.pdf";
import informasiBerkala_1_3 from "assets/pdf/Sejarah-Singkat-Perusahaan.pdf";
import informasiBerkala_1_4 from "assets/pdf/Informasi-tentang-Tugas-dan-Fungsi-Pokok-Perusahaan.pdf";
import informasiBerkala_2_1 from "assets/pdf/Informasi-tentang-Struktur-Organisasi-atau-Ketenagakerjaan.pdf";
import informasiBerkala_3_2 from "assets/pdf/Informasi-tentang-Sistem-dan-Alokasi-Renumerasi-Dewan-Komisaris-dan-Direksi.pdf";
import informasiBerkala_3_3 from "assets/pdf/Pengelolaan-Laporan-Harta-Kekayaan-Penyelenggara.pdf";
import informasiBerkala_4_4 from "assets/pdf/Informasi-tentang-Ringkasan-KinerjaPerusahaan.pdf";
import informasiBerkala_6_3 from "assets/pdf/Informasi-tentang-Ringkasan-Laporan-Layanan-dan-Akses-Informasi-Publik.pdf";
import informasiBerkala_6_4 from "assets/pdf/Informasi-tentang-Daftar-Informasi-Publik.pdf";
import informasiBerkala_7_1 from "assets/pdf/SOP-Rencana-Darurat.pdf";
import informasiBerkala_7_2 from "assets/pdf/SOP-Inspeksi-K3L.pdf";
import informasiBerkala_7_3 from "assets/pdf/SOP-Penyusunan-IBPPR-dan-OTP.pdf";
import informasiBerkala_7_4 from "assets/pdf/SOP-Pelaporan-Insiden-dan-Investigasi.pdf";
import InformasiSertaMerta_1_1 from "assets/pdf/Informasi-tentang-Jalur-Evakuasi-di-Lingkungan-PT-Pelindo-Solusi-Logistik.pdf";
import InformasiSertaMerta_1_2 from "assets/pdf/Informasi-tentang-Protokol-Kesehatan-di-Lingkungan-PT-Pelindo-Solusi-Logistik.pdf";
import InformasiSetiapSaat_1_1 from "assets/pdf/Informasi-tentang-Daftar-Peraturan-Keputusan-dan-atau-Kebijakan-Perusahaan.pdf";
import InformasiSetiapSaat_2_1 from "assets/pdf/Informasi-tentang-Organisasi-Perusahaan.pdf";
import InformasiSetiapSaat_3_1 from "assets/pdf/Informasi-tentang-Nama-Akuntan-Publik.pdf";
import InformasiSetiapSaat_4_1 from "assets/pdf/Informasi-tentang-Ikhtisar-Laba-Rugi.pdf";
import InformasiSetiapSaat_5_1 from "assets/pdf/Informasi-tentang-Daftar-Rencana-Kerja-Manajemen-SPSL-2023.pdf";
import InformasiSetiapSaat_6_1 from "assets/pdf/Informasi-tentang-Tingkat-Kepuasan-Pelanggan.pdf";
import InformasiSetiapSaat_7_1 from "assets/pdf/Informasi-tentang-Ringkasan-Laporan-Layanan-dan-Akses-Informasi-Publik.pdf";
import InformasiSetiapSaat_11_1 from "assets/pdf/Informasi-tentang-Hasil-Assessment-GCG.pdf";
import InformasiSetiapSaat_12_1 from "assets/pdf/Informasi-tentang-Mekanisme-Penetapan-Dewan-komisaris-dan-Direksi.pdf";
import InformasiSetiapSaat_13_1 from "assets/pdf/Informasi-tentang-Roadmap-Perusahaan.pdf";
import InformasiSetiapSaat_15_1 from "assets/pdf/Peristiwa-Penting-atau-Dokumentasi-Kegiatan.pdf";


export const informasiBerkala = {
    informasiBerkala_1_1,
    informasiBerkala_1_2,
    informasiBerkala_1_3,
    informasiBerkala_1_4,
    informasiBerkala_2_1,
    informasiBerkala_3_2,
    informasiBerkala_3_3,
    informasiBerkala_4_4,
    informasiBerkala_6_3,
    informasiBerkala_6_4,
    informasiBerkala_7_1,
    informasiBerkala_7_2,
    informasiBerkala_7_3,
    informasiBerkala_7_4
}

export const InformasiSetiapSaatPdf = {
    InformasiSetiapSaat_1_1,
    InformasiSetiapSaat_2_1,
    InformasiSetiapSaat_3_1,
    InformasiSetiapSaat_4_1,
    InformasiSetiapSaat_5_1,
    InformasiSetiapSaat_6_1,
    InformasiSetiapSaat_7_1,
    InformasiSetiapSaat_11_1,
    InformasiSetiapSaat_12_1,
    InformasiSetiapSaat_13_1,
    InformasiSetiapSaat_15_1,
}

export const InformasiSertaMertaPdf = {
    InformasiSertaMerta_1_1,
    InformasiSertaMerta_1_2
}

