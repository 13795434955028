import { RESETANAKPERUSAHAAN, RESETMANAJEMEN, RESETMITRA, RESETSTRUKTURORGANISASI, SETANAKPERUSAHAAN, SETMANAJEMEN, SETMITRA, SETSTRUKTURORGANISASI, SETPENGHARGAAN, RESETPENGHARGAAN, SETPUBLIKASI, RESETPUBLIKASI, UPDATEPUBLIKASI, SETDETAILPUBLIKASI } from "./profilTypes";

const initialState = {
    anakPerusahaan: {
        data: [],
        selected: null
    },
    mitra: {
        data: [],
    },
    manajemen: {
        dataKomisaris: [],
        dataDireksi: [],
    },
    strukturOrganisasi: {
        data: null,
    },
    penghargaan: {
        data: []
    },
    publikasi: {
        data: [],
        dataPers: [],
        dataTerbaru: [],
        dataTJSL: []
    },
    countView: {
        data: 0
    },
    detailPublikasi: {
        data: null,
        dataPers: [],
        dataTerbaru: [],
        dataTJSL: []
    }

};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SETANAKPERUSAHAAN:
            return {
                ...state,
                anakPerusahaan: {
                    ...state.anakPerusahaan,
                    ...payload
                },
            };
        case RESETANAKPERUSAHAAN:
            return {
                ...state,
                anakPerusahaan: {
                    data: [],
                    selected: null
                }
            };
        case SETMITRA:
            return {
                ...state,
                mitra: {
                    ...state.mitra,
                    ...payload
                },
            };
        case RESETMITRA:
            return {
                ...state,
                mitra: {
                    data: [],
                }
            };
        case SETMANAJEMEN:
            return {
                ...state,
                manajemen: {
                    ...state.manajemen,
                    ...payload
                },
            };
        case RESETMANAJEMEN:
            return {
                ...state,
                manajemen: {
                    dataKomisaris: [],
                    dataDireksi: [],
                }
            };
        case SETSTRUKTURORGANISASI:
            return {
                ...state,
                strukturOrganisasi: {
                    ...state.strukturOrganisasi,
                    ...payload
                },
            };
        case RESETSTRUKTURORGANISASI:
            return {
                ...state,
                strukturOrganisasi: {
                    data: null
                }
            };
        case SETPENGHARGAAN:
            return {
                ...state,
                penghargaan: {
                    ...state.penghargaan,
                    ...payload
                }
            };
        case RESETPENGHARGAAN:
            return {
                ...state,
                penghargaan: {
                    data: []
                }
            };
        case SETPUBLIKASI:
            // console.log(payload, "state from reducer")
            return {
                ...state,
                publikasi: {
                    ...state.publikasi,
                    ...payload
                }
            };
        case RESETPUBLIKASI:
            return {
                ...state,
                publikasi: {
                    data: [],
                    dataPers: [],
                    dataTerbaru: [],
                    dataTJSL: []
                }
            };
        case UPDATEPUBLIKASI:
            return {
                ...state,
                countView: {
                    ...state.countView,
                    ...payload
                }
            }
        case SETDETAILPUBLIKASI:
            return {
                ...state,
                detailPublikasi: {
                    ...state.detailPublikasi,
                    ...payload
                }
            }
        default:
            return state;
    }
}

export default reducer;