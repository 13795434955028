import React, { Fragment, useEffect } from "react";
import Media from 'react-media';
import SlideshowCustom from "components/reuseable/Slideshow/SlideshowCustom";
import { getPublikasi, resetPublikasi, updateView } from "redux/profil/profilActions";
import useRedux from "redux/useRedux";
import { uAPI } from "api/config";
import FroalaEditorView from "components/reuseable/FormInput/FroalaEditorTextArea";
import { useNavigate } from "react-router-dom";
import icSelengkapnya from 'assets/images/icons/diagonal-arrow-right.svg';
import ReactSlideshow from "components/reuseable/Slideshow/ReactSlideshow";

const BeritaHome = () => {
    const {
        dispatch,
        profil: {
            publikasi: {
                data,
            }
        }
    } = useRedux();
    // const { id } = useParams()
    // const {data} = useSelector((state) => state)

    // console.log(data, "from beranda")
    const nav = useNavigate()

    useEffect(() => {
        dispatch(getPublikasi(2, 16))
        return () => {
            dispatch(resetPublikasi())
        }
    }, [dispatch])
    const formatDate = (date) => {
        let d = new Date(date)
        let day = d.getDate()
        let month = d.toLocaleDateString('en-EN', { month: 'long', })
        let year = d.getFullYear()
        return `${day} ${month} ${year}`
    }
    const formatText = (text) => {
        const span = document.createElement('span');
        span.innerHTML = text;
        const textContent = span.textContent || span.innerText
        const subs = textContent.substring(0, 120)
        return subs
    }
    const incrementView = (id, viewers) => {
        let update = parseInt(viewers) + 1
        dispatch(updateView(id, update))
        nav(`/kabar-terbaru/${id}`)
    }

    const customChildren = (data) => {
        //    let storage = data
        // console.log( data, 'customChildren')
        return data.map((_val, _index) => {
            if (_val) {
                return (
                    <div key={_index} className="flex flex-col mx-4 rounded-lg bg-white drop-shadow-md w-[334.14px] h-[332.36px] p-5" >
                        <img className="flex flex-row justify-center mb-2" src={`${uAPI + _val?.picture}`} alt={_val.alt} style={{
                            width: "294.14px",
                            height: "156.86px",
                        }} />
                        <div className="text-[10px] text-[#828585]">{formatDate(_val?.date)}</div>
                        <p className="font-semibold w-[294.14px] pb-2 text-xs">{_val?.title}</p>
                        <div className="text-[12px] font-normal w-[294.14px]  h-[72px]">  {_val?.article && (
                            <FroalaEditorView
                                model={formatText(_val?.article)}
                            />
                        )}
                        </div>
                        <div className="text-[12px] pt-5 text-[#303030]">Oleh {_val.createdBy}</div>
                        <div className="flex flex-row justify-end pt-10" >
                            <button className="flex flex-row text-active items-center text-[15px] text-right cursor-pointer" onClick={() => {
                                incrementView(_val.id, _val.countView)
                            }}>
                                Selengkapnya <img className="ml-2 text" style={{
                                    height: '10px',
                                    width: '10px',
                                    top: '5.33px',
                                    left: '2.66px',
                                }} src={icSelengkapnya} alt="selengkapnya" />
                            </button>
                        </div>
                    </div>
                )
            }
            return _val;
        })
    }

    const customIndicators = (index) => {
        // console.log('customIndicators', index)
        return (
            <div className="indicator-dot"></div>
        );
    }

    const responsive = [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        }
    ]

    return (

        <>
            {data.length > 0 &&

                // <Media queries={{
                //     desktop: "(min-width: 1025px)",
                //     tablet: "(min-width: 768px) and (max-width: 1024px)",
                //     landscapeMobile: "(min-width: 481px) and (max-width: 767px)",
                //     portraitMobile: "(min-width: 320px) and (max-width: 480px)",
                // }}>
                //     {matches => (
                //         <Fragment>
                //             {
                //                 matches.desktop &&
                //                 <>
                //                     <SlideshowCustom data={data} showItem={1} customChildren={customChildren} withArrow={true} withDots={true} />
                //                 </>
                //             }
                //             {
                //                 matches.tablet &&
                //                 <>
                //                     <SlideshowCustom data={data} showItem={1} customChildren={customChildren} withArrow={true} withDots={true} />
                //                 </>
                //             }
                //             {
                //                 matches.landscapeMobile &&
                //                 <>
                //                     <SlideshowCustom data={data} showItem={1} customChildren={customChildren} withArrow={true} withDots={true} />
                //                 </>
                //             }
                //             {
                //                 matches.portraitMobile &&
                //                 <>
                //                     <SlideshowCustom data={data} showItem={1} customChildren={customChildren} withArrow={true} withDots={true} />
                //                 </>
                //             }
                //         </Fragment>
                //     )}
                // </Media>

                <ReactSlideshow indicators={true} customIndicators={customIndicators} responsive={responsive} transitionDuration={500}
                    prevArrow={
                        <div className="bottom-arrow"></div>
                    }
                    nextArrow={
                        <div className="bottom-arrow"></div>
                    }
                >
                    {
                        data.map((_val, _index) => {
                            return (
                                <div key={_index} className="flex flex-col
                                my-5 mx-4 rounded-lg bg-white drop-shadow-md max-w-[371.78px] p-5 position-absolute whitespace-normal h-[410px]" >
                                    <img className="flex flex-row justify-center mb-2 rounded-md" src={`${uAPI + _val?.picture}`} alt={_val.alt} style={{
                                        width: "100%",
                                        height: "156.86px",
                                    }} />
                                    <div className="text-[10px] text-[#828585]">{formatDate(_val?.date)}</div>
                                    <p className="font-semibold w-100 pb-2 text-xs whitespace-normal">{_val?.title}</p>
                                    <div className="text-[12px] font-normal w-100  min-h-[72px] whitespace-normal">  {_val?.article && (
                                        <FroalaEditorView
                                            model={formatText(_val?.article)}
                                        />
                                    )}
                                    </div>
                                    <div className="text-[12px] pt-5 text-[#303030]">Oleh {_val.createdBy}</div>
                                    <div className="flex flex-row justify-end pt-5" style={{
                                        position: "fixed",
                                        bottom: "12px",
                                        right: "18px"
                                    }}>
                                        <button className="flex flex-row text-active items-center text-right cursor-pointer" onClick={() => {
                                            incrementView(_val.id, _val.countView)
                                        }}>
                                            Selengkapnya <img className="ml-2" src={icSelengkapnya} alt="selengkapnya" />
                                        </button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </ReactSlideshow>
            }

        </>
    )
}
export default BeritaHome;