import React, { useEffect, useState } from "react";
import { Menu, Transition } from '@headlessui/react'
import useRedux from 'redux/useRedux';
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function SearchDropdown({ search, setShow, show }) {
  const {
    global: {
      route,
      menu,
      menuSidebar
    },
    profil: {
      publikasi: {
        data,
        dataPers,
        dataTerbaru,
        dataTJSL
      }
    },
    // profil,
    // hubunganInvestor,
    // layanan,
    gcg,
    // fasilitas,

  } = useRedux();

  let navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState([]);
  const [searchTermLength, setSearchTermLength] = useState(0);

  const onClickMenu = (val) => {
    // console.log('onClickMenu', val)
    setShow(false)
    val?.path && navigate(val?.path)
  }

  useEffect(() => {
    search = search.toUpperCase()
    if (search) {
      let result = []
      let index = 0
      menu?.map((child) => {
        let dataSearch = child?.child?.filter((val) => val.title.toUpperCase().includes(search))

        if (dataSearch?.length > 0) {
          let val = dataSearch?.length
          index += val
          result.push({ title: child.title, child: dataSearch })
        }
      })
      // console.log(index, "luar........");
      setSearchTermLength(index)
      setSearchTerm(result)
    } else {
      setSearchTermLength(0)
    }

  }, [search])


  return (
    <div className="absolute">
      {
        search?.length > 0 && searchTerm.length > 0 && show ?
          <div className={"z-[100] relative text-left bg-white w-80   rounded-md top-3  shadow-inner border"
            + (searchTermLength > 8 ? " h-96 overflow-y-auto" : "")
          }>
            <div>
              {
                searchTerm.length > 0 ?
                  <div className="">
                    {searchTerm.map((data) =>
                      <div>
                        <div className="bg-slate-300 px-3">{data.title}</div>
                        {data?.child.length > 0 ?
                          data?.child?.map((res) => <div className="border-b-2 py-3">
                            <div className="text-black px-4" onClick={() => onClickMenu(res)}>{res.title}</div>
                          </div>) : null}
                      </div>
                    )
                    }
                  </div>
                  : null
              }
            </div>
          </div> : null
      }
    </div>
  )
}
