import ApiGet from "api/ApiGet";
import React, { useEffect, useState } from "react";
import useRedux from "redux/useRedux";
import SearchCart from "./SearchCart";

export default function TabSearch({ search }) {
  const [isActive, setIsActive] = useState("Profile");
  const [dataCard, setDataCard] = useState([]);
  let tab = [
    { title: "Profile", raw: 0 },
    { title: "GCG", raw: 0 },
    { title: "Layanan", raw: 0 },
    { title: "Fasilitas", raw: 0 },
    { title: "Hubungan Investor", raw: 0 },
    { title: "Media & Publikasi", raw: 0 },
    { title: "TJSL", raw: 0 },
  ]
  const {
    dispatch,
    search: {
      menuList,
    }
  } = useRedux();
  let changeActive = (data) => {
    setIsActive(data.title)
  }
  useEffect(() => {
    if (isActive) {
      ApiGet.actions.SearchCard({ search: search, tab: isActive.replaceAll('& ', '') }).then((res) => {
        setDataCard(res.responseResult)
      }).catch(err => {
        setDataCard([])
        console.log(err.response);
      })
    }
  }, [isActive, search])


  return (
    <div className='px-32 pb-20'>
      <div className='flex gap-8 justify-center'>
        {menuList.length > 0 ?
          menuList.map((dataTab) =>
            <div className='flex items-center gap-2 cursor-pointer' onClick={() => changeActive(dataTab)}>
              <div className={'text-[15px] select-none' + (isActive == dataTab.title ? " text-black font-medium" : " text-[#BBBBBB]")}>{dataTab.title}</div>
              <div >
                <div className={'px-5 text-white rounded-md text-[15px] select-none' + (isActive == dataTab.title ? " bg-[#2FB6E9]" : " bg-[#BBBBBB]")}>{dataTab.raw}</div>
              </div>
            </div>
          ) : tab.map((dataTab) =>
            <div className='flex items-center gap-2 cursor-pointer' onClick={() => changeActive(dataTab)}>
              <div className={'text-[15px] select-none' + (isActive == dataTab.title ? " text-black font-medium" : " text-[#BBBBBB]")}>{dataTab.title}</div>
              <div >
                <div className={'px-5 text-white rounded-md text-[15px] select-none' + (isActive == dataTab.title ? " bg-[#2FB6E9]" : " bg-[#BBBBBB]")}>{dataTab.raw}</div>
              </div>
            </div>
          )
        }
      </div>
      <div className='my-4 border-t-2'></div>
      <div className="max-h-[1000px] overflow-y-auto">
        {
          dataCard?.length ? dataCard?.map((data) =>
            <SearchCart data={data} isActive={isActive} />
          ):""
        }

      </div>
    </div>
  )
}
