import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import pinOren from "assets/images/icons/pin-oren.svg";
import pinHijau from "assets/images/icons/pin-hijau.svg";
import pinBiru from "assets/images/icons/pin-biru.svg";
import pinAbu from "assets/images/icons/pin-abu.svg";

const MapsComponent = (props) => {
  const { location, zoom } = props;
  const ref = useRef();

  const handleLocationError = (browserHasGeolocation, infoWindow, pos) => {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    infoWindow.open(map);
  };

  var map, infoWindow; // eslint-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (location.length > 0) {
      const center = location[Math.floor(Math.random() * location.length)];

      map = new window.google.maps.Map(ref.current, {
        //eslint-disable-line react-hooks/exhaustive-deps
        zoom: zoom,
        center: center,
      });

      infoWindow = new window.google.maps.InfoWindow({
        //eslint-disable-line react-hooks/exhaustive-deps
        content: "",
        disableAutoPan: true,
      });

      const markers = location.map((position, i) => {
        // console.log('position', position)
        let label = position?.label;
        let layanan = position?.layanan;
        var icon = null;
        if (layanan?.match(/logistic/i)) {
          icon = pinBiru;
        } else if (layanan?.match(/hinterland/i)) {
          icon = pinAbu;
        }
        // const marker = new window.google.maps.Marker({
        //     position,
        //     map
        // });

        const marker = new window.google.maps.Marker({
          position,
          map,
          icon,
        });

        // markers can only be keyboard focusable when they have click listeners
        // open info window when marker is clicked
        marker.addListener("click", () => {
          infoWindow.setContent(label);
          infoWindow.open(map, marker);
        });
        return marker;
      });

      // const locationButton = document.createElement("button");

      // locationButton.textContent = "Pan to Current Location";
      // locationButton.classList.add("custom-map-control-button");
      // map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(locationButton);
      // locationButton.addEventListener("click", () => {
      //     // Try HTML5 geolocation.
      //     if (navigator.geolocation) {
      //         navigator.geolocation.getCurrentPosition(
      //             (position) => {
      //                 const pos = {
      //                     lat: position.coords.latitude,
      //                     lng: position.coords.longitude,
      //                 };

      //                 infoWindow.setPosition(pos);
      //                 infoWindow.setContent("Location found.");
      //                 infoWindow.open(map);
      //                 map.setCenter(pos);
      //             },
      //             () => {
      //                 handleLocationError(true, infoWindow, map.getCenter());
      //             }
      //         );
      //     } else {
      //         // Browser doesn't support Geolocation
      //         handleLocationError(false, infoWindow, map.getCenter());
      //     }
      // });

      // Add a marker clusterer to manage the markers.
      new MarkerClusterer({ markers, map });
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div ref={ref} id="map" className="rounded-xl md:w-full max-h-[366px]" />;
};
MapsComponent.defaultProps = {
  location: [
    // { lat: -6.117718, lng: 106.892635 }, //ilcs
    // { lat: -6.120847134379139, lng: 106.89360818261602 }, //pelindo tower
    // { lat: -6.105961704623567, lng: 106.89401977781567 }, //PT JIC
    // { lat: -6.106943156954852, lng: 106.88989990497316 }, //PT IPC
  ],
  zoom: 4,
};

MapsComponent.propTypes = {
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number,
};

export default MapsComponent;
