import { uAPI } from "api/config";
import axios from "axios";
import FileSaver from "file-saver";

export function youtube_parser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : url;
}
export function getFileExtension(filename) {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : ext[1];
}

export function formatText(text, limit = 230) {
    return text.substring(0, limit) + " ..."
}

export function slugUrl(text) {
    return text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '-');
    // return text.toLowerCase().replace(/ /g, '-')
    //     .replace(/[^\w-]+/g, '');
}

export function downloadFile(data) {
    return axios.get(uAPI + data, {
        responseType: 'arraybuffer'
    }).then(response => {
        console.log('response', response)
        var blob = new Blob([response?.data], { type: 'application/octet-stream' });
        FileSaver.saveAs(blob, data)
    })
}

export function getTextContent(text){
    const span = document.createElement('span');
    span.innerHTML = text;
    const textContent = span.textContent || span.innerText
    return textContent
}