export const SETANAKPERUSAHAAN = "SETANAKPERUSAHAAN";
export const RESETANAKPERUSAHAAN = "RESETANAKPERUSAHAAN";
export const SETMITRA = "SETMITRA";
export const RESETMITRA = "RESETMITRA";
export const SETMANAJEMEN = "SETMANAJEMEN";
export const RESETMANAJEMEN = "RESETMANAJEMEN";
export const SETSTRUKTURORGANISASI = "SETSTRUKTURORGANISASI";
export const RESETSTRUKTURORGANISASI = "RESETSTRUKTURORGANISASI";
export const SETPENGHARGAAN = "SETPENGHARGAAN"
export const RESETPENGHARGAAN = "RESETPENGHARGAAN"
export const SETPUBLIKASI = "SETPUBLIKASI"
export const RESETPUBLIKASI = "RESETPUBLIKASI"
export const UPDATEPUBLIKASI = "UPDATEPUBLIKASI"
export const SETDETAILPUBLIKASI = "SETDETAILPUBLIKASI"