import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import useRedux from 'redux/useRedux'

const BasicDropdown = ({
    title,
    items = [],
    onClickItems,
    classNameItems,
    colorChevron,
    active
}) => {
    const {
        global: {
            activeMenuSidebar
        }
    } = useRedux();

    return (
        <div className="">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md">
                        {title && (
                            <div className='flex flex-row'>
                                <div className={`mr-2 ${active ? 'text-active' : ''}`}>{title}</div>
                                {
                                    items.length > 0 && <div><FontAwesomeIcon icon={faChevronDown} color={active ? '#2FB6E9' : colorChevron} /></div>
                                }
                            </div>
                        )}
                    </Menu.Button>
                </div>
                {
                    items.length > 0 &&
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >

                        <Menu.Items className={`
                        absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg 
                        ring-1 ring-black ring-opacity-5 focus:outline-none z-10
                        ${classNameItems}`}>
                            {
                                items?.map((val, ind) => {
                                    if (val.title != "Laporan Penilaian GCG") {
                                        return (
                                            <Menu.Item key={ind}>
                                                <button className={`${activeMenuSidebar?.title === val?.title ? 'text-active' : 'text-gray-900'} text-left flex w-full items-center rounded-md p-4 text-sm`} onClick={() => onClickItems(val)}>
                                                    {val?.title}
                                                </button>
                                            </Menu.Item>
                                        )
                                    }
                                })
                            }
                        </Menu.Items>

                    </Transition>
                }
            </Menu>
        </div>
    )
}

export default BasicDropdown