import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import RenderError from "../RenderError";

const Form = (props) => {
    const {
        className,
        children,
        onSubmit,
    } = props;

    const ref = React.createRef();

    const [requiredProps, setRequiredProps] = useState([])
    const [errorProps, seterrorProps] = useState("")
    let temp = []
    useEffect(() => {
        for (const key in Form.propTypes) { //eslint-disable-line react/forbid-foreign-prop-types
            if (!Form.propTypes[key].isRequired) { //eslint-disable-line react/forbid-foreign-prop-types
                temp.push(key)
            }
        }
        if (temp.length > 0) {
            setRequiredProps(temp)
        }
    }, []) //eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (requiredProps.length > 0) {
            for (const key in requiredProps) {
                if (!props[requiredProps[key]]) {
                    seterrorProps(requiredProps[key]);
                    break;
                }
            }
        }
    }, [requiredProps]); //eslint-disable-line react-hooks/exhaustive-deps

    if (errorProps) {
        return <RenderError component="Form" err={errorProps} />
    }

    const submitForm = (e) => {
        e.preventDefault();
        onSubmit && onSubmit(e);
    }

    return (
        <>
            <form ref={ref} className={`${className}`} onSubmit={submitForm}>
                {children}
            </form>
        </>
    )
}
Form.defaultProps = {
    className: ''
};

Form.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
}
export default Form;