// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/bg-profil.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bg-img-profile {\n    width: 100%;\n    max-width: 245px;\n    height: 245px;\n    background: #666666;\n    border-radius: 122px 0px;\n}\n.img-profile {\n    width: 100%;\n    max-width: 245px;\n    height: auto;\n}\n.img-profile-manajemen {\n    width: 100%;\n    max-width: 588px;\n    height: auto;\n}\n\n.bg-profil {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n    height: 230px;\n}\n.clip-svg {   \n    clip-path: url(#myClip);\n}\n", "",{"version":3,"sources":["webpack://./src/assets/css/profil.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,wBAAwB;AAC5B;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,yDAAgD;IAChD,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;AACjB;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".bg-img-profile {\n    width: 100%;\n    max-width: 245px;\n    height: 245px;\n    background: #666666;\n    border-radius: 122px 0px;\n}\n.img-profile {\n    width: 100%;\n    max-width: 245px;\n    height: auto;\n}\n.img-profile-manajemen {\n    width: 100%;\n    max-width: 588px;\n    height: auto;\n}\n\n.bg-profil {\n    background-image: url(\"../images/bg-profil.png\");\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n    height: 230px;\n}\n.clip-svg {   \n    clip-path: url(#myClip);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
