import React from 'react'
import FroalaEditorView from "components/reuseable/FormInput/FroalaEditorTextArea";
import { useNavigate } from 'react-router-dom';
import { formatText } from "../../../../utils/myFunc";

export default function SearchCart({ data, isActive }) {
  let navigate = useNavigate();
  let addUrl = ""
  if (data.addUrl) {
    addUrl = `/${data.addUrl}`
  }

  const formatTitle = () => {
    return data?.url.replace('/', '').split("-").map(s => s !== "tjsl" ? s.charAt(0).toUpperCase() + s.slice(1) : "TJSL").join(' ')
  }

  return (
    <div className='py-3' onClick={() => navigate(data?.url + addUrl)}>
      <div className='px-4 py-1 bg-gradient-to-br from-[#36B4E5] to-[#0275BC] inline-block rounded-tl-lg rounded-br-lg'>
        <div className='text-white'>{formatTitle()}</div>
      </div>
      <div className='font-semibold mt-2'>
        {data?.title}
      </div>
      <div className='truncate'>
        {data?.article && (

          <FroalaEditorView
            model={formatText(data?.article)}
          />

        )}
      </div>
    </div>
  )
}
