import axios from "axios";
import { clientKey, uAPI } from "./config";

const getApiKey = (url = "", params) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(uAPI + url, {
        params,
        headers: {
          client_key: `${clientKey}`,
        },
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};

const UrlEncodedKey = (url = "", config) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(uAPI + url, config)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};

const ApiGet = {
  actions: {
    GetApiKey(params) {
      return getApiKey("/GetApiKey", params);
    },
    GetRoute(params) {
      return UrlEncodedKey("/landing/route", params);
    },
    Slideshow(params) {
      return UrlEncodedKey("/landing/slideshow", params);
    },
    Footer(params) {
      return UrlEncodedKey("/landing/footer", params);
    },
    FooterUrlImg(params) {
      return UrlEncodedKey("/landing/footer-url-img", params);
    },
    AnakPerusahaan(params) {
      return UrlEncodedKey("/landing/anak-perusahaan", params);
    },
    TentangKami(params) {
      return UrlEncodedKey("/landing/tentang-kami", params);
    },
    Testimoni(params) {
      return UrlEncodedKey("/landing/testimoni", params);
    },
    OurGroup(params) {
      return UrlEncodedKey("/landing/our-group", params);
    },
    PesanBod(params) {
      return UrlEncodedKey("/landing/quotes", params);
    },
    Mitra(params) {
      return UrlEncodedKey("/landing/mitra", params);
    },
    Komisaris(params) {
      return UrlEncodedKey("/landing/manajemen/komisaris", params);
    },
    Direksi(params) {
      return UrlEncodedKey("/landing/manajemen/direksi", params);
    },
    StrukturOrganisasi(params) {
      return UrlEncodedKey("/landing/struktur-organisasi", params);
    },
    Sertifikasi(params) {
      return UrlEncodedKey("/landing/awards", params);
    },
    HubunganInvestor(params) {
      return UrlEncodedKey("/landing/laporan", params);
    },
    DetailPublikasi(id, category, params) {
      return UrlEncodedKey(
        `/landing/publikasi/${id}?category=${category}`,
        params
      );
    },
    Tjsl(params) {
      return UrlEncodedKey("/landing/tjsl", params);
    },
    Layanan(params) {
      return UrlEncodedKey("/landing/layanan", params);
    },
    LayananDetails(params) {
      return UrlEncodedKey("/landing/layanan/details", params);
    },
    JenisLayanan(params) {
      return UrlEncodedKey("/landing/layanan/jenis-layanan", params);
    },
    GCGTitleList(params) {
      return UrlEncodedKey("/landing/gcg/title-list", params);
    },
    GCGDetails(params) {
      return UrlEncodedKey("/landing/gcg/details", params);
    },
    Fasilitas(params) {
      return UrlEncodedKey("/landing/fasilitas", params);
    },
    FasilitasCategory(params) {
      return UrlEncodedKey("/landing/fasilitas/category", params);
    },
    FasilitasDetails(params) {
      return UrlEncodedKey("/landing/fasilitas/details", params);
    },
    CountFasilitasDetails(params) {
      return UrlEncodedKey("/landing/fasilitas/details-count", params);
    },
    CoreValue(params) {
      return UrlEncodedKey("/landing/core-value", params);
    },
    Popup(params) {
      return UrlEncodedKey("/landing/Popup", params);
    },
    Search(id, params) {
      return UrlEncodedKey(`/landing/search/${id}`, params);
    },
    SearchCard(id, params) {
      return UrlEncodedKey(`/landing/search?istab=${id?.tab}&params=${id?.search}`, params);
    },
  },
};

export default ApiGet;
