import { RESETDETAILSLAYANAN, RESETLAYANAN, SETLAYANAN } from "./layananTypes";

const initialState = {
    data: [],
    details: {},
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SETLAYANAN:
            return {
                ...state,
                ...payload
            };
        case RESETLAYANAN:
            return {
                ...state,
                data: [],
            };
        case RESETDETAILSLAYANAN:
            return {
                ...state,
                details: {},
            };

        default:
            return state;
    }
}

export default reducer;