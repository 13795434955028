import React, { useEffect, useState } from "react";
import BasicDropdown from "./Dropdown/BasicDropdown";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faGlobe } from "@fortawesome/free-solid-svg-icons";
// import LogoPutih from 'assets/images/logo_pt_psl_putih.svg';
// import LogoBiru from 'assets/images/logo_pt_psl_biru.svg';
// import LogoPutih from 'assets/images/logo_pt_psl_putih.png';
// import LogoBiru from 'assets/images/logo_pt_psl_biru.png';
import LogoPutih from 'assets/images/logo_pt_psl_putih_new.svg';
import LogoBiru from 'assets/images/logo_pt_psl_biru_new.svg';
import flagIndo from 'assets/images/icons/indo.svg';
import flagUs from 'assets/images/icons/us.svg';
import { Transition } from "@headlessui/react";
import TransitionDropdown from "./Dropdown/TransitionDropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useRedux from "redux/useRedux";
import SearchDropdown from "./Dropdown/SearchDropdown";
// import { setHeaderSubMenu } from "redux/global/globalActions";

const Header = (props) => {
    // console.log('props', props)
    // const { headerSubMenu } = props;
    const lang = localStorage.getItem('i18nextLng');
    let navigate = useNavigate();
    const { pathname } = useLocation();
    const {
        // dispatch,
        global: {
            menu,
            menuSidebar
        }
    } = useRedux();
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false)

    const [searchTerm, setSearchTerm] = useState("");
    // const [navbar, setNavbar] = useState(false);
    const [colorChange, setColorchange] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', changeHeaderColor);
        return () => {
            window.removeEventListener("scroll", changeHeaderColor);
        }
    }, [])
    const changeHeaderColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        }
        else {
            setColorchange(false);
        }
    };

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value.length > 0) {
            // console.log(e.target.value.length,"if=============");
            setShow(true)
        }
    };

    var input = document.getElementById("myInput");

    useEffect(() => {
        window.addEventListener("keypress", (event) => {
            if (event.key === "Enter") {

                navigate(`/search?val=${event.target.value}`)

            }

        })

    }, [])

    useEffect(() => {

        setSearchTerm("");

    }, [pathname])

    const changeLang = (val) => {
        localStorage.setItem('i18nextLng', val)
        window.location.reload();
    }

    // useEffect(() => {
    //     dispatch(setHeaderSubMenu(headerSubMenu))
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const toggleMainMenu = () => {
        setIsOpen(prev => !prev)
    }
    const onClickMenu = (val) => {
        // console.log('onClickMenu', val)
        val?.path && navigate(val?.path)
    }
// console.log(window.screen.width, "wid")
    return (
        <>
            <div className={`bg-header ${(colorChange || pathname !== '/') ? 'scrolled ' : ''} flex flex-col `}>
                <div className={`flex flex-row items-center justify-between md:border-b-2 py-[16px] ${(colorChange || pathname !== '/') ? 'border-[#D9D9D9]' : 'border-white'}`}>
                    <div className={`flex flex-wrap flex-row items-center justify-end`}>
                        <Link to={'/'}>
                            <img className="mr-4 logo logo-header" src={(colorChange || pathname !== '/') ? LogoBiru : LogoPutih} alt="logo" />
                        </Link>
                    </div>
                    <div className={`hidden md:flex flex-wrap flex-row items-center  ${(colorChange || pathname !== '/') ? 'divide-[#D9D9D9]' : 'divide-white'} pt-[5px]`}>
                        <div className={`hidden md:flex px-[20px] ${(window.screen.width <=1230) ? 'w-[230px] h-[32px]' : 'w-[271.6px]'}`}>
                            <div>
                                <input id="myInput" className="search " type="search" placeholder="Search" value={searchTerm}
                                    onChange={handleChange} />
                                <div>
                                    <SearchDropdown show={show} setShow={setShow} search={searchTerm} />
                                </div>
                            </div>
                        </div>
                        <div className="px-[20px] font-medium border-r-2">
                            <Link to={'#'} onClick={(e) => {
                                window.open('https://www.pelindo.co.id/page/karir', '_blank')
                            }}>
                                Karir
                            </Link>
                        </div>
                        <div className={`px-[20px] border-r-2 font-medium ${pathname === '/ppid' ? 'text-active' : ''}`}>
                            <Link to={"/ppid"} >
                                PPID
                            </Link>
                        </div>
                        <div className="px-[20px] font-medium border-r-2">
                            <Link to={'#'} onClick={(e) => {
                                window.open('https://secure.pelindo.co.id/', '_blank')
                            }}>
                                Pengadaan
                            </Link>
                        </div>
                        <div className={`px-[20px] font-medium ${pathname === '/kontak-kami' ? 'text-active' : ''}`}>
                            <Link to={"/kontak-kami"} >
                                Kontak Kami
                            </Link>
                        </div>
                        <div className={` ${(window.screen.width <=1230) ? 'px-[0px]' : 'px-[20px]'}`} >
                            <div className="flex flex-row">
                                <img src={flagIndo} alt='icon' className="mx-2 cursor-pointer" onClick={() => changeLang('id')} />
                                <img src={flagUs} alt='icon' className="mx-2 cursor-pointer" onClick={() => changeLang('en')} />
                            </div>
                        </div>
                    </div>
                    <div className="flex md:hidden">
                        <button
                            onClick={toggleMainMenu}
                            type="button"
                            className="inline-flex items-center justify-center p-2 rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-800"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Open main menu</span>
                            {!isOpen ? (
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
                <div className="hidden md:flex flex-wrap flex-row items-center justify-between ">
                    {
                        menu?.map((val, ind) => {
                            return (
                                <div key={ind} className={`p-[10px] style-drop-down first:px-0 last:px-0 ${(colorChange || pathname !== '/') ? 'text-[#333333]' : 'text-white'}`}>
                                    <BasicDropdown
                                        title={
                                            <div className={`flex flex-row items-center style-drop-down font-medium uppercase `} onClick={() => onClickMenu(val)}>
                                                {val.title}
                                            </div>
                                        }
                                        colorChevron='primary'
                                        classNameItems='w-56'
                                        items={val.child}
                                        onClickItems={onClickMenu}
                                        active={menuSidebar?.title === val.title ? true : false}

                                    />
                                </div>
                            )
                        })
                    }
                </div>
                <Transition
                    show={isOpen}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    {(ref) => (
                        <div className="md:hidden menu overflow-y-scroll">
                            <div className="flex flex-wrap flex-col font-semibold">
                                <div className="p-[10px]">
                                    <div className="w-full">
                                        <input className="search" type="search" placeholder="Search" />
                                    </div>
                                </div>
                                <div className="p-[10px]">
                                    <BasicDropdown
                                        title={
                                            <div className="flex flex-row items-center ">
                                                {/* <FontAwesomeIcon className="mr-2" icon={faGlobe} /> */}
                                                {lang === 'id' ? 'Bahasa Indonesia' : 'English'}
                                                <img src={lang === 'id' ? flagIndo : flagUs} alt='icon' className="ml-2" />
                                            </div>
                                        }
                                        colorChevron='primary'
                                        onClickItems={changeLang}
                                        items={[
                                            {
                                                id: 'id',
                                                title: 'Indonesia',
                                            },
                                            {
                                                id: 'en',
                                                title: 'English',
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="p-[10px] ">
                                    <Link to={"/ppid"} onClick={() => toggleMainMenu()}>
                                        PPID
                                    </Link>
                                </div>
                                <div className="p-[10px] ">
                                    <Link to={'#'} onClick={(e) => {
                                        toggleMainMenu()
                                        window.open('https://secure.pelindo.co.id/', '_blank')
                                    }}>
                                        Pengadaan
                                    </Link>
                                </div>
                                <div className="p-[10px] ">
                                    <Link to={"/kontak-kami"} onClick={() => toggleMainMenu()}>
                                        Kontak Kami
                                    </Link>
                                </div>
                                <hr />
                                {
                                    menu?.map((val, ind) => {
                                        return (
                                            <TransitionDropdown title={val.title} withChevron={true} items={val.child} toggleMainMenu={toggleMainMenu}/>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )}
                </Transition>
            </div>
        </>
    )
}
export default Header