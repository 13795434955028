import React from "react";

const ConfigRoute = (data) => {
    const CR = [];
    data.map((route) => {
        // let C = route.component.split(".") ? route.component.split(".")[0] : route.component;
        return CR.push({
            ...route,
            element: React.lazy(() => import("./components/pages/" + route.components)),
        })
    })
    return CR
}



export default ConfigRoute;