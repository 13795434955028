import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import axios from "axios";
import { getCookie, setCookie } from "utils/Cookies";
import { responseResult } from "utils/ResponseStatus";
import ApiGet from "api/ApiGet";
import globalReducers from "./global/globalReducers";
import profilReducers from "./profil/profilReducers";
import hubunganReducers from "./hubunganinvestor/hubunganinvestorReducers";
import layananReducers from "./layanan/layananReducers";
import gcgReducers from "./gcg/gcgReducers";
import fasilitasReducers from "./fasilitas/fasilitasReducers";
import search from "./search/searchReducers";

const store = configureStore({
    reducer: combineReducers({
        global: globalReducers,
        profil: profilReducers,
        hubunganInvestor: hubunganReducers,
        layanan: layananReducers,
        gcg: gcgReducers,
        fasilitas: fasilitasReducers,
        search:search
    }),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

// var key
// if (getCookie('ApiKey')) {
//     key = getCookie('ApiKey')
// } else {
//     ApiGet.actions.GetApiKey()
//         .then((res) => {
//             setCookie('ApiKey', responseResult(res))
//             key = getCookie('ApiKey')
//         })
// }

// Add a request interceptor
axios.interceptors.request.use(async function (config) {
    // if (key?.ApiKey) {
    //     config.headers.Authorization = `${key?.ApiKey}`;
    // }
    config.headers.Authorization = process.env.REACT_APP_ApiKey
    return config;
}, async function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(async function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log(`[INTERCEPTORS] ${response?.request?.responseURL}:`, response);
    return response;
}, async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // return Promise.reject(error);
    // console.log(`[INTERCEPTORS]:`, responseMessage(error.response.data));
    return Promise.reject(error);
});

export default store;
