import BasicDropdown from "components/reuseable/Dropdown/BasicDropdown";
import SelectDropdown from "components/reuseable/Dropdown/SelectDropdown"
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  setActiveMenuSidebar,
  setMenuSidebar,
} from "redux/global/globalActions";
import { setAnakPerusahaan } from "redux/profil/profilActions";
import useRedux from "redux/useRedux";
import icSelected from "./assets/images/icons/icon-selected.svg";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

const LayoutDetail = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;
  // console.log('pathname', pathname)
  const {
    dispatch,
    global: { menu, menuSidebar, activeMenuSidebar },
    profil: { anakPerusahaan },
  } = useRedux();

  let navigate = useNavigate();

  const [open, setOpen] = useState(false);

  let pathFind = "";
  const findSubMenu = menu.find((val, ind) => {
    let child = val?.child;
    if (child?.length > 0) {
      pathFind = child?.find(
        (val) => val?.path.split("/")[1] === pathname.split("/")[1]
      );
      return child?.find(
        (val) => val?.path.split("/")[1] === pathname.split("/")[1]
      );
    }
    return null;
  });
  useEffect(() => {
    if (findSubMenu) {
      dispatch(setMenuSidebar(findSubMenu));
    }
    if (pathFind) {
      dispatch(setActiveMenuSidebar(pathFind));
    }
    return () => {
      dispatch(setMenuSidebar(null));
      dispatch(setActiveMenuSidebar(null));
    };
  }, [findSubMenu, pathFind]); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log('findSubMenu', findSubMenu)
  // console.log('pathFind', pathFind)

  const anakPerusahaanClick = (data) => {
    // console.log('anakPerusahaanClick', data)
    dispatch(
      setAnakPerusahaan({
        selected: data,
      })
    );
  };
  let dropdown = [
    { Title: "Tata Kelola", child: [{ title: "nama", path: "https:" }] },
    { Title: "GCG", child: [{ title: "nama", path: "https:" }] },
  ];

  const onClickMenu = (val) => {
    // console.log('onClickMenu', val)
    val?.path && navigate(val?.path);
  };

  const bgTitle = (title) => {
    switch (title) {
      case "Profil":
        return "bg-profil";
      case "GCG":
        return "bg-gcg";
      case "Layanan":
        return "bg-layanan";
      case "Fasilitas":
        return "bg-fasilitas";
      case "Hubungan Investor":
        return "bg-hubungan";
      case "Media & Publikasi":
        return "bg-media";
      case "TJSL":
        return "bg-tjsl";
      default:
        return "";
    }
  };
  let tataKelola = [];
  let other = [];

  let test2 = menuSidebar?.child.sort((a, b) => {
    return a.id - b.id;
  });


  // console.log("ini other", other);

  return (
    <>
      <div className="mt-[110px]">
        <div
          className={`${bgTitle(
            menuSidebar?.title
          )} flex flex-col justify-center items-center`}
        >
          {/* <div className={`${bgTitle(findSubMenu?.title)} flex flex-col justify-center items-center`}> */}
          <div className="text-white text-[40px] font-semibold uppercase text-center">
            {menuSidebar?.title}
            {/* {findSubMenu?.title} */}
          </div>
        </div>
        <div className="section pt-[30px]">
          {/* <div className="flex flex-row flex-wrap md:flex-nowrap md:divide-x-2"> */}
          <div className="md:grid md:grid-cols-4 md:divide-x-2 ">
            <div className="hidden md:flex md:flex-col md:mr-2">
              {/* {findSubMenu?.title && <div className="text-active text-[15px] uppercase font-bold mb-4">{findSubMenu?.title}</div>} */}
              {menuSidebar?.title && (
                <div className="text-active text-[15px] uppercase font-bold mb-4">
                  {menuSidebar?.title}
                </div>
              )}
              {/* {findSubMenu?.title?.length > 0 && (
                                findSubMenu?.child?.map((val, ind) => {
                                    return (
                                        <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${pathname === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                                            {val?.title}
                                        </Link>
                                    )
                                })
                            )} */}
              {menuSidebar?.title?.length > 0 &&
                menuSidebar?.child?.map((val, ind) => {
                  val?.title === "Praktik Tata Kelola" ||
                  val?.title === "Laporan Penilaian GCG"
                    ? tataKelola.push(val)
                    : other.push(val);
                })}
              {other?.length > 0 &&
                other.map((val, ind) => {
                  
                  if(val?.title=="Manual GCG"||val?.title=="Whistleblowing System"){
                  return (
                    // <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${pathname === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                    //     {val?.title}
                    // </Link>
                    // <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${menuSidebar?.active?.path === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                    //     {val?.title}
                    // </Link>
                    <>
                      <Link
                        key={ind}
                        className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${
                          activeMenuSidebar?.path === val?.path
                            ? "text-active font-semibold bg-[#F0F2F2] rounded-lg"
                            : ""
                        }`}
                        to={`${val?.isEksternal ? "#" : val?.path}`}
                        onClick={(e) => {
                          if (val?.isEksternal) {
                            window.open(val?.path, "_blank");
                          }
                        }}
                      >
                        {val?.title}
                      </Link>
                    </>
                  );
                }})}
              {menuSidebar?.title === "GCG" && tataKelola.length > 0 ? (
                // <BasicDropdown
                //     className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg 'text-active font-semibold bg-[#F0F2F2] rounded-lg'`}
                //     title={'Tata Kelola'}
                //     items={tataKelola}
                //     onClickItems={onClickMenu}
                // />

                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                        <div className="my-1 text-[15px] ">Praktik Tata Kelola</div>
                        <ChevronUpIcon
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-primary-500`}
                        />
                      </Disclosure.Button>
                      {tataKelola?.map((val, ind) => {
                        return (
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <Link
                              key={ind}
                              className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${
                                activeMenuSidebar?.path === val?.path
                                  ? "text-active font-semibold bg-[#F0F2F2] rounded-lg"
                                  : ""
                              }`}
                              to={`${val?.isEksternal ? "#" : val?.path}`}
                              onClick={(e) => {
                                if (val?.isEksternal) {
                                  window.open(val?.path, "_blank");
                                }
                              }}
                            >
                              {val?.title}
                            </Link>
                          </Disclosure.Panel>
                        );
                      })}
                    </>
                  )}
                </Disclosure>
              ) : (
                ""
              )}

              {other?.length > 0 &&
                other.map((val, ind) => {
                  if(val?.title!=="Manual GCG"&&val?.title!=="Whistleblowing System"){
                  return (
                    // <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${pathname === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                    //     {val?.title}
                    // </Link>
                    // <Link key={ind} to={val?.path} className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${menuSidebar?.active?.path === val?.path ? 'text-active font-semibold bg-[#F0F2F2] rounded-lg' : ''}`}>
                    //     {val?.title}
                    // </Link>
                    <>
                      <Link
                        key={ind}
                        className={`my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] hover:rounded-lg ${
                          activeMenuSidebar?.path === val?.path
                            ? "text-active font-semibold bg-[#F0F2F2] rounded-lg"
                            : ""
                        }`}
                        to={`${val?.isEksternal ? "#" : val?.path}`}
                        onClick={(e) => {
                          if (val?.isEksternal) {
                            window.open(val?.path, "_blank");
                          }
                        }}
                      >
                        {val?.title}
                      </Link>
                    </>
                  );
                }})}

              {pathFind?.path === "/wilayah-kerja-anak-perusahaan" &&
                anakPerusahaan?.data?.length > 0 && (
                  <div className="py-6 border-t-2">
                    {anakPerusahaan?.data?.map((val, ind) => {
                      return (
                        <div
                          key={ind}
                          className={`cursor-pointer my-1 p-[14px] text-[15px] hover:bg-[#F0F2F2] items-center hover:rounded-lg ${
                            anakPerusahaan?.selected?.id === val?.id
                              ? "text-active gap-2 flex flex-row items-start font-semibold bg-[#F0F2F2] rounded-lg"
                              : ""
                          }`}
                          onClick={() => anakPerusahaanClick(val)}
                        >
                          {anakPerusahaan?.selected?.id === val?.id ? (
                            <img src={icSelected} alt="icons" style={{
                              objectFit:"cover",
                              position:"relative"
                            }}/>
                          ) : (
                            ""
                          )}
                          {val?.company_name}
                        </div>
                      );
                    })}
                  </div>
                )}
            </div>
            
            {/* <div>
                            {
                                dropdown?dropdown.map((data)=>

                                ):null
                            }
                        </div> */}
            <div className="md:col-span-3 md:flex md:flex-col pl-0 md:pl-[20px]">
              {/* <div className="section flex flex-col ml-10 px-[20px] pt-[30px] md:px-[2px]"> */}
              {pathFind?.path === "/wilayah-kerja-anak-perusahaan" &&
                anakPerusahaan?.data?.length > 0 && (
                  <div className="py-6 border-t-2 md:hidden">
                    <SelectDropdown
                        items={anakPerusahaan?.data}
                        onClickItems={anakPerusahaanClick}
                        selectedTitle={anakPerusahaan?.selected?.company_name}
                        selectedValue={anakPerusahaan?.selected}
                    />
                  </div>
                )}
              <div className="section-detail flex flex-col">
                <Outlet context={pathFind} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <img src={bgFooter} alt="icon" className="relative object-fill w-full" /> */}
    </>
  );
};
export default LayoutDetail;
